import { getAuthorizationHeader } from '../graphql/token';

const hoppApiUrl = process.env.REACT_APP_HOPP_API_URL;

const buildServerError = (url: string, status: number, message?: string) => {
  const errorMessage = message || 'Unexpected server error';
  console.error(`${errorMessage} (${status} ${url})`);
  throw new Error(`${errorMessage} (${status})`);
};

const safeExecute = async <T>(dataFn: () => Promise<T>): Promise<T | null> => {
  try {
    return await dataFn();
  } catch {
    return null;
  }
};

const safeExecuteSync = <T>(dataFn: () => T): T | null => {
  try {
    return dataFn();
  } catch {
    return null;
  }
};

const safeFetch = async (
  url: string,
  { parseJSON, ...requestOptions }: RequestInit & { parseJSON: boolean },
) => {
  const fetchOptions: RequestInit = {
    ...requestOptions,
    headers: {
      Accept: 'application/json',
      'X-User-Agent': 'HoppDashboard',
      authorization: getAuthorizationHeader(),
      ...requestOptions.headers,
    },
  };
  const response = await fetch(url, fetchOptions);
  const data = parseJSON ? await safeExecute(() => response.json()) : response;
  if (!response.ok) {
    throw buildServerError(response.url, response.status, data?.message);
  }
  return data;
};

type HoppClientOptions = {
  parseJSON: boolean;
};

type HoppClientPostImageWithProgressOptions = {
  parseJSON: boolean;
  progressCb: (progress: number | null) => void;
};

/**
 * Call the Hopp API and handle errors.
 */
class HoppClient {
  post(
    url: string,
    body: Record<string, unknown>,
    options: HoppClientOptions = { parseJSON: true },
  ) {
    return safeFetch(`${hoppApiUrl}${url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      ...options,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  get(url: string, options: HoppClientOptions = { parseJSON: true }) {
    return safeFetch(`${hoppApiUrl}${url}`, {
      method: 'GET',
      ...options,
    });
  }
  postImage(
    url: string,
    imageForm: FormData,
    options: HoppClientOptions = { parseJSON: true },
  ) {
    return safeFetch(`${hoppApiUrl}${url}`, {
      method: 'POST',
      body: imageForm,
      ...options,
    });
  }
  postImageWithProgress<T = unknown>(
    url: string,
    imageForm: FormData,
    options: HoppClientPostImageWithProgressOptions,
  ): [XMLHttpRequest, Promise<T>] {
    const request = new XMLHttpRequest();
    const promise = new Promise<T>((resolve, reject) => {
      request.upload.addEventListener('progress', e => {
        if (!e.lengthComputable) {
          options.progressCb(null);
          return;
        }
        const progress = e.loaded / e.total;
        options.progressCb(progress);
      });
      request.addEventListener('readystatechange', () => {
        if (request.readyState !== request.DONE) {
          return;
        }
        const data = options.parseJSON
          ? safeExecuteSync(() => JSON.parse(request.responseText))
          : request.responseText;
        if (request.status >= 300) {
          reject(buildServerError(url, request.status, data?.message));
        }
        resolve(data);
      });
      request.addEventListener('error', () => {
        reject(buildServerError(url, request.status));
      });
      request.addEventListener('timeout', () => {
        reject(buildServerError(url, request.status, 'Request timed out'));
      });
      request.open('POST', `${hoppApiUrl}${url}`);
      request.setRequestHeader('Accept', 'application/json');
      request.setRequestHeader('X-User-Agent', 'HoppDashboard');
      request.setRequestHeader('authorization', getAuthorizationHeader());
      request.timeout = 45 * 1000;
      request.send(imageForm);
    });

    return [request, promise];
  }
}

export default new HoppClient();

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: string;
  /** A special custom Scalar type for Dates that converts to a ISO formatted string */
  Date: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** A (multidimensional) set of coordinates following x, y, z order. */
  GeoJSONCoordinates: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSON` scalar type represents raw JSON as values. */
  SequelizeJSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export interface AccountingConfig {
  __typename?: 'AccountingConfig';
  id: Scalars['Int'];
  name: Scalars['String'];
}

/** An active trip. Includes vehicle information. */
export interface ActiveTrip {
  __typename?: 'ActiveTrip';
  /** @deprecated Not reflective of NO_PARK vs OUT_OF_BOUNDS logic. Should respond to endRent errors instead. */
  canPark: Scalars['Boolean'];
  currency: CurrencyEnum;
  id: Scalars['Int'];
  isFree: Scalars['Boolean'];
  masterTripId?: Maybe<Scalars['Int']>;
  parkStatus: ParkStatusEnum;
  tripStart: Scalars['Date'];
  vehicle: Vehicle;
}

export interface AddAppleAuthFullNameInput {
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<Scalars['String']>;
  nameSuffix?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
}

export interface AddAppleAuthInput {
  authorizationCode: Scalars['String'];
  fullName?: InputMaybe<AddAppleAuthFullNameInput>;
}

export interface AddApplePayInput {
  orgId?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
}

/** An added card with 3ds info */
export interface AddCard {
  __typename?: 'AddCard';
  acsForm?: Maybe<Scalars['String']>;
  /** @deprecated A card isn't always created */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated A card isn't always created */
  lastDigits?: Maybe<Scalars['String']>;
  should3ds: Scalars['Boolean'];
  /** @deprecated A card isn't always created */
  type?: Maybe<Scalars['String']>;
}

export interface AddFleetInput {
  accountingId: Scalars['Int'];
  availableApns?: InputMaybe<Array<Scalars['String']>>;
  creditsPerMin: Scalars['Int'];
  currency: CurrencyEnum;
  features: Array<FleetFeatureEnum>;
  maxInactiveMinutes: Scalars['Int'];
  maxPausedMinutes: Scalars['Int'];
  maxReservedMinutes: Scalars['Int'];
  name: Scalars['String'];
  newOperator?: InputMaybe<NewOperator>;
  newRegion?: InputMaybe<NewRegion>;
  operatorId?: InputMaybe<Scalars['Int']>;
  pausedCost: Scalars['Int'];
  penaltyCost: Scalars['Int'];
  preAuthAmount: Scalars['Int'];
  preAuthWhen: FleetPreAuthWhenEnum;
  regionId?: InputMaybe<Scalars['Int']>;
  reservedCost: Scalars['Int'];
  startCost: Scalars['Int'];
  timezone: Scalars['String'];
  type: FleetTypeEnum;
  vatRate: Scalars['Float'];
  vehicleTypeGroup: VehicleTypeGroupEnum;
}

export interface AddGoogleAuthInput {
  idToken: Scalars['String'];
}

export interface AddLicenseInput {
  workflowId: Scalars['String'];
}

export interface AddOrgUser {
  __typename?: 'AddOrgUser';
  isNewUser: Scalars['Boolean'];
  userId: Scalars['Int'];
}

export interface AddPhoneAuthInput {
  phone: Scalars['String'];
  pin: Scalars['String'];
  pinToken: Scalars['String'];
}

export interface Address {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  coordinates: TaxiCoordinates;
  country?: Maybe<Scalars['String']>;
  entryway?: Maybe<Entryway>;
  houseNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
}

export interface AddressInput {
  city?: InputMaybe<Scalars['String']>;
  coordinates: CoordinatesInput;
  country?: InputMaybe<Scalars['String']>;
  entryway?: InputMaybe<EntrywayInput>;
  houseNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
}

export interface AllIssues {
  __typename?: 'AllIssues';
  issues: Array<Issue>;
  pageInfo: CursorPageInfo;
}

export interface AllWatcherEvents {
  __typename?: 'AllWatcherEvents';
  pageInfo: CursorPageInfo;
  watcherEvents: Array<WatcherEvent>;
}

/** Notifications for a user */
export interface Announcement {
  __typename?: 'Announcement';
  body: Scalars['String'];
  buttonText: Scalars['String'];
  fleet?: Maybe<Fleet>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  locales: Array<Maybe<Scalars['String']>>;
  rawBody?: Maybe<Scalars['SequelizeJSON']>;
  rawButtonText?: Maybe<Scalars['SequelizeJSON']>;
  rawTitle?: Maybe<Scalars['SequelizeJSON']>;
  showFrom: Scalars['Date'];
  showTo: Scalars['Date'];
  title: Scalars['String'];
  vehicleTypeGroups?: Maybe<Array<VehicleTypeGroupEnum>>;
}

export interface AnnouncementInput {
  icon?: InputMaybe<Scalars['String']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  showFrom: Scalars['String'];
  showTo: Scalars['String'];
  translations: Array<TranslatedAnnouncementInput>;
  vehicleTypeGroups: Array<VehicleTypeGroupEnum>;
}

/** A card to pay with connected to a user */
export interface Card {
  __typename?: 'Card';
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  id: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  lastDigits: Scalars['String'];
  org?: Maybe<Organization>;
  provider: CardproviderEnumType;
  status: CardstatusEnumType;
  stripeFingerprint: Scalars['String'];
  type: Scalars['String'];
  user?: Maybe<User>;
}

export interface ChangedZones {
  __typename?: 'ChangedZones';
  deletedIds: Array<Scalars['Int']>;
  updated: Array<Zone>;
}

export interface CompleteTutorialInput {
  tutorialId: Scalars['Int'];
}

/** A configuration for Hopp */
export interface Configuration {
  __typename?: 'Configuration';
  paymentToken: Scalars['String'];
  stripePublishableKey: Scalars['String'];
}

export interface CoordinatesInput {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

/** A Country. */
export interface Country {
  __typename?: 'Country';
  countryCode: Scalars['String'];
  name: Scalars['String'];
  regions: Array<Region>;
}

export interface Coupon {
  __typename?: 'Coupon';
  amountRemaining: Scalars['Int'];
  couponGroup: PublicCouponGroup;
  couponType: CouponType;
  criteria: Array<CouponCriteria>;
  currency: CurrencyEnum;
  details: Array<CouponDetail>;
  glimpseDetails: Array<Scalars['String']>;
  icons: Array<MediaAsset>;
  id: Scalars['Int'];
  isClaimed: Scalars['Boolean'];
  oid: Scalars['String'];
  originalAmount: Scalars['Int'];
  publicId?: Maybe<Scalars['String']>;
  redeemedAt?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  user?: Maybe<User>;
  validUntil?: Maybe<Scalars['Date']>;
}

export interface CouponCriteria {
  __typename?: 'CouponCriteria';
  renderType: CouponCriteriaRenderTypeEnum;
  title: Scalars['String'];
  type: CouponCriteriaTypeEnum;
  values: Array<CouponCriteriaValue>;
}

export enum CouponCriteriaRenderTypeEnum {
  OneOf = 'ONE_OF',
}

export enum CouponCriteriaTypeEnum {
  Region = 'REGION',
  VehicleType = 'VEHICLE_TYPE',
}

export interface CouponCriteriaValue {
  __typename?: 'CouponCriteriaValue';
  title: Scalars['String'];
}

export interface CouponDescription {
  __typename?: 'CouponDescription';
  body: Scalars['String'];
  title: Scalars['String'];
}

export interface CouponDetail {
  __typename?: 'CouponDetail';
  description: Scalars['String'];
  title: Scalars['String'];
}

export interface CouponGroup {
  __typename?: 'CouponGroup';
  amount: Scalars['Int'];
  couponLimit?: Maybe<Scalars['Int']>;
  couponType: CouponType;
  couponTypeId: Scalars['Int'];
  coupons: Array<Coupon>;
  couponsClaimed: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdByUser: User;
  createdByUserId: Scalars['Int'];
  currency: CurrencyEnum;
  fleetId: Scalars['Int'];
  id: Scalars['Int'];
  internalName?: Maybe<Scalars['String']>;
  oid: Scalars['String'];
  qrCode?: Maybe<Scalars['String']>;
  regions: Array<Region>;
  soldFor: Scalars['Int'];
  validFor?: Maybe<Scalars['Int']>;
  vehicleTypeGroups: Array<VehicleTypeGroupRentableEnum>;
}

export interface CouponGroups {
  __typename?: 'CouponGroups';
  couponGroups: Array<CouponGroup>;
  pageInfo: CursorPageInfo;
}

export interface CouponInput {
  id?: InputMaybe<Scalars['Int']>;
  publicId?: InputMaybe<Scalars['String']>;
}

export enum CouponLayoutEnum {
  Generic = 'GENERIC',
  Gift = 'GIFT',
  Slim = 'SLIM',
}

export interface CouponPayment {
  __typename?: 'CouponPayment';
  couponAmountUsed: Scalars['Int'];
  couponId: Scalars['Int'];
  createdAt: Scalars['Date'];
  invoiceAmountPaid: Scalars['Int'];
  invoiceId: Scalars['Int'];
}

export interface CouponType {
  __typename?: 'CouponType';
  coverPhoto?: Maybe<Scalars['String']>;
  description?: Maybe<CouponDescription>;
  id: Scalars['Int'];
  isInternal: Scalars['Boolean'];
  layout: CouponLayoutEnum;
  name: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  unit: CouponUnitEnum;
}

export enum CouponUnitEnum {
  Credit = 'CREDIT',
  Discount = 'DISCOUNT',
  Minute = 'MINUTE',
  Trip = 'TRIP',
}

export interface CreateCouponGroupInput {
  amount: Scalars['Int'];
  /** Number of coupons allowed for this group */
  couponLimit: Scalars['Int'];
  /** Type of each coupon in the group */
  couponTypeId: Scalars['Int'];
  fleetId: Scalars['Int'];
  /** Name of the coupon group, for internal reference */
  internalName: Scalars['String'];
  /** List of allowed regions */
  regionIds: Array<Scalars['Int']>;
  /** The amount we expect to receive for the coupon, usually just the full amount or 0 */
  soldFor: Scalars['Int'];
  /** How long, from the time the coupon is created, will the coupon last */
  validFor: Scalars['Int'];
  /** List of vehicle type groups this coupon group applies to */
  vehicleTypeGroups: Array<VehicleTypeGroupRentableEnum>;
}

export enum CurrencyEnum {
  Bhd = 'BHD',
  Chf = 'CHF',
  Dkk = 'DKK',
  Eur = 'EUR',
  Hrk = 'HRK',
  Huf = 'HUF',
  Isk = 'ISK',
  Nok = 'NOK',
  Pln = 'PLN',
  Ron = 'RON',
  Rsd = 'RSD',
  Sek = 'SEK',
  Usd = 'USD',
}

/** Object for cursor pagination */
export interface CursorPageInfo {
  __typename?: 'CursorPageInfo';
  cursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  remaining?: Maybe<Scalars['Int']>;
}

/** Geometries that affect scooter rentals in some way. */
export interface Demand {
  __typename?: 'Demand';
  id: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

/** Discount associated with an invoice */
export interface DiscountItem {
  __typename?: 'DiscountItem';
  cost: Scalars['Float'];
  label: Scalars['String'];
  referenceItemIndex?: Maybe<Scalars['Int']>;
  total: Scalars['Float'];
  type: InvoiceDiscountType;
  units: Scalars['Int'];
}

export interface Driver {
  __typename?: 'Driver';
  activeTrip?: Maybe<TaxiTrip>;
  activeVehicle?: Maybe<TaxiVehicle>;
  activeVehicleId?: Maybe<Scalars['ID']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  bankAccount?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nationalId?: Maybe<Scalars['String']>;
  ownedVehicles: Array<VehicleInfo>;
  permitNumber?: Maybe<Scalars['String']>;
  permitType?: Maybe<TaxiPermitType>;
  phoneNumber?: Maybe<Scalars['String']>;
  plan?: Maybe<Array<VehicleStep>>;
  profileImageUrl?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  rating: Scalars['Float'];
  termsOfServiceAgreedAt?: Maybe<Scalars['DateTime']>;
  vehicles: Array<TaxiVehicle>;
}

export interface DriverAccountingPeriod {
  __typename?: 'DriverAccountingPeriod';
  /** Amount due that accounts for previous accounting periods that should be paid with this one */
  adjustedAmountDue: Scalars['Int'];
  amountDue: Scalars['Int'];
  currency: CurrencyEnum;
  driver: Driver;
  endTime: Scalars['Date'];
  id: Scalars['Int'];
  owner?: Maybe<Driver>;
  startTime: Scalars['Date'];
  taxiVehicle: TaxiVehicle;
  xeroInvoiceId?: Maybe<Scalars['String']>;
}

export interface DriverConnection {
  __typename?: 'DriverConnection';
  edges: Array<DriverEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface DriverEdge {
  __typename?: 'DriverEdge';
  cursor: Scalars['String'];
  node: Driver;
}

export interface EditFleetInput {
  accountingId?: InputMaybe<Scalars['Int']>;
  availableApns?: InputMaybe<Array<Scalars['String']>>;
  creditsPerMin?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<CurrencyEnum>;
  features?: InputMaybe<Array<FleetFeatureEnum>>;
  fleetId: Scalars['Int'];
  maxInactiveMinutes?: InputMaybe<Scalars['Int']>;
  maxPausedMinutes?: InputMaybe<Scalars['Int']>;
  maxReservedMinutes?: InputMaybe<Scalars['Int']>;
  pausedCost?: InputMaybe<Scalars['Int']>;
  penaltyCost?: InputMaybe<Scalars['Int']>;
  preAuthAmount?: InputMaybe<Scalars['Int']>;
  preAuthWhen?: InputMaybe<FleetPreAuthWhenEnum>;
  reservedCost?: InputMaybe<Scalars['Int']>;
  startCost?: InputMaybe<Scalars['Int']>;
  vatRate?: InputMaybe<Scalars['Float']>;
}

export interface EndGroupRentInput {
  tripId?: InputMaybe<Scalars['Int']>;
  userLocation?: InputMaybe<UserLocationInput>;
}

export interface Entryway {
  __typename?: 'Entryway';
  level?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface EntrywayInput {
  level?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export enum FareType {
  Metered = 'METERED',
  UpfrontEstimate = 'UPFRONT_ESTIMATE',
}

export type Feature = {
  name: Scalars['String'];
};

export interface FeatureFlag extends Feature {
  __typename?: 'FeatureFlag';
  booleanValue: Scalars['Boolean'];
  name: Scalars['String'];
}

export interface FeatureNumberSetting extends Feature {
  __typename?: 'FeatureNumberSetting';
  name: Scalars['String'];
  numberValue: Scalars['Float'];
}

export interface FeatureTextSetting extends Feature {
  __typename?: 'FeatureTextSetting';
  name: Scalars['String'];
  textValue: Scalars['String'];
}

/** A fine */
export interface Fine {
  __typename?: 'Fine';
  createdAt: Scalars['Date'];
  createdByUserId: Scalars['Int'];
  fineType: FineType;
  fineTypeId: Scalars['Int'];
  id: Scalars['Int'];
  invoice: Invoice;
  invoiceId: Scalars['Int'];
  invoiceOid: Scalars['String'];
  oid: Scalars['String'];
  privateDescription?: Maybe<Scalars['String']>;
  publicDescription?: Maybe<Scalars['String']>;
  referenceInvoiceId: Scalars['Int'];
  referenceInvoiceOid: Scalars['String'];
  uploads: Array<FineUpload>;
  user: Invoice;
  userId: Scalars['Int'];
}

/** A Fine Type. */
export interface FineType {
  __typename?: 'FineType';
  id: Scalars['Int'];
  name: Scalars['String'];
}

/** A Fine Upload. */
export interface FineUpload {
  __typename?: 'FineUpload';
  id: Scalars['Int'];
  photo: Scalars['String'];
  visibleToUser: Scalars['Boolean'];
}

export interface FinishVehicleCheckupReturn {
  __typename?: 'FinishVehicleCheckupReturn';
  shouldPickUp: Scalars['Boolean'];
}

/** Finished Trips with cursor pagination */
export interface FinishedTrips {
  __typename?: 'FinishedTrips';
  pageInfo: CursorPageInfo;
  trips?: Maybe<Array<Trip>>;
}

/** A fleet */
export interface Fleet {
  __typename?: 'Fleet';
  accountingConfigId?: Maybe<Scalars['Int']>;
  availableApns: Array<Scalars['String']>;
  creditsPerMin: Scalars['Float'];
  currency: CurrencyEnum;
  features: Array<FleetFeatureEnum>;
  hexagonSize?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  isStaffFleet: Scalars['Boolean'];
  maxInactiveMinutes?: Maybe<Scalars['Int']>;
  maxPausedMinutes?: Maybe<Scalars['Int']>;
  maxReservedMinutes?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  operator: Operator;
  pausedCost: Scalars['Int'];
  penaltyCost: Scalars['Float'];
  preAuthAmount?: Maybe<Scalars['Int']>;
  preAuthWhen: FleetpreAuthWhenEnumType;
  regionId: Scalars['Int'];
  reservedCost: Scalars['Int'];
  serviceDistance?: Maybe<Scalars['Int']>;
  startCost: Scalars['Float'];
  vatRate: Scalars['Float'];
}

/** A fleet and the scopes the user has on that fleet */
export interface FleetAndScopes {
  __typename?: 'FleetAndScopes';
  currency: Scalars['String'];
  features: Array<FleetFeatureEnum>;
  hexagonSize?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  region: Region;
  role?: Maybe<Scalars['String']>;
  scopes: Array<Scalars['String']>;
  serviceDistance?: Maybe<Scalars['Int']>;
}

export interface FleetConnection {
  __typename?: 'FleetConnection';
  edges: Array<FleetEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface FleetEdge {
  __typename?: 'FleetEdge';
  cursor: Scalars['String'];
  node: TaxiFleet;
}

export interface FleetFare {
  __typename?: 'FleetFare';
  baseFee: Scalars['Float'];
  bookingFee: Scalars['Float'];
  cancellationDriverTimeout: Scalars['Float'];
  cancellationFee: Scalars['Float'];
  cancellationRiderTimeout: Scalars['Float'];
  currency: CurrencyEnum;
  effectiveAt: Scalars['DateTime'];
  fleet: TaxiFleet;
  fleetId: Scalars['String'];
  gratuityAccepted: Scalars['Boolean'];
  id: Scalars['ID'];
  minimumFee: Scalars['Float'];
  perKilometer: Scalars['Float'];
  perMinute: Scalars['Float'];
  perMinuteWait: Scalars['Float'];
  reservationFee: Scalars['Float'];
  serviceFee?: Maybe<Scalars['Float']>;
  servicePercent?: Maybe<Scalars['Float']>;
  splitFareAccepted: Scalars['Boolean'];
  type: FareType;
}

export enum FleetFeatureEnum {
  EndsTripsOutOfBounds = 'ENDS_TRIPS_OUT_OF_BOUNDS',
  LightsWhenRisky = 'LIGHTS_WHEN_RISKY',
  LongerOutOfBoundsInactivity = 'LONGER_OUT_OF_BOUNDS_INACTIVITY',
  RequiresDriversLicense = 'REQUIRES_DRIVERS_LICENSE',
  RequiresParkingStation = 'REQUIRES_PARKING_STATION',
  RequiresPhoneNumber = 'REQUIRES_PHONE_NUMBER',
  ShouldTakeParkingPhoto = 'SHOULD_TAKE_PARKING_PHOTO',
  ShowPauseModal = 'SHOW_PAUSE_MODAL',
  StartRideImmediately = 'START_RIDE_IMMEDIATELY',
}

export enum FleetPreAuthWhenEnum {
  Always = 'ALWAYS',
  FirstTripOnly = 'FIRST_TRIP_ONLY',
  RareUseOrGroupRide = 'RARE_USE_OR_GROUP_RIDE',
}

export enum FleetTypeEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Staff = 'STAFF',
}

/** Fleet user */
export interface FleetUser {
  __typename?: 'FleetUser';
  fleetId: Scalars['Int'];
  role?: Maybe<Fleet_UserroleEnumType>;
  user: User;
  userId: Scalars['Int'];
  watcherTypes: Array<WatcherEventTypeEnum>;
}

export interface FleetUserInput {
  fleetId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  oid: Scalars['String'];
  role?: InputMaybe<UserRoleEnum>;
}

/** Vehicle statistics for a single fleet */
export interface FleetVehicleStatistics {
  __typename?: 'FleetVehicleStatistics';
  availablePercentage?: Maybe<Scalars['Float']>;
  avgBatteryAvailable?: Maybe<Scalars['Float']>;
  inactive: Scalars['Int'];
  onStreet: VehiclesOnStreet;
  removed: Scalars['Int'];
  total: Scalars['Int'];
}

/** A fleet with metrics */
export interface FleetWithMetrics {
  __typename?: 'FleetWithMetrics';
  creditsPerMin: Scalars['Float'];
  currency: Scalars['String'];
  features: Array<FleetFeatureEnum>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  maxInactiveMinutes?: Maybe<Scalars['Int']>;
  maxPausedMinutes?: Maybe<Scalars['Int']>;
  maxReservedMinutes?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pausedCost: Scalars['Int'];
  penaltyCost: Scalars['Float'];
  preAuthAmount?: Maybe<Scalars['Int']>;
  preAuthWhen: FleetPreAuthWhenEnum;
  reservedCost: Scalars['Int'];
  startCost: Scalars['Float'];
  vehicleStatistics: FleetVehicleStatistics;
  vehicleTypeGroup: VehicleTypeGroupEnum;
}

/** CRS object properties. */
export type GeoJsoncrsProperties =
  | GeoJsonLinkedCrsProperties
  | GeoJsonNamedCrsProperties;

/** Enumeration of all GeoJSON CRS object types. */
export enum GeoJsoncrsType {
  Link = 'link',
  Name = 'name',
}

/** Coordinate Reference System (CRS) object. */
export interface GeoJsonCoordinateReferenceSystem {
  __typename?: 'GeoJSONCoordinateReferenceSystem';
  properties: GeoJsoncrsProperties;
  type: GeoJsoncrsType;
}

export type GeoJsonGeometryInterface = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']>;
  crs: GeoJsonCoordinateReferenceSystem;
  type: GeoJsonType;
};

export type GeoJsonInterface = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  crs: GeoJsonCoordinateReferenceSystem;
  type: GeoJsonType;
};

/** Object describing a single connected sequence of geographical points. */
export interface GeoJsonLineString
  extends GeoJsonGeometryInterface,
    GeoJsonInterface {
  __typename?: 'GeoJSONLineString';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']>;
  crs: GeoJsonCoordinateReferenceSystem;
  type: GeoJsonType;
}

/** Properties for link based CRS object. */
export interface GeoJsonLinkedCrsProperties {
  __typename?: 'GeoJSONLinkedCRSProperties';
  href: Scalars['String'];
  type?: Maybe<Scalars['String']>;
}

/** Object describing multiple shapes formed by sets of geographical points. */
export interface GeoJsonMultiPolygon
  extends GeoJsonGeometryInterface,
    GeoJsonInterface {
  __typename?: 'GeoJSONMultiPolygon';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']>;
  crs: GeoJsonCoordinateReferenceSystem;
  type: GeoJsonType;
}

/** Properties for name based CRS object. */
export interface GeoJsonNamedCrsProperties {
  __typename?: 'GeoJSONNamedCRSProperties';
  name: Scalars['String'];
}

/** Object describing a single geographical point. */
export interface GeoJsonPoint
  extends GeoJsonGeometryInterface,
    GeoJsonInterface {
  __typename?: 'GeoJSONPoint';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']>;
  crs: GeoJsonCoordinateReferenceSystem;
  type: GeoJsonType;
}

/** Object describing a single shape formed by a set of geographical points. */
export interface GeoJsonPolygon
  extends GeoJsonGeometryInterface,
    GeoJsonInterface {
  __typename?: 'GeoJSONPolygon';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']>;
  crs: GeoJsonCoordinateReferenceSystem;
  type: GeoJsonType;
}

/** Enumeration of all GeoJSON object types. */
export enum GeoJsonType {
  Feature = 'Feature',
  FeatureCollection = 'FeatureCollection',
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon',
}

export interface GiveCouponInput {
  amount: Scalars['Float'];
  type: OldCouponType;
  userOId: Scalars['String'];
}

export interface GiveCouponPayload {
  __typename?: 'GiveCouponPayload';
  user: User;
}

/** Hexagon Grid over map */
export interface HexagonGrid {
  __typename?: 'HexagonGrid';
  coordinates: Array<Coordinates>;
  totalBikes: Scalars['Int'];
  totalDemand: Scalars['Int'];
}

/** Inactive vehicles */
export interface InactiviteVehicles {
  __typename?: 'InactiviteVehicles';
  hours?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  vehicleId: Scalars['Int'];
}

/** Inactive vehicles */
export interface InactivityType {
  __typename?: 'InactivityType';
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  weight: Scalars['Float'];
}

/** Input for adding org user */
export interface InputAddOrgUser {
  name: Scalars['String'];
  orgId: Scalars['Int'];
  phone: Scalars['String'];
  role: OrgUserRoleEnum;
}

/** Input for adding organization. */
export interface InputAddOrganization {
  address?: InputMaybe<Scalars['String']>;
  adminName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fleetId?: InputMaybe<Scalars['Int']>;
  invoiceInfo?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  monthlyPayments?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
  taxNumber?: InputMaybe<Scalars['String']>;
  usageRestriction?: InputMaybe<OrganizationUsageRestriction>;
}

/** Input for editing org user */
export interface InputEditOrgUser {
  name?: InputMaybe<Scalars['String']>;
  orgId: Scalars['Int'];
  role: OrgUserRoleEnum;
  userId: Scalars['Int'];
}

/** Input for editing organization. */
export interface InputEditOrganization {
  address?: InputMaybe<Scalars['String']>;
  cardToken?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceInfo?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  monthlyPayments?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  restrictions?: InputMaybe<OrganizationRestrictionMainInput>;
  taxNumber?: InputMaybe<Scalars['String']>;
  usageRestriction?: InputMaybe<OrganizationUsageRestriction>;
}

/** A reported issue of a vehicle */
export interface InputIssue {
  comment?: InputMaybe<Scalars['String']>;
  issueTypeId?: InputMaybe<Scalars['Int']>;
  photos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  type?: InputMaybe<ReportIssuePartEnum>;
}

/** Issue tag to be added */
export interface InputIssueTag {
  issueTypeId: Scalars['Int'];
  name: Translation;
  requiresElevatedRole?: InputMaybe<Scalars['Boolean']>;
  shouldPickUp?: InputMaybe<Scalars['Boolean']>;
  tag: Scalars['String'];
  vehicleTypeIds: Array<Scalars['Int']>;
}

/** Input for adding a part */
export interface InputPart {
  currency?: InputMaybe<CurrencyEnum>;
  id?: InputMaybe<Scalars['Int']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Int']>;
  vehicleTypeIds: Array<Scalars['Int']>;
}

/** Input for adding/updating inventory of a part */
export interface InputPartInventory {
  currentAmount?: InputMaybe<Scalars['Int']>;
  fleetId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  minAmount?: InputMaybe<Scalars['Int']>;
  partId: Scalars['Int'];
  recommendedAmount?: InputMaybe<Scalars['Int']>;
}

/** Input for connecting a repair and a part */
export interface InputRepairPart {
  partId: Scalars['Int'];
  qty?: InputMaybe<Scalars['Int']>;
  salvaged?: InputMaybe<Scalars['Boolean']>;
}

/** Input for adding/updating a staff-shift */
export interface InputStaffShift {
  end: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  staffId: Scalars['Int'];
  start: Scalars['String'];
  vehicleId?: InputMaybe<Scalars['Int']>;
}

/** An invoice. */
export interface Invoice {
  __typename?: 'Invoice';
  amountDue: Scalars['Int'];
  couponPayments: Array<CouponPayment>;
  createdAt: Scalars['Date'];
  createdByUser?: Maybe<User>;
  createdByUserAffiliation?: Maybe<InvoicecreatedByUserAffiliationEnumType>;
  creditInvoices: Array<Invoice>;
  currency: CurrencyEnum;
  discountItems: Array<DiscountItem>;
  discounts?: Maybe<Scalars['SequelizeJSON']>;
  fleet: Fleet;
  id: Scalars['Int'];
  invoiceItems: Array<InvoiceItem>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  invoiceReasonTag?: Maybe<InvoiceReasonTag>;
  issuedFines: Array<Fine>;
  items: Scalars['SequelizeJSON'];
  oid: Scalars['String'];
  org?: Maybe<Organization>;
  outstandingAmount?: Maybe<Scalars['Int']>;
  /** @deprecated Invoice can have multiple payments. Use payments field instead. */
  payment?: Maybe<Payment>;
  payments: Array<Payment>;
  reasonDescription?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  type: InvoicetypeEnumType;
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
}

/** Aggregates for invoices, grouped by a date */
export interface InvoiceAggregate {
  __typename?: 'InvoiceAggregate';
  creditValueSum: Scalars['BigInt'];
  creditVat: Scalars['BigInt'];
  debitValueSum: Scalars['BigInt'];
  debitVat: Scalars['BigInt'];
  unpaidOrgCreditValueSum: Scalars['BigInt'];
  unpaidOrgDebitValueSum: Scalars['BigInt'];
  unpaidUserCreditValueSum: Scalars['BigInt'];
  unpaidUserDebitValueSum: Scalars['BigInt'];
}

export enum InvoiceDiscountType {
  Coupon = 'COUPON',
  Credit = 'CREDIT',
  DiscountCoupon = 'DISCOUNT_COUPON',
  DiscountZone = 'DISCOUNT_ZONE',
  FreeTrip = 'FREE_TRIP',
  UnlockDiscount = 'UNLOCK_DISCOUNT',
}

export interface InvoiceHistoryInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
}

export type InvoiceHistoryItem = {
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: CurrencyEnum;
  icon: MediaAsset;
  id: Scalars['Int'];
  outstandingAmount: Scalars['Int'];
  refundedAmount: Scalars['Int'];
  title: Scalars['String'];
  type: InvoiceTypeEnum;
};

export interface InvoiceHistoryItemBasic extends InvoiceHistoryItem {
  __typename?: 'InvoiceHistoryItemBasic';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: CurrencyEnum;
  icon: MediaAsset;
  id: Scalars['Int'];
  outstandingAmount: Scalars['Int'];
  refundedAmount: Scalars['Int'];
  title: Scalars['String'];
  type: InvoiceTypeEnum;
}

/** Combined object type for the typescript definition file */
export type InvoiceHistoryItemCombined =
  | InvoiceHistoryItemBasic
  | InvoiceHistoryItemFine
  | InvoiceHistoryItemTaxi
  | InvoiceHistoryItemVehicle;

export interface InvoiceHistoryItemFine extends InvoiceHistoryItem {
  __typename?: 'InvoiceHistoryItemFine';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: CurrencyEnum;
  fineId: Scalars['Int'];
  icon: MediaAsset;
  id: Scalars['Int'];
  outstandingAmount: Scalars['Int'];
  refundedAmount: Scalars['Int'];
  title: Scalars['String'];
  type: InvoiceTypeEnum;
}

export interface InvoiceHistoryItemTaxi extends InvoiceHistoryItem {
  __typename?: 'InvoiceHistoryItemTaxi';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: CurrencyEnum;
  from: Scalars['DateTime'];
  icon: MediaAsset;
  id: Scalars['Int'];
  outstandingAmount: Scalars['Int'];
  refundedAmount: Scalars['Int'];
  taxiTripId: Scalars['String'];
  taxiVehicleId: Scalars['String'];
  taxiVehicleLicensePlate: Scalars['String'];
  title: Scalars['String'];
  to: Scalars['DateTime'];
  type: InvoiceTypeEnum;
}

export interface InvoiceHistoryItemVehicle extends InvoiceHistoryItem {
  __typename?: 'InvoiceHistoryItemVehicle';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: CurrencyEnum;
  endReason: TripEndReasonEnum;
  from: Scalars['DateTime'];
  icon: MediaAsset;
  id: Scalars['Int'];
  length: Scalars['Int'];
  outstandingAmount: Scalars['Int'];
  refundedAmount: Scalars['Int'];
  title: Scalars['String'];
  to: Scalars['DateTime'];
  tripId: Scalars['Int'];
  tripVehicleCode: Scalars['String'];
  tripVehicleId: Scalars['Int'];
  type: InvoiceTypeEnum;
  vehicleTypeGroup: VehicleTypeGroupRentableEnum;
}

export interface InvoiceHistoryResult {
  __typename?: 'InvoiceHistoryResult';
  items: Array<InvoiceHistoryItem>;
  pageInfo: CursorPageInfo;
}

/** Item associated with an invoice */
export interface InvoiceItem {
  __typename?: 'InvoiceItem';
  cost: Scalars['Float'];
  label: Scalars['String'];
  referenceItemIndex?: Maybe<Scalars['Int']>;
  total: Scalars['Float'];
  type: InvoiceItemType;
  units: Scalars['Int'];
}

export interface InvoiceItemInput {
  referenceItemIndex?: InputMaybe<Scalars['Int']>;
  type: InvoiceItemType;
  units: Scalars['Float'];
}

export enum InvoiceItemType {
  Coupon = 'COUPON',
  Fine = 'FINE',
  FromLastPeriod = 'FROM_LAST_PERIOD',
  ParkingPenalty = 'PARKING_PENALTY',
  PausedMinute = 'PAUSED_MINUTE',
  ReservedMinute = 'RESERVED_MINUTE',
  RideMinute = 'RIDE_MINUTE',
  TaxiAirportFee = 'TAXI_AIRPORT_FEE',
  TaxiBaseFee = 'TAXI_BASE_FEE',
  TaxiCancellation = 'TAXI_CANCELLATION',
  TaxiGratuity = 'TAXI_GRATUITY',
  TaxiMinimumSupplement = 'TAXI_MINIMUM_SUPPLEMENT',
  TaxiPerKilometer = 'TAXI_PER_KILOMETER',
  TaxiPerMinute = 'TAXI_PER_MINUTE',
  TaxiPerWaitingMinute = 'TAXI_PER_WAITING_MINUTE',
  TaxiReservation = 'TAXI_RESERVATION',
  TaxiServiceFee = 'TAXI_SERVICE_FEE',
  TaxiSurge = 'TAXI_SURGE',
  Unlock = 'UNLOCK',
}

/** A taggable reason for manually creating a new invoice on the user, whether it is credit or not. */
export interface InvoiceReasonTag {
  __typename?: 'InvoiceReasonTag';
  id: Scalars['Int'];
  isDescriptionRequired: Scalars['Boolean'];
  name: Scalars['String'];
}

export enum InvoiceStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
}

export enum InvoiceTypeEnum {
  Basic = 'Basic',
  Coupon = 'Coupon',
  Fine = 'Fine',
  RentedTrip = 'RentedTrip',
  TaxiTrip = 'TaxiTrip',
}

/** IoT */
export interface IoT {
  __typename?: 'IoT';
  deviceModel?: Maybe<Scalars['String']>;
  iccid?: Maybe<Scalars['String']>;
  imei: Scalars['String'];
  productNumber?: Maybe<Scalars['String']>;
}

/** A reported issue of a vehicle */
export interface Issue {
  __typename?: 'Issue';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  issueType: IssueType;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  repair?: Maybe<Repair>;
  tags: Array<IssueTag>;
  /** @deprecated Use issueType instead, deprecated in version 5.6.0 */
  type?: Maybe<ReportIssuePartEnum>;
  userId?: Maybe<Scalars['Int']>;
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['Int'];
}

export interface IssueAggregate {
  __typename?: 'IssueAggregate';
  count: Scalars['Int'];
  issueTags: Array<Maybe<IssueTagAggregate>>;
  issueType: IssueType;
}

/** Input for creating a new fine. */
export interface IssueFineInput {
  amount: Scalars['Int'];
  fineTypeId: Scalars['Int'];
  fleetId: Scalars['Int'];
  images: Array<IssueFineInputImage>;
  invoiceId: Scalars['Int'];
  privateDescription?: InputMaybe<Scalars['String']>;
  publicDescription: Scalars['String'];
  vatRate: Scalars['Int'];
}

/** Image to be attached to the issued fine */
export interface IssueFineInputImage {
  id: Scalars['Int'];
  visibleToUser: Scalars['Boolean'];
}

/** Part of the vehicle a user can report has an issue */
export interface IssuePart {
  __typename?: 'IssuePart';
  tags?: Maybe<Array<Maybe<IssueTag>>>;
  type?: Maybe<ReportIssuePartEnum>;
  typeName?: Maybe<Scalars['String']>;
}

/** Tag related to issue part when reporting an issue */
export interface IssueTag {
  __typename?: 'IssueTag';
  id: Scalars['Int'];
  issueType: IssueType;
  name?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  vehicleTypes: Array<VehicleType>;
}

export interface IssueTagAggregate {
  __typename?: 'IssueTagAggregate';
  count: Scalars['Int'];
  tag: Scalars['String'];
}

/** Type of issue tags */
export interface IssueType {
  __typename?: 'IssueType';
  id: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
}

/** A driving license */
export interface License {
  __typename?: 'License';
  createdAt: Scalars['Date'];
  dateOfBirth?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  images: LicenseImage;
  issuingDate?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Audit history log for the specified license. */
  licenseAudit: Array<LicenseAudit>;
  /** The minimum age for which a license is considered valid. */
  minimumDrivingAge?: Maybe<Scalars['Int']>;
  reviewedAutomatically: Scalars['Boolean'];
  reviewedByUser?: Maybe<User>;
  reviewedStatus: LicenseVerificationStatusEnum;
  status: LicenseVerificationStatusEnum;
  verifiedAt?: Maybe<Scalars['Date']>;
  workflowId: Scalars['String'];
}

export enum LicenseAction {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
}

/** A driving license history entry */
export interface LicenseAudit {
  __typename?: 'LicenseAudit';
  auditAction: Scalars['String'];
  auditCreatedAt: Scalars['Date'];
  auditCreatedByUser?: Maybe<User>;
  dateOfBirth?: Maybe<Scalars['String']>;
  documentNumber?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  issuingDate?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseId: Scalars['Int'];
  reviewedAutomatically: Scalars['Boolean'];
  reviewedByUser?: Maybe<User>;
  status: LicenseVerificationStatusEnum;
  verifiedAt?: Maybe<Scalars['Date']>;
  workflowId: Scalars['String'];
}

export interface LicenseBlocklistInput {
  licenseId: Scalars['Int'];
  reason: Scalars['String'];
}

export interface LicenseImage {
  __typename?: 'LicenseImage';
  back?: Maybe<Scalars['String']>;
  face?: Maybe<Scalars['String']>;
  front?: Maybe<Scalars['String']>;
  liveness?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum LicenseVerificationStatusEnum {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Denied = 'DENIED',
  DeniedFraud = 'DENIED_FRAUD',
  DeniedUnsupportedCountry = 'DENIED_UNSUPPORTED_COUNTRY',
  DeniedUnsupportedType = 'DENIED_UNSUPPORTED_TYPE',
  ErrorNotReadableId = 'ERROR_NOT_READABLE_ID',
  Expired = 'EXPIRED',
  NoIdUploaded = 'NO_ID_UPLOADED',
  Pending = 'PENDING',
  TimedOut = 'TIMED_OUT',
  UnderAge = 'UNDER_AGE',
}

export enum LockColor {
  Blue = 'BLUE',
  Gold = 'GOLD',
  Green = 'GREEN',
  Grey = 'GREY',
  Purple = 'PURPLE',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

/** Center of the map on the users screen */
export interface MapCenter {
  latitude: Scalars['Float'];
  latitudeDelta: Scalars['Float'];
  longitude: Scalars['Float'];
  longitudeDelta: Scalars['Float'];
}

export interface MapCoordinates {
  accuracy?: InputMaybe<Scalars['Float']>;
  altitude?: InputMaybe<Scalars['Float']>;
  altitudeAccuracy?: InputMaybe<Scalars['Float']>;
  heading?: InputMaybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  speed?: InputMaybe<Scalars['Float']>;
}

export interface MarketCity {
  __typename?: 'MarketCity';
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  hasBikes: Scalars['Boolean'];
  hasCarSharing: Scalars['Boolean'];
  hasMopeds: Scalars['Boolean'];
  hasScooters: Scalars['Boolean'];
  hasTaxis: Scalars['Boolean'];
  id: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  population?: Maybe<Scalars['Int']>;
  regulationStatusScooters: Market_CityregulationStatusScootersEnumType;
  regulationStatusTaxis: Market_CityregulationStatusTaxisEnumType;
  services: Array<MarketServiceLocation>;
  timezone?: Maybe<Scalars['String']>;
}

export interface MarketCountry {
  __typename?: 'MarketCountry';
  countryCode: Scalars['String'];
  name: Scalars['String'];
}

export interface MarketService {
  __typename?: 'MarketService';
  color?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface MarketServiceLocation {
  __typename?: 'MarketServiceLocation';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface MediaAsset {
  __typename?: 'MediaAsset';
  darkUrl: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  lightUrl: Scalars['String'];
  name: Scalars['String'];
  width: Scalars['Int'];
}

export interface MoveVehicleToFleetInput {
  fleetId: Scalars['Int'];
  vehicleCode?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  /** Accept Terms of Service */
  acceptToS: ToS;
  /**
   *
   *       Driver can accept a trip request before it expires.
   *       Trip request's vehicle must be equal to the driver's active vehicle.
   *
   */
  acceptTrip: TripRequest;
  addAnnouncement?: Maybe<Scalars['Boolean']>;
  addAppleAuth: User;
  /** Add an Apple Pay card for payment. */
  addApplePay: Card;
  /** Add a card for payment. */
  addCard: AddCard;
  addFleet: Fleet;
  addFleetUser: User;
  addFleetUsers: Array<Maybe<User>>;
  addGoogleAuth: User;
  /** Add issue tag */
  addIssueTag: IssueTag;
  addLicense: License;
  /** Add user to an organization */
  addOrgUser: AddOrgUser;
  /** Add an organization to be used as an company account for employees. */
  addOrganization: Organization;
  /** Adds a part */
  addPart: Part;
  /** Adds an inventory of a part for a certain fleet */
  addPartInventory?: Maybe<Scalars['Boolean']>;
  addPhoneAuth: User;
  /** Store the region the user signed up in on the user */
  addSignUpRegion: User;
  /** Add staff shift */
  addStaffShift: Scalars['Boolean'];
  /** Adding a vehicle to an active staff shift */
  addVehicleToShift: StaffShift;
  blockLicense: License;
  /** Cancel a trip */
  cancelTripDriver: Scalars['Boolean'];
  /** Updates vehicle APN */
  changeApn: Vehicle;
  changeIot: Scalars['Boolean'];
  /** Change lock color */
  changeVehicleLockColor: Vehicle;
  /** Complete a single vehicle step within his planner */
  completeStep: VehicleStep;
  completeTutorial: Scalars['Boolean'];
  createCouponGroup: CouponGroup;
  /** Create a Registration Campaign */
  createRegistrationCampaign: RegistrationCampaign;
  /** Create a new vehicle */
  createVehicle: TaxiVehicle;
  /** Deletes a zone */
  deleteZone?: Maybe<Zone>;
  /** Accept the driver terms of service */
  driverAcceptTerms: Driver;
  driverCompleteOnboarding: Driver;
  /** Create or update the driver profile */
  driverUpdateProfile?: Maybe<Driver>;
  driverUpdatePushToken: Scalars['Boolean'];
  editFleet: Fleet;
  /** Edit member of an organization */
  editOrgUser: OrganizationUser;
  /** Edit an organization. */
  editOrganization: Organization;
  endGroupRent: Array<Maybe<Trip>>;
  endRent: Trip;
  /** Ending a shift for a staff member of Hopp */
  endShift: Scalars['Boolean'];
  /** Finish a vehicle checkup */
  finishVehicleCheckup: FinishVehicleCheckupReturn;
  fixIssues?: Maybe<Scalars['Boolean']>;
  giveCoupon: GiveCouponPayload;
  /** Issue a new fine */
  issueFine: Fine;
  /** Join an organization as an employee */
  joinOrganization: Organization;
  locateVehicle: Vehicle;
  logDemandLocation: Scalars['Boolean'];
  /** Makes hexagon grid */
  makeHexagonGrid: Scalars['Boolean'];
  moveVehicleToFleet: Vehicle;
  purchaseMarketplaceCoupon: Coupon;
  rateDriver: Scalars['Boolean'];
  /** Driver can rate a rider after a trip */
  rateRider: Scalars['Boolean'];
  redeemCoupon: Coupon;
  refundInvoice: Invoice;
  /** @deprecated Use refundInvoice mutation instead */
  refundTrip: Trip;
  /**
   *
   *       Driver can reject a trip request by id.
   *       Trip request's vehicle must be equal to authenticated driver's active vehicle.
   *
   */
  rejectTrip: TripRequest;
  removeAnnouncements?: Maybe<Scalars['Boolean']>;
  removeCard?: Maybe<Scalars['Boolean']>;
  /** Removes users with elevated privileges from fleet */
  removeFleetUsers?: Maybe<Scalars['Boolean']>;
  /** Remove issue tags */
  removeIssueTags?: Maybe<Scalars['Boolean']>;
  /** Remove a member from an organization. */
  removeOrgUser: RemoveOrganizationUser;
  /** Removes an inventory of a part for a certain fleet */
  removePartInventory?: Maybe<Scalars['Boolean']>;
  /** Remove staff shifts */
  removeStaffShifts?: Maybe<Scalars['Boolean']>;
  removeVehicle: Scalars['Boolean'];
  rent: ActiveTrip;
  reportIssues?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Add a parking violation */
  reportParkingViolation: ParkingViolation;
  requestQuote: RequestQuote;
  requestTrip: TaxiTrip;
  requestUserDeletion: Scalars['Boolean'];
  retryOrgPayments: Organization;
  retryPayment: Trip;
  /** Retry unpaid invoices by charging a specific payment method */
  retryUnpaidInvoices: Array<Invoice>;
  reviewLicense?: Maybe<License>;
  riderCancelTrip: Scalars['Boolean'];
  setActiveFleets: TaxiVehicle;
  setActiveVehicle: Driver;
  /** Set vehicle available for trip requests */
  setOnline: TaxiVehicle;
  setOrganizationCoupon?: Maybe<Coupon>;
  setPrimaryCard: Card;
  setVehicleInfoOwnerDriver: VehicleInfo;
  /** Starting a shift for a staff member of Hopp */
  startShift: StaffShift;
  /** Start a vehicle checkup */
  startVehicleCheckup: Array<VehicleCheck>;
  /** Switch locale for the user in the app */
  switchLocale: User;
  toggleIotMode: Scalars['Boolean'];
  toggleVehicleLock: Vehicle;
  toggleVehicleLockByDeviceId: Scalars['Boolean'];
  toggleVehicleLocks: Array<Vehicle>;
  unblockLicense: License;
  /** Unlock battery of a vehicle */
  unlockBattery: Scalars['Boolean'];
  unlockHelmet: Vehicle;
  updateAnnouncement?: Maybe<Scalars['Boolean']>;
  updateDriverApproval: Driver;
  updateDriverDetails: Driver;
  /** Updates the role for a fleet user */
  updateFleetUser: Scalars['Boolean'];
  /** Updates a part */
  updatePart: Part;
  /** Updates an inventory of a part for a certain fleet */
  updatePartInventory?: Maybe<Scalars['Boolean']>;
  updatePushToken: User;
  /** Updates rider's coordinates, heading and speed */
  updateRiderPosition: Position;
  /** Update staff shift */
  updateStaffShift: Scalars['Boolean'];
  updateTaxiVehicleFleets: Array<VehicleFleet>;
  updateTripPaymentMethod: ActiveTrip;
  /** Update user information */
  updateUser: User;
  updateVehicleApproval: TaxiVehicle;
  /** Updates vehicle's coordinates, heading and speed */
  updateVehiclePosition: Position;
  /** Updates vehicle status, only admins can do this */
  updateVehicleStatus: Vehicle;
  /** Updates zone data */
  updateZones: Array<Zone>;
  uploadLogs: Scalars['Boolean'];
}

export type MutationAcceptToSArgs = {
  acceptedAt?: InputMaybe<Scalars['DateTime']>;
  tosId?: InputMaybe<Scalars['Int']>;
};

export type MutationAcceptTripArgs = {
  tripRequestId: Scalars['ID'];
};

export type MutationAddAnnouncementArgs = {
  fleetId: Scalars['Int'];
  input: AnnouncementInput;
};

export type MutationAddAppleAuthArgs = {
  input: AddAppleAuthInput;
};

export type MutationAddApplePayArgs = {
  input: AddApplePayInput;
};

export type MutationAddCardArgs = {
  cardToken: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
};

export type MutationAddFleetArgs = {
  input: AddFleetInput;
};

export type MutationAddFleetUserArgs = {
  input: FleetUserInput;
};

export type MutationAddFleetUsersArgs = {
  fleetId: Scalars['Int'];
  phones: Array<InputMaybe<Scalars['String']>>;
  role?: InputMaybe<UserRoleEnum>;
};

export type MutationAddGoogleAuthArgs = {
  input: AddGoogleAuthInput;
};

export type MutationAddIssueTagArgs = {
  input: InputIssueTag;
};

export type MutationAddLicenseArgs = {
  input: AddLicenseInput;
};

export type MutationAddOrgUserArgs = {
  input: InputAddOrgUser;
};

export type MutationAddOrganizationArgs = {
  input: InputAddOrganization;
};

export type MutationAddPartArgs = {
  part: InputPart;
};

export type MutationAddPartInventoryArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  partInventory: InputPartInventory;
};

export type MutationAddPhoneAuthArgs = {
  input: AddPhoneAuthInput;
};

export type MutationAddSignUpRegionArgs = {
  regionId: Scalars['Int'];
};

export type MutationAddStaffShiftArgs = {
  fleetId: Scalars['Int'];
  staffShift: InputStaffShift;
};

export type MutationAddVehicleToShiftArgs = {
  vehicleId: Scalars['Int'];
};

export type MutationBlockLicenseArgs = {
  input: LicenseBlocklistInput;
};

export type MutationCancelTripDriverArgs = {
  reason: Scalars['String'];
  tripId: Scalars['ID'];
};

export type MutationChangeApnArgs = {
  apn: Scalars['String'];
  vehicleId: Scalars['Int'];
};

export type MutationChangeIotArgs = {
  imei?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  vehicleId: Scalars['Int'];
};

export type MutationChangeVehicleLockColorArgs = {
  color: LockColor;
  vehicleId: Scalars['Int'];
};

export type MutationCompleteStepArgs = {
  stepId: Scalars['ID'];
};

export type MutationCompleteTutorialArgs = {
  input: CompleteTutorialInput;
};

export type MutationCreateCouponGroupArgs = {
  input: CreateCouponGroupInput;
};

export type MutationCreateRegistrationCampaignArgs = {
  endDate: Scalars['DateTime'];
  freeRides?: InputMaybe<Scalars['Int']>;
  regionId: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type MutationCreateVehicleArgs = {
  driverId?: InputMaybe<Scalars['ID']>;
  licensePlate: Scalars['String'];
};

export type MutationDeleteZoneArgs = {
  fleetId: Scalars['Int'];
  id: Scalars['Int'];
};

export type MutationDriverCompleteOnboardingArgs = {
  licensePlate?: InputMaybe<Scalars['String']>;
  permitNumber?: InputMaybe<Scalars['String']>;
  permitType: TaxiPermitType;
};

export type MutationDriverUpdateProfileArgs = {
  input?: InputMaybe<UpdateDriverInput>;
  profileImage?: InputMaybe<Scalars['Upload']>;
};

export type MutationDriverUpdatePushTokenArgs = {
  token: Scalars['String'];
};

export type MutationEditFleetArgs = {
  input: EditFleetInput;
};

export type MutationEditOrgUserArgs = {
  input: InputEditOrgUser;
};

export type MutationEditOrganizationArgs = {
  input: InputEditOrganization;
};

export type MutationEndGroupRentArgs = {
  input?: InputMaybe<EndGroupRentInput>;
};

export type MutationEndRentArgs = {
  payOutOfBoundsFee?: InputMaybe<Scalars['Boolean']>;
  tripId?: InputMaybe<Scalars['Int']>;
  userLocation?: InputMaybe<UserLocationInput>;
};

export type MutationEndShiftArgs = {
  shiftId?: InputMaybe<Scalars['Int']>;
};

export type MutationFinishVehicleCheckupArgs = {
  answerIds: Array<InputMaybe<Scalars['Int']>>;
  duration: Scalars['Int'];
  vehicleId: Scalars['Int'];
};

export type MutationFixIssuesArgs = {
  comment?: InputMaybe<Scalars['String']>;
  issueTypeId?: InputMaybe<Scalars['Int']>;
  parts?: InputMaybe<Array<InputMaybe<InputRepairPart>>>;
  realIssue?: InputMaybe<ReportIssuePartEnum>;
  realIssueTypeId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ReportIssuePartEnum>;
  vehicleId: Scalars['Int'];
};

export type MutationGiveCouponArgs = {
  input: GiveCouponInput;
};

export type MutationIssueFineArgs = {
  input: IssueFineInput;
};

export type MutationJoinOrganizationArgs = {
  joinId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationLocateVehicleArgs = {
  vehicleId: Scalars['Int'];
};

export type MutationLogDemandLocationArgs = {
  distance?: InputMaybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  walkingDistance?: InputMaybe<Scalars['Float']>;
};

export type MutationMakeHexagonGridArgs = {
  fleetId: Scalars['Int'];
  hexagonSize?: InputMaybe<Scalars['Int']>;
  serviceDistance?: InputMaybe<Scalars['Int']>;
};

export type MutationMoveVehicleToFleetArgs = {
  input: MoveVehicleToFleetInput;
};

export type MutationPurchaseMarketplaceCouponArgs = {
  input: PurchaseMarketplaceCouponInput;
};

export type MutationRateDriverArgs = {
  input: TripRatingInput;
};

export type MutationRateRiderArgs = {
  input: TripRatingInput;
};

export type MutationRedeemCouponArgs = {
  input: RedeemCouponInput;
};

export type MutationRefundInvoiceArgs = {
  invoiceId: Scalars['Int'];
  invoiceReasonDescription?: InputMaybe<Scalars['String']>;
  invoiceReasonTagId?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Array<InvoiceItemInput>>;
};

export type MutationRefundTripArgs = {
  items?: InputMaybe<Array<InvoiceItemInput>>;
  tripId: Scalars['Int'];
};

export type MutationRejectTripArgs = {
  tripRequestId: Scalars['ID'];
};

export type MutationRemoveAnnouncementsArgs = {
  announcementIds: Array<Scalars['Int']>;
};

export type MutationRemoveCardArgs = {
  id: Scalars['Int'];
};

export type MutationRemoveFleetUsersArgs = {
  fleetId: Scalars['Int'];
  userIds: Array<InputMaybe<Scalars['Int']>>;
};

export type MutationRemoveIssueTagsArgs = {
  issueTagIds: Array<Scalars['Int']>;
};

export type MutationRemoveOrgUserArgs = {
  orgId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type MutationRemovePartInventoryArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  partInventoryIds: Array<Scalars['Int']>;
};

export type MutationRemoveStaffShiftsArgs = {
  fleetId: Scalars['Int'];
  staffShiftIds: Array<InputMaybe<Scalars['Int']>>;
};

export type MutationRemoveVehicleArgs = {
  id: Scalars['ID'];
};

export type MutationRentArgs = {
  cardId?: InputMaybe<Scalars['Int']>;
  code: Scalars['String'];
  initialStatus?: InputMaybe<RentStatus>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
};

export type MutationReportIssuesArgs = {
  issues?: InputMaybe<Array<InputMaybe<InputIssue>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  tripId?: InputMaybe<Scalars['Int']>;
  userLat?: InputMaybe<Scalars['Float']>;
  userLng?: InputMaybe<Scalars['Float']>;
  vehicleCode?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
  vehicleLat?: InputMaybe<Scalars['Float']>;
  vehicleLng?: InputMaybe<Scalars['Float']>;
};

export type MutationReportParkingViolationArgs = {
  input: ParkingViolationInput;
};

export type MutationRequestQuoteArgs = {
  departure?: InputMaybe<Scalars['DateTime']>;
  dropoff: AddressInput;
  pickup: AddressInput;
  stops?: InputMaybe<Array<AddressInput>>;
};

export type MutationRequestTripArgs = {
  fleetId: Scalars['ID'];
  passengerCount: Scalars['Int'];
  paymentId?: InputMaybe<Scalars['ID']>;
  paymentMethod?: InputMaybe<Scalars['ID']>;
  quoteId: Scalars['ID'];
};

export type MutationRetryOrgPaymentsArgs = {
  input: RetryOrgPaymentsInput;
};

export type MutationRetryPaymentArgs = {
  cardId?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  tripId: Scalars['Int'];
};

export type MutationRetryUnpaidInvoicesArgs = {
  input: RetryUnpaidInvoicesInput;
};

export type MutationReviewLicenseArgs = {
  input: ReviewLicenseInput;
};

export type MutationRiderCancelTripArgs = {
  reason: Scalars['String'];
};

export type MutationSetActiveFleetsArgs = {
  fleetIds: Array<Scalars['ID']>;
};

export type MutationSetActiveVehicleArgs = {
  driverId?: InputMaybe<Scalars['ID']>;
  vehicleId: Scalars['ID'];
};

export type MutationSetOnlineArgs = {
  online: Scalars['Boolean'];
};

export type MutationSetOrganizationCouponArgs = {
  input: SetOrganizationCouponInput;
};

export type MutationSetPrimaryCardArgs = {
  id: Scalars['Int'];
};

export type MutationSetVehicleInfoOwnerDriverArgs = {
  driverId?: InputMaybe<Scalars['ID']>;
  vehicleInfoId: Scalars['ID'];
};

export type MutationStartShiftArgs = {
  phone: Scalars['String'];
  regionId?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type MutationStartVehicleCheckupArgs = {
  vehicleId: Scalars['Int'];
};

export type MutationSwitchLocaleArgs = {
  locale: Scalars['String'];
};

export type MutationToggleIotModeArgs = {
  mode: VehicleIotMode;
  vehicleId: Scalars['Int'];
};

export type MutationToggleVehicleLockArgs = {
  lock: Scalars['Boolean'];
  vehicleId: Scalars['Int'];
};

export type MutationToggleVehicleLockByDeviceIdArgs = {
  deviceId: Scalars['String'];
  lock: Scalars['Boolean'];
};

export type MutationToggleVehicleLocksArgs = {
  lock: Scalars['Boolean'];
};

export type MutationUnblockLicenseArgs = {
  input: LicenseBlocklistInput;
};

export type MutationUnlockBatteryArgs = {
  vehicleId: Scalars['Int'];
};

export type MutationUnlockHelmetArgs = {
  vehicleId: Scalars['Int'];
};

export type MutationUpdateAnnouncementArgs = {
  id: Scalars['Int'];
  input: AnnouncementInput;
};

export type MutationUpdateDriverApprovalArgs = {
  approved: Scalars['Boolean'];
  driverId: Scalars['ID'];
};

export type MutationUpdateDriverDetailsArgs = {
  driverId: Scalars['ID'];
  input: UpdateDriverInput;
};

export type MutationUpdateFleetUserArgs = {
  fleetId: Scalars['Int'];
  role?: InputMaybe<UserRoleEnum>;
  selectedWatchers?: InputMaybe<Array<WatcherEventTypeEnum>>;
  userId: Scalars['Int'];
};

export type MutationUpdatePartArgs = {
  part: InputPart;
};

export type MutationUpdatePartInventoryArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  partInventory: InputPartInventory;
};

export type MutationUpdatePushTokenArgs = {
  pushToken?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateRiderPositionArgs = {
  input: UpdatePositionInput;
};

export type MutationUpdateStaffShiftArgs = {
  fleetId: Scalars['Int'];
  staffShift: InputStaffShift;
};

export type MutationUpdateTaxiVehicleFleetsArgs = {
  fleetIds: Array<Scalars['ID']>;
  vehicleId: Scalars['ID'];
};

export type MutationUpdateTripPaymentMethodArgs = {
  input: UpdateTripPaymentMethodInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateVehicleApprovalArgs = {
  approved: Scalars['Boolean'];
  vehicleId: Scalars['ID'];
};

export type MutationUpdateVehiclePositionArgs = {
  input: UpdatePositionInput;
};

export type MutationUpdateVehicleStatusArgs = {
  event: VehicleEvent;
  reason?: InputMaybe<VehicleEventReason>;
  vehicleId: Scalars['Int'];
};

export type MutationUpdateZonesArgs = {
  input: UpdateZonesInput;
};

export type MutationUploadLogsArgs = {
  hoppLogLines: Array<UserLogEntryInput>;
};

export interface NewOperator {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  xeroContactId: Scalars['String'];
}

export interface NewRegion {
  countryCode: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  requiredLanguages: Array<Scalars['String']>;
}

export enum OldCouponType {
  Credit = 'CREDIT',
  FreeRide = 'FREE_RIDE',
}

/** An Operator of a Hopp franchise */
export interface Operator {
  __typename?: 'Operator';
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  receiptText: Scalars['String'];
  regions: Region;
}

/** An invite to an organization. */
export interface OrgInvite {
  __typename?: 'OrgInvite';
  id: Scalars['Int'];
  name: Scalars['String'];
}

export enum OrgUserRoleEnum {
  Admin = 'ADMIN',
  User = 'USER',
}

/** An organization that can be used as a payment method. */
export interface Organization {
  __typename?: 'Organization';
  address?: Maybe<Scalars['String']>;
  card?: Maybe<Card>;
  cardId?: Maybe<Scalars['Int']>;
  coupons: Array<Coupon>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  hasDebt: Scalars['Boolean'];
  id: Scalars['Int'];
  invoiceInfo?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  isMissingRequiredCard?: Maybe<Scalars['Boolean']>;
  joinId: Scalars['String'];
  joinUrl: Scalars['String'];
  monthlyPayments: Scalars['Boolean'];
  name: Scalars['String'];
  organizationInvoices?: Maybe<Array<OrganizationInvoice>>;
  postalCode?: Maybe<Scalars['String']>;
  restrictions: OrganizationRestrictions;
  ridesFree: Scalars['Boolean'];
  taxNumber?: Maybe<Scalars['String']>;
  unpaidInvoices: Array<UnpaidOrganizationInvoice>;
  usageRestriction?: Maybe<OrganizationUsageRestrictionOutput>;
  userCount?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<OrganizationUser>>;
}

/** An invoice for an organization. */
export interface OrganizationInvoice {
  __typename?: 'OrganizationInvoice';
  amountDue: Scalars['Int'];
  currency: CurrencyEnum;
  endDate: Scalars['Date'];
  id: Scalars['Int'];
  invoiceNumber: Scalars['Int'];
  oid: Scalars['String'];
  operator: Operator;
  outstandingAmount?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
}

export type OrganizationRestrictionLocation = Country | Region;

/** Restrictions to apply on the organizations */
export interface OrganizationRestrictionMainInput {
  locations?: InputMaybe<Array<OrganizationRestrictionsLocationInput>>;
  timePerDay?: InputMaybe<OrganizationUsageRestrictionTimeLimit>;
  vehicleTypeGroups?: InputMaybe<Array<VehicleTypeGroupEnum>>;
}

/** All usage restrictions applicable when using this organization as payment method. */
export interface OrganizationRestrictions {
  __typename?: 'OrganizationRestrictions';
  locations?: Maybe<Array<OrganizationRestrictionLocation>>;
  timePerDay?: Maybe<OrganizationUsageRestrictionTimeLimitOutput>;
  vehicleTypeGroups?: Maybe<Array<VehicleTypeGroupEnum>>;
}

/** Usage restriction for a location. Either countryCode or regionId needs to be defined. */
export interface OrganizationRestrictionsLocationInput {
  countryCode?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
}

/** Usage restriction for organizations */
export interface OrganizationUsageRestriction {
  timePerDay?: InputMaybe<OrganizationUsageRestrictionTimeLimit>;
  vehicleTypeGroups?: InputMaybe<Array<VehicleTypeGroupEnum>>;
}

/** Usage restriction for one day */
export interface OrganizationUsageRestrictionDay {
  from: Scalars['String'];
  to: Scalars['String'];
}

/** Usage restriction for one day */
export interface OrganizationUsageRestrictionDayOutput {
  __typename?: 'OrganizationUsageRestrictionDayOutput';
  from: Scalars['String'];
  to: Scalars['String'];
}

/** Usage restriction for organizations */
export interface OrganizationUsageRestrictionOutput {
  __typename?: 'OrganizationUsageRestrictionOutput';
  timePerDay?: Maybe<OrganizationUsageRestrictionTimeLimitOutput>;
  vehicleTypeGroups?: Maybe<Array<VehicleTypeGroupEnum>>;
}

/** Usage restriction of days for organizations */
export interface OrganizationUsageRestrictionTimeLimit {
  friday: OrganizationUsageRestrictionDay;
  monday: OrganizationUsageRestrictionDay;
  saturday: OrganizationUsageRestrictionDay;
  sunday: OrganizationUsageRestrictionDay;
  thursday: OrganizationUsageRestrictionDay;
  tuesday: OrganizationUsageRestrictionDay;
  wednesday: OrganizationUsageRestrictionDay;
}

/** Usage restriction of days for organizations */
export interface OrganizationUsageRestrictionTimeLimitOutput {
  __typename?: 'OrganizationUsageRestrictionTimeLimitOutput';
  friday: OrganizationUsageRestrictionDayOutput;
  monday: OrganizationUsageRestrictionDayOutput;
  saturday: OrganizationUsageRestrictionDayOutput;
  sunday: OrganizationUsageRestrictionDayOutput;
  thursday: OrganizationUsageRestrictionDayOutput;
  tuesday: OrganizationUsageRestrictionDayOutput;
  wednesday: OrganizationUsageRestrictionDayOutput;
}

/** A member of an organization. */
export interface OrganizationUser {
  __typename?: 'OrganizationUser';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  oid: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
}

export interface Organizations {
  __typename?: 'Organizations';
  organizations: Array<Organization>;
  pageInfo: CursorPageInfo;
}

export interface PageInfo {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int'];
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
}

export interface PaginatedCoupons {
  __typename?: 'PaginatedCoupons';
  coupons: Array<Coupon>;
  pageInfo: CursorPageInfo;
}

export enum ParkStatusEnum {
  CanPark = 'CAN_PARK',
  NoPark = 'NO_PARK',
  OutOfBounds = 'OUT_OF_BOUNDS',
  ParkingStationRequired = 'PARKING_STATION_REQUIRED',
}

export interface ParkingViolation {
  __typename?: 'ParkingViolation';
  id: Scalars['Int'];
  uploadId: Scalars['Int'];
  userId: Scalars['Int'];
  vehicleId: Scalars['Int'];
  vehicleSelectedBy: Parking_ViolationvehicleSelectedByEnumType;
}

export interface ParkingViolationInput {
  uploadId: Scalars['Int'];
  userLocation?: InputMaybe<UserLocationInput>;
  vehicleId?: InputMaybe<Scalars['Int']>;
}

/** A part used to repair vehicles */
export interface Part {
  __typename?: 'Part';
  currency?: Maybe<CurrencyEnum>;
  id: Scalars['Int'];
  manufacturerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  qty?: Maybe<Scalars['Int']>;
  salvaged?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ParttypeEnumType>;
  vehicleTypes: Array<VehicleType>;
}

/** Inventory status of a part for a certain fleet */
export interface PartInventory {
  __typename?: 'PartInventory';
  currentAmount?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  minAmount?: Maybe<Scalars['Int']>;
  part: Part;
  partId: Scalars['Int'];
  recommendedAmount?: Maybe<Scalars['Int']>;
}

/** A payment on an invoice. */
export interface Payment {
  __typename?: 'Payment';
  amount: Scalars['Int'];
  card?: Maybe<Card>;
  cardType?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  currency: CurrencyEnum;
  id: Scalars['Int'];
  isDebit?: Maybe<Scalars['Boolean']>;
  paymentType: PaymentpaymentTypeEnumType;
}

export interface Payment3ds {
  __typename?: 'Payment3ds';
  acsForm: Scalars['String'];
}

/** Aggregates for payments, grouped by a date */
export interface PaymentAggregate {
  __typename?: 'PaymentAggregate';
  paidDebtRefundSum: Scalars['BigInt'];
  paidDebtRevenueSum: Scalars['BigInt'];
  refundSum: Scalars['BigInt'];
  revenueSum: Scalars['BigInt'];
}

/** A payment method for a trip, such as a card or an organization. */
export interface PaymentMethod {
  __typename?: 'PaymentMethod';
  available: Scalars['Boolean'];
  icon: MediaAsset;
  id: Scalars['String'];
  isSelected: Scalars['Boolean'];
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: PaymentMethodTypeEnum;
}

export enum PaymentMethodContextEnum {
  BuyCoupon = 'BUY_COUPON',
  RetryPayment = 'RETRY_PAYMENT',
  RideShareStart = 'RIDE_SHARE_START',
  TaxiRequestQuote = 'TAXI_REQUEST_QUOTE',
}

export enum PaymentMethodTypeEnum {
  ApplePay = 'APPLE_PAY',
  Card = 'CARD',
  Coupon = 'COUPON',
  Org = 'ORG',
}

export enum PeriodUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export enum PeriodUnitEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export interface Position {
  __typename?: 'Position';
  accuracy?: Maybe<Scalars['Float']>;
  altitude?: Maybe<Scalars['Float']>;
  coordinates?: Maybe<TaxiCoordinates>;
  heading?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  speed?: Maybe<Scalars['Float']>;
  trip?: Maybe<TaxiTrip>;
  updatedAt: Scalars['DateTime'];
}

/** Pre-auth for trips */
export interface PreAuth {
  __typename?: 'PreAuth';
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  amountReturned: Scalars['Int'];
  currency: Scalars['String'];
  helpText: Scalars['String'];
  id: Scalars['String'];
}

/** Vehicle pre-rental information */
export interface PreRentInfo {
  __typename?: 'PreRentInfo';
  currency: Scalars['String'];
  discount: Scalars['Int'];
  fallbackPaymentMethod?: Maybe<Scalars['String']>;
  fallbackPaymentMethodNotice?: Maybe<Scalars['String']>;
  preAuthAmount: Scalars['Int'];
  preAuthNotice?: Maybe<Scalars['String']>;
  priceInfo: PriceInfo;
  priceShortText: Scalars['String'];
}

export interface PriceInfo {
  __typename?: 'PriceInfo';
  description?: Maybe<Scalars['String']>;
  items: Array<PriceInfoItem>;
  title: Scalars['String'];
}

export interface PriceInfoItem {
  __typename?: 'PriceInfoItem';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
}

export interface PublicCouponGroup {
  __typename?: 'PublicCouponGroup';
  amount: Scalars['Int'];
  couponType: CouponType;
  criteria: Array<CouponCriteria>;
  currency: CurrencyEnum;
  details: Array<CouponDetail>;
  glimpseDetails: Array<Scalars['String']>;
  icons: Array<MediaAsset>;
  id: Scalars['Int'];
  internalName?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  qrCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  validFor?: Maybe<Scalars['Int']>;
  vat: Scalars['Int'];
}

export interface PurchaseMarketplaceCouponInput {
  couponGroupId: Scalars['Int'];
  expectedCurrency?: InputMaybe<CurrencyEnum>;
  expectedPrice?: InputMaybe<Scalars['Int']>;
  location: UserLocationInput;
  paymentMethod: Scalars['String'];
}

export interface Query {
  __typename?: 'Query';
  Driver: Driver;
  TaxiFleet: TaxiFleet;
  TaxiTrip?: Maybe<TaxiTrip>;
  TaxiTripInvoice?: Maybe<TaxiTripInvoice>;
  TaxiVehicle: TaxiVehicle;
  accountingConfigs: Array<AccountingConfig>;
  /** Gets all announcements that haven't expired yet */
  activeNotifications: Array<Announcement>;
  activeTaxiTrip?: Maybe<TaxiTrip>;
  /** Gets an active trip for the authenticated user. Includes information about the vehicle */
  activeTrip?: Maybe<ActiveTrip>;
  activeTripRequest?: Maybe<TripRequest>;
  /** Get locations where vehicles have waited for a short while */
  activeVehicles: Array<InactivityType>;
  /** Gets all active trips for the authenticated user. */
  allActiveTrips: Array<ActiveTrip>;
  allCompletedTaxiTrips: TripConnection;
  /** Gets the authenticated user's finished trips with cursor pagination */
  allFinishedTrips: FinishedTrips;
  /** Gets all users for a certain fleet and certain roles if provided */
  allFleetUsers: Array<FleetUser>;
  /** Gets all fleets that the user has access to as admin/charger/maintenance and should thus show admin UI for in the app */
  allFleets: Array<Fleet>;
  /** Gets all fleets the user has access to in another role than user and following scopes (used for admin) */
  allFleetsAndScopes: Array<FleetAndScopes>;
  /** Gets all fleets with metrics */
  allFleetsWithMetrics: Array<FleetWithMetrics>;
  /** Gets all issues for vehicles in a certain fleet */
  allIssues: AllIssues;
  /** Get all organizations that the user belongs to or that are connected to a certain fleet */
  allOrganizations: Array<Organization>;
  /** Gets all part inventory for a certain fleet */
  allPartInventory: Array<PartInventory>;
  /** Gets all parts for the vehicle or vehicleType */
  allParts: Array<Part>;
  /** Gets all regions that have active Hopp operations */
  allRegions: Array<Region>;
  /** Gets all repairs for a vehicle */
  allRepairsForVehicle?: Maybe<Array<Maybe<Repair>>>;
  /**
   * Gets all data for issues, tags and issues localized by user locale
   * @deprecated Use reportIssueData instead, deprecated in version 5.6.0
   */
  allReportIssueData?: Maybe<ReportIssueDataDeprecated>;
  /** Gets all finished staff shifts for a certain fleet */
  allStaffShifts: StaffShifts;
  allTaxiDrivers: DriverConnection;
  allTaxiFleets: FleetConnection;
  allTaxiInvoices: TripInvoiceConnection;
  allTaxiTrips: TripConnection;
  allTaxiVehicles: VehicleConnection;
  /** Gets the authenticated user's trips */
  allTrips?: Maybe<Array<Maybe<Trip>>>;
  /** Gets all available vehicles for normal users, filters them for admins */
  allVehicles: Array<Vehicle>;
  /** Gets all vehicles in a fleet for an admin */
  allVehiclesInFleet: Array<Vehicle>;
  /** Gets all watcher events in a certain fleet or vehicle */
  allWatcherEvents: AllWatcherEvents;
  /** Gets all zones */
  allZones: Array<Zone>;
  /** Gets all zones without combining operating zones */
  allZonesAdmin: Array<Zone>;
  /** Get all locale versions of announcement */
  announcement?: Maybe<Announcement>;
  /** Gets all announcements */
  announcements: Array<Announcement>;
  autocomplete: TaxiGeoJsonFeatureCollection;
  autocompletePlace?: Maybe<TaxiGeoJsonFeature>;
  /** Get basic vehicle information by license plate number from Samgongustofa */
  basicVehicleInfo?: Maybe<VehicleInfo>;
  /** Gets cards that belong to the logged in user or cards with the same pan */
  cards: Array<Card>;
  /** Gets zones updated since the given date */
  changedZones: ChangedZones;
  /** Gets configuration for the app */
  configuration: Configuration;
  /** Gets all active countries */
  countries: Array<Country>;
  /** Returns a single coupon */
  coupon: Coupon;
  /** Get all coupon groups that are connected to a certain fleet with pagination */
  couponGroup: CouponGroup;
  /** Get all coupon groups that are connected to a certain fleet with pagination */
  couponGroups: CouponGroups;
  /** Returns all coupon types */
  couponTypes: Array<CouponType>;
  /** Returns all coupons available to the user */
  coupons: Array<Coupon>;
  /** Gets the id of the shift if the user is currently working */
  currentShift?: Maybe<StaffShift>;
  /** Gets all demands */
  demand: Array<Demand>;
  demoModeCode: Scalars['String'];
  directions?: Maybe<TaxiGeoJsonFeature>;
  /** Gets all discount zones */
  discountZones: Array<Zone>;
  driver?: Maybe<Driver>;
  driverAccountingPeriods: Array<DriverAccountingPeriod>;
  /** Gets all enabled features for the user */
  enabledFeatures: Array<Feature>;
  /** Gets user's fine by id */
  fine?: Maybe<Fine>;
  /** Gets all valid fine types */
  fineTypes: Array<FineType>;
  fleet?: Maybe<Fleet>;
  /** Gets hexagon grid */
  getHexagons: HexagonGrid;
  /** Gets part inventory by id for certain fleet */
  getPartInventory?: Maybe<PartInventory>;
  /** Gets a staff shift by id */
  getStaffShift: StaffShift;
  /** Checks if current user has accepted all tos */
  hasAcceptedTos: Scalars['Boolean'];
  /** Get all vehicles currently inactive */
  inactiveVehicles: Array<InactiviteVehicles>;
  /** Get inactive vehicles */
  inactivity: Array<InactivityType>;
  initiateJumioWorkflow: StartLicenseVerification;
  /** Get invoice history */
  invoiceHistory: InvoiceHistoryResult;
  /** Gets all invoice reason tags */
  invoiceReasonTags: Array<InvoiceReasonTag>;
  invoices: Array<Invoice>;
  /** Gets all issue tags for all vehicle types */
  issueTags: Array<IssueTag>;
  /** Gets all issue types for all vehicle types */
  issueTypes: Array<IssueType>;
  /** Get's the latest license for the user */
  license?: Maybe<License>;
  /** Returns all cities for a given filter */
  marketCities: Array<MarketCity>;
  /** Returns all countries for the autocomplete search */
  marketCountries: Array<MarketCountry>;
  /** Returns all services for the autocomplete search */
  marketServices: Array<MarketService>;
  /** Get all the stuff the marketplace has to offer */
  marketplaceOfferings: Array<PublicCouponGroup>;
  /** Gets the authenticated user */
  me: User;
  /** Get an aggregate of open issues */
  openIssueAggregate?: Maybe<Array<IssueAggregate>>;
  /** Gets all open issues for a vehicle */
  openIssuesForVehicle?: Maybe<Array<Maybe<Issue>>>;
  operators: Array<Operator>;
  /** Get info about organization invite */
  orgInvite?: Maybe<OrgInvite>;
  /** Get organization info for org admin */
  organization: Organization;
  /** Get all organizations that the user belongs to or that are connected to a certain fleet but with pagination */
  organizations: Organizations;
  /** Get a list of paginated coupons that are associated with a certain coupon group */
  paginatedCoupons: PaginatedCoupons;
  parkingRules: Array<Scalars['String']>;
  /** Parses IoT information from qr code */
  parseIotBarcode: IoT;
  /** Gets part by id */
  part?: Maybe<Part>;
  /**
   * Gets all the available payment methods for the logged in user
   * @deprecated Use paymentMethodsWithContext instead
   */
  paymentMethods: Array<PaymentMethod>;
  paymentMethodsWithContext: Array<PaymentMethod>;
  ping: Scalars['String'];
  /** Get information for renting the specified vehicle with the specified payment method */
  preRentInfo?: Maybe<PreRentInfo>;
  pudos?: Maybe<TaxiGeoJsonFeatureCollection>;
  /** Gets a region by id or map coordinates */
  region?: Maybe<Region>;
  /** Get requirements for renting the specified vehicle */
  rentRequirements: Array<RentRequirementEnum>;
  /** Gets all issue types and responding tags for certain vehicle type */
  reportIssueData: Array<ReportIssueData>;
  /**
   * Gets the required terms of service for the logged in user
   * @deprecated Use hasAcceptedTos instead
   */
  requiredToS?: Maybe<ToS>;
  /** Gets revenue statistics for a certain fleet over given period */
  revenueStatistics: RevenueStatistics;
  reverseLookup?: Maybe<Address>;
  /** Get ride history */
  rideHistory: RideHistoryResult;
  /** Gets a list of scopes and the roles that have access to those scopes */
  scopesAndRoles: Array<ScopeDefinition>;
  /** Gets an aggregate of staff stats during a specified period */
  staffShiftsAggregateByUser: Array<StaffShiftAggregateByUser>;
  /** Values needed for Strips payment sheet */
  stripePaymentSetup: StripePaymentSetup;
  /** Gets the support url for the region the user is in and his chosen locale */
  supportUrl: Scalars['String'];
  surroundingVehicles: Array<SurroundingVehicle>;
  taxiTripDemand: TaxiGeoJsonFeatureCollection;
  /** Gets any of the authenticated user's trips by id */
  trip?: Maybe<Trip>;
  tripState: RiderTripState;
  /** Gets trip statistics for a certain fleet over given period */
  tripStatistics: TripStatistics;
  /** Gets all sorts of aggregates grouped by date */
  tripsByDates: TripsByDates;
  /** Gets the tutorial for the current region the user is in */
  tutorial?: Maybe<Tutorial>;
  /** Gets available tutorials for the current region the user is in */
  tutorialsInRegion: Array<RegionTutorial>;
  /** Get all openings of app where demand is not met */
  unmetDemand: Array<Demand>;
  unratedTaxiTrip?: Maybe<TaxiTrip>;
  /** Gets a user */
  user?: Maybe<User>;
  /** Gets an available vehicle by id */
  vehicle?: Maybe<Vehicle>;
  /** Get aggregates for vehicle during it's lifetime */
  vehicleAggregates: VehicleAggregates;
  /** Get all vehicle clusters */
  vehicleClusters: Array<VehicleCluster>;
  /** Get all events for a specific vehicle */
  vehicleEvents: VehicleEventPage;
  vehiclePath: Array<VehicleTelemetryPoint>;
  /** Gets vehicle statistics for a certain fleet */
  vehicleStatistics: VehicleStatistics;
  vehicleType?: Maybe<VehicleType>;
  /** Gets all vehicle types */
  vehicleTypes: Array<VehicleType>;
  /** Gets documentation about the watchers in HOPP */
  watcherDocumentation: Array<WatcherEventDocument>;
  /** Gets a zone by id */
  zone?: Maybe<Zone>;
}

export type QueryDriverArgs = {
  id: Scalars['ID'];
};

export type QueryTaxiFleetArgs = {
  id: Scalars['ID'];
};

export type QueryTaxiTripArgs = {
  id: Scalars['ID'];
};

export type QueryTaxiTripInvoiceArgs = {
  id: Scalars['ID'];
};

export type QueryTaxiVehicleArgs = {
  id: Scalars['ID'];
};

export type QueryActiveNotificationsArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
  vehicleTypeGroup?: InputMaybe<VehicleTypeGroupEnum>;
};

export type QueryActiveTripArgs = {
  tripId?: InputMaybe<Scalars['Int']>;
};

export type QueryActiveVehiclesArgs = {
  endDate: Scalars['String'];
  endTime: Scalars['Int'];
  fleetId: Scalars['Int'];
  minutes: Scalars['Int'];
  startDate: Scalars['String'];
  startTime: Scalars['Int'];
};

export type QueryAllActiveTripsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllCompletedTaxiTripsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  driver: Scalars['Boolean'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryAllFinishedTripsArgs = {
  cardIdForPan?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  fleetId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllFleetUsersArgs = {
  fleetId: Scalars['Int'];
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryAllFleetsArgs = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};

export type QueryAllIssuesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  fleetId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  openOnly?: InputMaybe<Scalars['Boolean']>;
  repairedOnly?: InputMaybe<Scalars['Boolean']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllOrganizationsArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllPartInventoryArgs = {
  fleetId: Scalars['Int'];
};

export type QueryAllPartsArgs = {
  vehicleId?: InputMaybe<Scalars['Int']>;
  vehicleTypeId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllRegionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};

export type QueryAllRepairsForVehicleArgs = {
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllReportIssueDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};

export type QueryAllStaffShiftsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  fleetId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryAllTaxiDriversArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isPending?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryAllTaxiFleetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryAllTaxiInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  driver: Scalars['Boolean'];
  first?: InputMaybe<Scalars['Int']>;
  from: Scalars['DateTime'];
  last?: InputMaybe<Scalars['Int']>;
  to: Scalars['DateTime'];
};

export type QueryAllTaxiTripsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryAllTaxiVehiclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TaxiVehicleFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryAllTripsArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  mostRecent?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  unpaid?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllVehiclesArgs = {
  filter?: InputMaybe<VehicleFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  limitFromCenter?: InputMaybe<Scalars['Boolean']>;
  mapCenter?: InputMaybe<MapCenter>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllVehiclesInFleetArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllWatcherEventsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  fleetId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<WatcherEventTypeEnum>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllZonesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
};

export type QueryAllZonesAdminArgs = {
  fleetId: Scalars['Int'];
};

export type QueryAnnouncementArgs = {
  id: Scalars['Int'];
};

export type QueryAnnouncementsArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Scalars['Int']>;
};

export type QueryAutocompleteArgs = {
  coordinates?: InputMaybe<CoordinatesInput>;
  driver?: InputMaybe<Scalars['String']>;
  q: Scalars['String'];
};

export type QueryAutocompletePlaceArgs = {
  id: Scalars['String'];
};

export type QueryBasicVehicleInfoArgs = {
  licensePlate: Scalars['String'];
};

export type QueryCardsArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryChangedZonesArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  regionId: Scalars['Int'];
};

export type QueryCouponArgs = {
  input: CouponInput;
};

export type QueryCouponGroupArgs = {
  oid: Scalars['String'];
};

export type QueryCouponGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  excludeNotIssuedByUser: Scalars['Boolean'];
  excludeSingular: Scalars['Boolean'];
  fleetId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryCouponsArgs = {
  marketplaceOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryDemandArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryDemoModeCodeArgs = {
  imei: Scalars['String'];
};

export type QueryDirectionsArgs = {
  driver: Scalars['String'];
  locations: Array<CoordinatesInput>;
};

export type QueryDiscountZonesArgs = {
  regionId: Scalars['Int'];
};

export type QueryDriverAccountingPeriodsArgs = {
  fleetId: Scalars['Int'];
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
};

export type QueryEnabledFeaturesArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};

export type QueryFineArgs = {
  fineId: Scalars['Int'];
};

export type QueryFleetArgs = {
  id: Scalars['Int'];
};

export type QueryGetHexagonsArgs = {
  fleetId: Scalars['Int'];
};

export type QueryGetPartInventoryArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetStaffShiftArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  staffShiftId?: InputMaybe<Scalars['Int']>;
};

export type QueryHasAcceptedTosArgs = {
  regionId: Scalars['Int'];
};

export type QueryInactiveVehiclesArgs = {
  fleetId: Scalars['Int'];
  hours: Scalars['Int'];
};

export type QueryInactivityArgs = {
  endDate: Scalars['String'];
  endTime: Scalars['Int'];
  fleetId: Scalars['Int'];
  startDate: Scalars['String'];
  startTime: Scalars['Int'];
};

export type QueryInitiateJumioWorkflowArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};

export type QueryInvoiceHistoryArgs = {
  input?: InputMaybe<InvoiceHistoryInput>;
};

export type QueryInvoicesArgs = {
  status?: InputMaybe<InvoiceStatus>;
};

export type QueryIssueTagsArgs = {
  issueTypeId?: InputMaybe<Scalars['Int']>;
  vehicleTypeId?: InputMaybe<Scalars['Int']>;
};

export type QueryLicenseArgs = {
  workflowId?: InputMaybe<Scalars['String']>;
};

export type QueryMarketCitiesArgs = {
  continents?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  populationFrom: Scalars['Int'];
  populationTo: Scalars['Int'];
  services?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryMarketplaceOfferingsArgs = {
  location: UserLocationInput;
};

export type QueryOpenIssueAggregateArgs = {
  fleetId: Scalars['Int'];
};

export type QueryOpenIssuesForVehicleArgs = {
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryOrgInviteArgs = {
  id: Scalars['String'];
};

export type QueryOrganizationArgs = {
  id: Scalars['Int'];
};

export type QueryOrganizationsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  fleetId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryPaginatedCouponsArgs = {
  couponGroupId: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  fleetId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryParkingRulesArgs = {
  vehicleCode: Scalars['String'];
};

export type QueryParseIotBarcodeArgs = {
  qrCode: Scalars['String'];
  vehicleId: Scalars['Int'];
};

export type QueryPartArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentMethodsArgs = {
  includeUnavailable?: InputMaybe<Scalars['Boolean']>;
  isTaxi?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentMethodsWithContextArgs = {
  context: PaymentMethodContextEnum;
  couponGroupId?: InputMaybe<Scalars['Int']>;
  regionId?: InputMaybe<Scalars['Int']>;
  retryPaymentInvoiceIds?: InputMaybe<Array<Scalars['Int']>>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryPreRentInfoArgs = {
  paymentMethod?: InputMaybe<Scalars['String']>;
  vehicleId: Scalars['Int'];
};

export type QueryPudosArgs = {
  id: Scalars['String'];
};

export type QueryRegionArgs = {
  coordinates?: InputMaybe<MapCoordinates>;
  regionId?: InputMaybe<Scalars['Int']>;
  zoomLevel?: InputMaybe<Scalars['Int']>;
};

export type QueryRentRequirementsArgs = {
  vehicleCode: Scalars['String'];
};

export type QueryReportIssueDataArgs = {
  vehicleId: Scalars['Int'];
};

export type QueryRequiredToSArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};

export type QueryRevenueStatisticsArgs = {
  endDate: Scalars['DateTime'];
  fleetId: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type QueryReverseLookupArgs = {
  coordinates: CoordinatesInput;
  driver?: InputMaybe<Scalars['String']>;
};

export type QueryRideHistoryArgs = {
  input?: InputMaybe<RideHistoryInput>;
};

export type QueryStaffShiftsAggregateByUserArgs = {
  endDate: Scalars['String'];
  fleetId: Scalars['Int'];
  startDate: Scalars['String'];
};

export type QueryStripePaymentSetupArgs = {
  orgId?: InputMaybe<Scalars['Int']>;
};

export type QuerySupportUrlArgs = {
  regionId: Scalars['Int'];
  supportType?: InputMaybe<SupportType>;
};

export type QuerySurroundingVehiclesArgs = {
  position: CoordinatesInput;
};

export type QueryTaxiTripDemandArgs = {
  bounds: Array<CoordinatesInput>;
};

export type QueryTripArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  tripId?: InputMaybe<Scalars['Int']>;
  tripOid?: InputMaybe<Scalars['String']>;
};

export type QueryTripStateArgs = {
  tripId: Scalars['ID'];
};

export type QueryTripStatisticsArgs = {
  endDate: Scalars['DateTime'];
  fleetId: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type QueryTripsByDatesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  fleetId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  unit: PeriodUnitEnum;
};

export type QueryTutorialArgs = {
  regionId: Scalars['Int'];
  tutorialId?: InputMaybe<Scalars['Int']>;
  userLanguage?: InputMaybe<Scalars['String']>;
  vehicleCode?: InputMaybe<Scalars['String']>;
};

export type QueryTutorialsInRegionArgs = {
  regionId: Scalars['Int'];
};

export type QueryUnmetDemandArgs = {
  endDate: Scalars['String'];
  nearestBike: Scalars['Int'];
  startDate: Scalars['String'];
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  oid?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type QueryVehicleArgs = {
  code?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  fleetId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  qrScan?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Int']>;
  searchInPublicFleets?: InputMaybe<Scalars['Boolean']>;
  temporaryId?: InputMaybe<Scalars['String']>;
};

export type QueryVehicleAggregatesArgs = {
  vehicleId: Scalars['Int'];
};

export type QueryVehicleClustersArgs = {
  regionId: Scalars['Int'];
  vehicleTypeGroups?: InputMaybe<Array<VehicleTypeGroupEnum>>;
  withDetails?: InputMaybe<Scalars['Boolean']>;
  zoomLevel: Scalars['Int'];
};

export type QueryVehicleEventsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  vehicleId: Scalars['Int'];
};

export type QueryVehiclePathArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  vehicleCode?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
};

export type QueryVehicleStatisticsArgs = {
  fleetId?: InputMaybe<Scalars['Int']>;
};

export type QueryVehicleTypeArgs = {
  id: Scalars['Int'];
};

export type QueryZoneArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export interface RedeemCouponInput {
  publicId: Scalars['String'];
}

/** A region where Hopp has active operations. */
export interface Region {
  __typename?: 'Region';
  country: Country;
  countryCode: Scalars['String'];
  fleets: Array<Fleet>;
  id: Scalars['Int'];
  latitude: Scalars['Float'];
  location: GeoJsonPoint;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  registrationCampaigns: Array<RegistrationCampaign>;
  requiredLanguages: Array<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status: RegionstatusEnumType;
  timezone: Scalars['String'];
  vehicleTypeGroups: Array<VehicleTypeGroupEnum>;
}

/** Condensed tutorial */
export interface RegionTutorial {
  __typename?: 'RegionTutorial';
  displayName: Scalars['String'];
  icon: MediaAsset;
  id: Scalars['Int'];
  name: Scalars['String'];
}

/** A Registration Campaign */
export interface RegistrationCampaign {
  __typename?: 'RegistrationCampaign';
  couponGroupId?: Maybe<Scalars['Int']>;
  freeRides?: Maybe<Scalars['Int']>;
  from: Scalars['Date'];
  id: Scalars['Int'];
  regionId: Scalars['Int'];
  to: Scalars['Date'];
}

export interface RemoveOrganizationUser {
  __typename?: 'RemoveOrganizationUser';
  id: Scalars['Int'];
}

export enum RentRequirementEnum {
  DrivingLicense = 'DRIVING_LICENSE',
  DrivingLicensePending = 'DRIVING_LICENSE_PENDING',
  DrivingLicenseRejected = 'DRIVING_LICENSE_REJECTED',
  PhoneNumber = 'PHONE_NUMBER',
  Tos = 'TOS',
  Tutorial = 'TUTORIAL',
}

export enum RentStatus {
  Reserved = 'RESERVED',
  Trip = 'TRIP',
}

/** A vehicle repair */
export interface Repair {
  __typename?: 'Repair';
  comment?: Maybe<Scalars['String']>;
  fixedAt?: Maybe<Scalars['String']>;
  fixedBy?: Maybe<User>;
  fleet?: Maybe<Fleet>;
  id: Scalars['Int'];
  issues: Array<Issue>;
  parts?: Maybe<Array<Maybe<Part>>>;
  /** @deprecated Deprecated in version 5.6.0, use realIssueType instead */
  realIssue?: Maybe<Scalars['String']>;
  realIssueType?: Maybe<IssueType>;
  realIssueTypeId?: Maybe<Scalars['Int']>;
}

/** All info that we need to display the report issue screen */
export interface ReportIssueData {
  __typename?: 'ReportIssueData';
  id: Scalars['Int'];
  issueTags: Array<IssueTag>;
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}

/** All info that we need to display the report issue screen - deprecated in version 5.6.0 */
export interface ReportIssueDataDeprecated {
  __typename?: 'ReportIssueDataDeprecated';
  issueParts: Array<Maybe<IssuePart>>;
  lang?: Maybe<Scalars['String']>;
}

export enum ReportIssuePartEnum {
  Body = 'BODY',
  Brake = 'BRAKE',
  Handlebar = 'HANDLEBAR',
  Light = 'LIGHT',
  Other = 'OTHER',
  Stand = 'STAND',
  Suspension = 'SUSPENSION',
  Throttle = 'THROTTLE',
  Wheel = 'WHEEL',
}

export interface RequestQuote {
  __typename?: 'RequestQuote';
  dropoffAddress: Address;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  options: Array<TripQuoteOption>;
  pickupAddress: Address;
  /** @deprecated Use options instead */
  prices: Array<RequestQuotePrice>;
  route: Scalars['JSON'];
  stops: Array<Address>;
}

export interface RequestQuotePrice {
  __typename?: 'RequestQuotePrice';
  fleet: TaxiFleet;
  price: Scalars['Float'];
  waitingTime: Scalars['Float'];
}

export interface RetryOrgPaymentsInput {
  orgId: Scalars['Int'];
}

export interface RetryUnpaidInvoicesInput {
  invoiceIds: Array<Scalars['Int']>;
  paymentMethod: Scalars['String'];
}

/** Aggregated revenue data for a set of trips */
export interface RevenueByDay {
  __typename?: 'RevenueByDay';
  date: Scalars['String'];
  totalRevenue: Scalars['Int'];
}

/** Revenue data for the payment period */
export interface RevenueForPaymentPeriod {
  __typename?: 'RevenueForPaymentPeriod';
  endDate: Scalars['DateTime'];
  revenue: Scalars['Int'];
  startDate: Scalars['DateTime'];
}

/** Displays revenue statistics for a certain time */
export interface RevenueStatistics {
  __typename?: 'RevenueStatistics';
  currentPaymentPeriod: RevenueForPaymentPeriod;
  dayRevenue: Scalars['Int'];
  monthRevenue: Scalars['Int'];
  previousPaymentPeriod: RevenueForPaymentPeriod;
  revenueByDay: Array<RevenueByDay>;
}

export interface ReviewLicenseInput {
  action: LicenseAction;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  issuingDate?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
}

export interface RideHistoryInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  vehicleTypeGroups?: InputMaybe<Array<VehicleTypeGroupEnum>>;
}

export interface RideHistoryItem {
  __typename?: 'RideHistoryItem';
  amountDue: Scalars['Int'];
  createdAt: Scalars['String'];
  currency: CurrencyEnum;
  id: Scalars['Int'];
  invoiceId: Scalars['Int'];
  outstandingAmount: Scalars['Int'];
  refundedAmountDue: Scalars['Int'];
  taxiTripId?: Maybe<Scalars['String']>;
  tripEnd: Scalars['DateTime'];
  tripId?: Maybe<Scalars['Int']>;
  tripStart: Scalars['DateTime'];
  vehicleTypeGroup: VehicleTypeGroupEnum;
}

export interface RideHistoryResult {
  __typename?: 'RideHistoryResult';
  items: Array<RideHistoryItem>;
  pageInfo: CursorPageInfo;
}

export interface RiderTripState {
  __typename?: 'RiderTripState';
  info?: Maybe<Scalars['JSON']>;
  replacementTripId?: Maybe<Scalars['ID']>;
  state: TripState;
  vehicle?: Maybe<TaxiVehicle>;
  vehicleId?: Maybe<Scalars['ID']>;
}

export interface ScopeDefinition {
  __typename?: 'ScopeDefinition';
  description: ScopeDescription;
  roles: Array<UserRoleEnum>;
  scope: Scalars['String'];
}

export interface ScopeDescription {
  __typename?: 'ScopeDescription';
  actions: Array<Scalars['String']>;
  view: Array<Scalars['String']>;
}

export interface SetOrganizationCouponInput {
  discount: Scalars['Int'];
  fleetId: Scalars['Int'];
  organizationId: Scalars['Int'];
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Shift of a staff member */
export interface StaffShift {
  __typename?: 'StaffShift';
  batteriesReplaced?: Maybe<Scalars['Int']>;
  duration: Scalars['Float'];
  employee?: Maybe<User>;
  end?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  staffId?: Maybe<Scalars['Int']>;
  start: Scalars['Date'];
  user: User;
  userId: Scalars['Int'];
}

/** Aggregate of an employee's stats */
export interface StaffShiftAggregateByUser {
  __typename?: 'StaffShiftAggregateByUser';
  batteriesReplaced?: Maybe<Scalars['Int']>;
  duration: Scalars['Float'];
  employee?: Maybe<User>;
  staffId: Scalars['Int'];
}

/** Staff shifts with cursor pagination */
export interface StaffShifts {
  __typename?: 'StaffShifts';
  pageInfo: CursorPageInfo;
  staffShifts: Array<StaffShift>;
}

/** Initialize driving license verification */
export interface StartLicenseVerification {
  __typename?: 'StartLicenseVerification';
  /** The sdk token to be used to initialize the verification process. */
  sdkToken: Scalars['String'];
  /** The url to be used to initialize the web verification process. */
  webUrl: Scalars['String'];
  /** The Jumio workflowID. */
  workflowId: Scalars['String'];
}

export interface StatisticsAvailableGroup {
  __typename?: 'StatisticsAvailableGroup';
  missing: Scalars['Int'];
  outOfBounds: Scalars['Int'];
  total: Scalars['Int'];
}

export interface StatisticsInactiveGroup {
  __typename?: 'StatisticsInactiveGroup';
  decommissioned: Scalars['Int'];
  missing: Scalars['Int'];
  total: Scalars['Int'];
}

export interface StatisticsRemovedGroup {
  __typename?: 'StatisticsRemovedGroup';
  charge: Scalars['Int'];
  compliance: Scalars['Int'];
  maintenance: Scalars['Int'];
  rebalance: Scalars['Int'];
  total: Scalars['Int'];
}

export interface StatisticsTripGroup {
  __typename?: 'StatisticsTripGroup';
  active: Scalars['Int'];
  paused: Scalars['Int'];
  reserved: Scalars['Int'];
  total: Scalars['Int'];
}

export interface StatisticsUnavailableGroup {
  __typename?: 'StatisticsUnavailableGroup';
  batteryRemoved: Scalars['Int'];
  compliance: Scalars['Int'];
  lowBattery: Scalars['Int'];
  maintenance: Scalars['Int'];
  noConnection: Scalars['Int'];
  offHours: Scalars['Int'];
  total: Scalars['Int'];
}

export enum StepType {
  DriveToDropoff = 'DRIVE_TO_DROPOFF',
  DriveToPickup = 'DRIVE_TO_PICKUP',
  DriveToStop = 'DRIVE_TO_STOP',
  Dropoff = 'DROPOFF',
  Pickup = 'PICKUP',
  Stop = 'STOP',
}

/** Values that Stripe needs to open a payment sheet. */
export interface StripePaymentSetup {
  __typename?: 'StripePaymentSetup';
  currencyCode: CurrencyEnum;
  customerEphemeralKeySecret: Scalars['String'];
  customerId: Scalars['String'];
  merchantCountryCode: Scalars['String'];
  merchantDisplayName: Scalars['String'];
  publishableKey: Scalars['String'];
  setupIntentClientSecret: Scalars['String'];
}

export interface Subscription {
  __typename?: 'Subscription';
  pingPing: Scalars['String'];
  /** Plan updates as soon as they change */
  planUpdate: Array<VehicleStep>;
  /** Position updates */
  position: Position;
  /** Trip requests ad-hoc */
  tripRequest: TripRequest;
  tripUpdate: TaxiTrip;
}

export type SubscriptionPlanUpdateArgs = {
  vehicleId: Scalars['ID'];
};

export type SubscriptionPositionArgs = {
  riderId?: InputMaybe<Scalars['ID']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionTripRequestArgs = {
  vehicleId: Scalars['ID'];
};

export type SubscriptionTripUpdateArgs = {
  riderId?: InputMaybe<Scalars['ID']>;
  tripId?: InputMaybe<Scalars['ID']>;
};

export enum SupportType {
  Driver = 'driver',
  User = 'user',
}

export interface SurroundingVehicle {
  __typename?: 'SurroundingVehicle';
  coordinates: TaxiCoordinates;
  heading: Scalars['Float'];
  id: Scalars['String'];
}

export interface TaxiCoordinates {
  __typename?: 'TaxiCoordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

export interface TaxiFleet {
  __typename?: 'TaxiFleet';
  description?: Maybe<Scalars['String']>;
  fare: FleetFare;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  maximumPassengers: Scalars['Float'];
  name: Scalars['String'];
  pool: Scalars['Boolean'];
  taxiVehicles: Array<TaxiVehicle>;
}

export interface TaxiGeoJsonFeature extends TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONFeature';
  bbox?: Maybe<Array<Scalars['Float']>>;
  geometry?: Maybe<TaxiGeoJsonGeometryInterface>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSON']>;
  type: GeoJsonType;
}

export interface TaxiGeoJsonFeatureCollection extends TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONFeatureCollection';
  bbox?: Maybe<Array<Scalars['Float']>>;
  features: Array<TaxiGeoJsonFeature>;
  type: GeoJsonType;
}

export interface TaxiGeoJsonGeometry
  extends TaxiGeoJsonGeometryInterface,
    TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONGeometry';
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
}

export type TaxiGeoJsonGeometryInterface = {
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
};

export type TaxiGeoJsonInterface = {
  bbox?: Maybe<Array<Scalars['Float']>>;
  type: GeoJsonType;
};

export interface TaxiGeoJsonLineString
  extends TaxiGeoJsonGeometryInterface,
    TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONLineString';
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
}

export interface TaxiGeoJsonMultiLineString
  extends TaxiGeoJsonGeometryInterface,
    TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONMultiLineString';
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
}

export interface TaxiGeoJsonMultiPoint
  extends TaxiGeoJsonGeometryInterface,
    TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONMultiPoint';
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
}

export interface TaxiGeoJsonMultiPolygon
  extends TaxiGeoJsonGeometryInterface,
    TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONMultiPolygon';
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
}

export interface TaxiGeoJsonPoint
  extends TaxiGeoJsonGeometryInterface,
    TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONPoint';
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
}

export interface TaxiGeoJsonPolygon
  extends TaxiGeoJsonGeometryInterface,
    TaxiGeoJsonInterface {
  __typename?: 'TaxiGeoJSONPolygon';
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates?: Maybe<Array<Scalars['JSON']>>;
  type: GeoJsonType;
}

export enum TaxiPermitType {
  None = 'NONE',
  OperatingPermit = 'OPERATING_PERMIT',
  WorkPermit = 'WORK_PERMIT',
}

export interface TaxiTrip {
  __typename?: 'TaxiTrip';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  activeTripRequest?: Maybe<TripRequest>;
  createdAt: Scalars['DateTime'];
  departure?: Maybe<Scalars['DateTime']>;
  dropoffAddress?: Maybe<Address>;
  dropoffCoordinate?: Maybe<TaxiCoordinates>;
  endedAt?: Maybe<Scalars['DateTime']>;
  fareExtras: Scalars['JSON'];
  fareGratuity?: Maybe<Scalars['Float']>;
  fareWaitTime?: Maybe<Scalars['Float']>;
  fleet: TaxiFleet;
  id: Scalars['String'];
  invoice?: Maybe<Invoice>;
  lastProcessedAt?: Maybe<Scalars['DateTime']>;
  passengerCount: Scalars['Int'];
  pickupAddress?: Maybe<Address>;
  pickupCoordinate: TaxiCoordinates;
  quote: TripQuote;
  rating: Array<TripRating>;
  reason?: Maybe<Scalars['JSON']>;
  replacementTripId: Scalars['String'];
  rider: User;
  settledAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  state: TripState;
  stops: Array<Address>;
  stripePreAuthorizationId?: Maybe<Scalars['String']>;
  tripRequests: Array<TripRequest>;
  vehicle?: Maybe<TaxiVehicle>;
}

export interface TaxiTripInvoice {
  __typename?: 'TaxiTripInvoice';
  cancelled?: Maybe<Scalars['Boolean']>;
  distance: Scalars['Float'];
  driverName?: Maybe<Scalars['String']>;
  driverTotal: Scalars['Float'];
  duration: Scalars['Float'];
  elapsed: Scalars['Float'];
  fare: FleetFare;
  fleetName: Scalars['String'];
  gratuity: Scalars['Float'];
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  licensePlate: Scalars['String'];
  riderName?: Maybe<Scalars['String']>;
  riderTotal: Scalars['Float'];
  surge: Scalars['Float'];
  surgeKey?: Maybe<Scalars['String']>;
  trip: TaxiTrip;
  waitTime: Scalars['Float'];
}

export interface TaxiVehicle {
  __typename?: 'TaxiVehicle';
  approvedAt?: Maybe<Scalars['DateTime']>;
  driver: Driver;
  driverId: Scalars['String'];
  fleets: Array<VehicleFleet>;
  id: Scalars['ID'];
  info?: Maybe<VehicleInfo>;
  licensePlate: Scalars['String'];
  /** Whether vehicle is online and available for trip requests */
  online: Scalars['Boolean'];
  /** Number of passengers the vehicle can accept */
  passengerCapacity: Scalars['Float'];
  position: Position;
  state: VehicleState;
  steps: Array<VehicleStep>;
  trips: TripConnection;
}

export type TaxiVehicleStepsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  rider?: InputMaybe<Scalars['Boolean']>;
};

export type TaxiVehicleTripsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export interface TaxiVehicleFilterInput {
  bounds?: InputMaybe<Array<Scalars['Float']>>;
  online?: InputMaybe<Scalars['Boolean']>;
}

/** Hopp's terms of service */
export interface ToS {
  __typename?: 'ToS';
  id: Scalars['Int'];
}

export interface TranslatedAnnouncementInput {
  body: Scalars['String'];
  buttonText: Scalars['String'];
  locale: Scalars['String'];
  title: Scalars['String'];
}

/** Translation of an issue tag */
export interface Translation {
  key: Scalars['String'];
  translations: Array<TranslationLocale>;
}

/** Translation of an issue tag for one locale */
export interface TranslationLocale {
  locale: Scalars['String'];
  translation: Scalars['String'];
}

/** A trip */
export interface Trip {
  __typename?: 'Trip';
  actualCost?: Maybe<Scalars['Int']>;
  card?: Maybe<Card>;
  co2Saved: Scalars['Int'];
  cost?: Maybe<Scalars['Int']>;
  coupon?: Maybe<Coupon>;
  /** @deprecated Use invoice.creditInvoices instead. */
  creditInvoices: Array<Invoice>;
  currency: CurrencyEnum;
  durationCost?: Maybe<Scalars['Int']>;
  endLatitude?: Maybe<Scalars['Float']>;
  endLongitude?: Maybe<Scalars['Float']>;
  endReason?: Maybe<TripEndReasonEnum>;
  fleet: Fleet;
  footnotes?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  invoice?: Maybe<Invoice>;
  isFree: Scalars['Boolean'];
  /** @deprecated Use invoice.status instead */
  isPaid: Scalars['Boolean'];
  length?: Maybe<Scalars['Int']>;
  /** @deprecated Use invoice instead. */
  mainInvoice?: Maybe<Invoice>;
  masterTripId?: Maybe<Scalars['Int']>;
  minutes?: Maybe<Scalars['Int']>;
  oid: Scalars['String'];
  org?: Maybe<Organization>;
  path?: Maybe<GeoJsonLineString>;
  pausedCost?: Maybe<Scalars['Int']>;
  pausedMinutes?: Maybe<Scalars['Int']>;
  penaltyCost?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['String']>;
  preAuth?: Maybe<PreAuth>;
  reservedCost?: Maybe<Scalars['Int']>;
  reservedMinutes?: Maybe<Scalars['Int']>;
  startCost?: Maybe<Scalars['Int']>;
  startLatitude: Scalars['Float'];
  startLongitude: Scalars['Float'];
  threeDs?: Maybe<Payment3ds>;
  tripEnd?: Maybe<Scalars['Date']>;
  tripStart: Scalars['Date'];
  usedUserEndLocation: Scalars['Boolean'];
  user: User;
  vatCost?: Maybe<Scalars['Int']>;
  vehicle: Vehicle;
  vehicleCode?: Maybe<Scalars['String']>;
}

export interface TripConnection {
  __typename?: 'TripConnection';
  edges: Array<TripEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface TripEdge {
  __typename?: 'TripEdge';
  cursor: Scalars['String'];
  node: TaxiTrip;
}

export enum TripEndReasonEnum {
  Admin = 'ADMIN',
  Battery = 'BATTERY',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE',
  OutOfBounds = 'OUT_OF_BOUNDS',
  TimeLimit = 'TIME_LIMIT',
  User = 'USER',
  VehicleIssue = 'VEHICLE_ISSUE',
}

export interface TripInvoiceConnection {
  __typename?: 'TripInvoiceConnection';
  edges: Array<TripInvoiceEdge>;
  pageInfo: PageInfo;
  totalAmount: Scalars['Float'];
  totalCount: Scalars['Int'];
  totalCurrency: CurrencyEnum;
}

export interface TripInvoiceEdge {
  __typename?: 'TripInvoiceEdge';
  cursor: Scalars['String'];
  node: TaxiTripInvoice;
}

export interface TripQuote {
  __typename?: 'TripQuote';
  createdAt: Scalars['DateTime'];
  /** Distance in kilometers */
  distance?: Maybe<Scalars['Float']>;
  dropoffAddress: Address;
  dropoffCoordinates: TaxiCoordinates;
  /** Duration in minutes */
  duration?: Maybe<Scalars['Float']>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  options: Array<TripQuoteOption>;
  pickupAddress: Address;
  pickupCoordinates: TaxiCoordinates;
  /** @deprecated Use "options" instead. */
  prices: Scalars['JSON'];
  rider: User;
  route: Scalars['JSON'];
  stops: Array<Address>;
  surge: Scalars['Float'];
  surgeKey?: Maybe<Scalars['String']>;
  trip?: Maybe<TaxiTrip>;
}

export interface TripQuoteOption {
  __typename?: 'TripQuoteOption';
  currency: CurrencyEnum;
  discountItems: Scalars['JSON'];
  earning: Scalars['Float'];
  eta: Array<Scalars['Float']>;
  fleet: TaxiFleet;
  lineItems: Scalars['JSON'];
  /** @deprecated Use "total" instead. */
  price: Scalars['Float'];
  total: Scalars['Float'];
  waitingTime: Scalars['Float'];
}

export interface TripRating {
  __typename?: 'TripRating';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  raterUser: User;
  rating?: Maybe<Scalars['Int']>;
  receiverUser: User;
  tags: Array<Scalars['String']>;
  trip: TaxiTrip;
}

export interface TripRatingInput {
  rating?: InputMaybe<Scalars['Int']>;
  tripId: Scalars['ID'];
}

export interface TripRequest {
  __typename?: 'TripRequest';
  createdAt: Scalars['DateTime'];
  driver: Driver;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  respondedAt?: Maybe<Scalars['DateTime']>;
  state: TripRequestState;
  trip: TaxiTrip;
  vehicle: TaxiVehicle;
}

export enum TripRequestState {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum TripState {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  DrivingToDropoff = 'DRIVING_TO_DROPOFF',
  DrivingToPickup = 'DRIVING_TO_PICKUP',
  Rejected = 'REJECTED',
  Replaced = 'REPLACED',
  WaitingForAssignment = 'WAITING_FOR_ASSIGNMENT',
  WaitingForPickup = 'WAITING_FOR_PICKUP',
}

/** Displays trip statistics for a certain time */
export interface TripStatistics {
  __typename?: 'TripStatistics';
  dayTripCount: Scalars['Int'];
  monthTripCount: Scalars['Int'];
  tripsByDay: Array<TripsByDay>;
}

/** Aggregates for trips, grouped by a date */
export interface TripsByDate {
  __typename?: 'TripsByDate';
  averagePayment: Scalars['Int'];
  /** debitValueSum / totalCount, average trip worth is everything would be paid (free and unpaid trips included) */
  averageWorth: Scalars['Int'];
  co2SavedSum: Scalars['BigInt'];
  currency: CurrencyEnum;
  date: Scalars['String'];
  distanceSum: Scalars['BigInt'];
  freeCostSum: Scalars['BigInt'];
  freeCount: Scalars['Int'];
  invoiceAggregate?: Maybe<InvoiceAggregate>;
  pausedMinutesSum: Scalars['Int'];
  paymentAggregate?: Maybe<PaymentAggregate>;
  penaltyCount: Scalars['Int'];
  reservedMinutesSum: Scalars['Int'];
  reservedOnlyCount: Scalars['Int'];
  rideMinutesSum: Scalars['Int'];
  totalCount: Scalars['Int'];
  uniqueUsersCount: Scalars['Int'];
  uniqueVehiclesCount: Scalars['Int'];
  unit: PeriodUnit;
  vatRate: Scalars['Float'];
}

/** Aggregates for trips, grouped by dates */
export interface TripsByDates {
  __typename?: 'TripsByDates';
  dates: Array<TripsByDate>;
  pageInfo?: Maybe<CursorPageInfo>;
}

/** Aggregated trip data for a set of trips */
export interface TripsByDay {
  __typename?: 'TripsByDay';
  count: Scalars['Int'];
  date: Scalars['String'];
}

/** Tutorial for the user */
export interface Tutorial {
  __typename?: 'Tutorial';
  finishButtonText: Scalars['String'];
  id: Scalars['Int'];
  rules: Array<Scalars['String']>;
  rulesTitle: Scalars['String'];
  screens: Array<TutorialText>;
}

/** Title, description and id for a tutorial screen */
export interface TutorialText {
  __typename?: 'TutorialText';
  description: Scalars['String'];
  /** @deprecated Use image url instead */
  id: Scalars['String'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
}

/** An unpaid invoice for an organization. */
export interface UnpaidOrganizationInvoice {
  __typename?: 'UnpaidOrganizationInvoice';
  amountDue: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: CurrencyEnum;
  vat: Scalars['Int'];
}

export interface UpdateDriverInput {
  bankAccount?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nationalId?: InputMaybe<Scalars['String']>;
  permitNumber?: InputMaybe<Scalars['String']>;
  permitType?: InputMaybe<TaxiPermitType>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}

export interface UpdatePositionInput {
  accuracy?: InputMaybe<Scalars['Float']>;
  altitude?: InputMaybe<Scalars['Float']>;
  coordinates: CoordinatesInput;
  heading: Scalars['Float'];
  speed: Scalars['Float'];
}

export interface UpdateTripPaymentMethodInput {
  paymentMethodId: Scalars['String'];
}

export interface UpdateUserInput {
  name?: InputMaybe<Scalars['String']>;
  prefersLimitedSpeed?: InputMaybe<Scalars['Boolean']>;
}

export interface UpdateZonesInput {
  deletedZoneIds?: InputMaybe<Array<Scalars['Int']>>;
  fleetId: Scalars['Int'];
  updatedZones: Array<ZoneInput>;
}

/** A user */
export interface User {
  __typename?: 'User';
  activeTrips: Array<ActiveTrip>;
  appleEmail?: Maybe<Scalars['String']>;
  canManage: Scalars['Boolean'];
  cardLastDigits?: Maybe<Scalars['String']>;
  cards: Array<Card>;
  coupons: Array<Coupon>;
  createdAt?: Maybe<Scalars['Date']>;
  credits: Scalars['Int'];
  deletionRequestedAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  fleets: Array<Fleet>;
  freeRides: Scalars['Int'];
  googleEmail?: Maybe<Scalars['String']>;
  hasUnpaidTrips: Scalars['Boolean'];
  id: Scalars['Int'];
  /** @deprecated Use `scopes` instead, or `role`. */
  isAdmin: Scalars['Boolean'];
  /** @deprecated Use `scopes` instead, or `role`. */
  isCharger: Scalars['Boolean'];
  licenses?: Maybe<Array<License>>;
  locale: Scalars['String'];
  metrics: UserMetrics;
  name?: Maybe<Scalars['String']>;
  oid: Scalars['String'];
  organizations: Array<Organization>;
  phone?: Maybe<Scalars['String']>;
  prefersLimitedSpeed: Scalars['Boolean'];
  rating: Scalars['Float'];
  remainingLicenseRetries: Scalars['Int'];
  role?: Maybe<UserRoleEnum>;
  scopes: Array<Maybe<UserScopeEnum>>;
  selectedCardId?: Maybe<Scalars['Int']>;
  /** @deprecated Use signUpRegionV2 or signUpRegionId instead. */
  signUpRegion?: Maybe<Scalars['Int']>;
  signUpRegionId?: Maybe<Scalars['Int']>;
  signUpRegionV2?: Maybe<Region>;
  stripeId?: Maybe<Scalars['String']>;
}

/** A user */
export type UserFleetsArgs = {
  id?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Scalars['SequelizeJSON']>;
};

export enum UserLocationGeoJsonType {
  Point = 'Point',
}

export interface UserLocationInput {
  coordinates: Scalars['GeoJSONCoordinates'];
  type: UserLocationGeoJsonType;
}

export interface UserLogEntryInput {
  app: Scalars['String'];
  level: Scalars['String'];
  line: Scalars['String'];
  meta?: InputMaybe<Scalars['JSON']>;
  timestamp: Scalars['DateTime'];
}

export interface UserMetrics {
  __typename?: 'UserMetrics';
  /** CO2 saved by using hopp (grams) */
  co2Saved: Scalars['Int'];
  /** Distance traveled in trips (meters) */
  distanceTraveled: Scalars['Int'];
  tripCount: Scalars['Int'];
}

export enum UserRoleEnum {
  Admin = 'ADMIN',
  Charger = 'CHARGER',
  Maintenance = 'MAINTENANCE',
  Regulator = 'REGULATOR',
  ShiftManager = 'SHIFT_MANAGER',
  Support = 'SUPPORT',
  User = 'USER',
}

export enum UserScopeEnum {
  AccessMds = 'ACCESS_MDS',
  ApproveTaxiDriver = 'APPROVE_TAXI_DRIVER',
  ApproveTaxiVehicle = 'APPROVE_TAXI_VEHICLE',
  CreateCoupons = 'CREATE_COUPONS',
  CreateFleet = 'CREATE_FLEET',
  EditZones = 'EDIT_ZONES',
  ManageAllFleets = 'MANAGE_ALL_FLEETS',
  ManageAnnouncements = 'MANAGE_ANNOUNCEMENTS',
  ManageCards = 'MANAGE_CARDS',
  ManageCoupons = 'MANAGE_COUPONS',
  ManageFines = 'MANAGE_FINES',
  ManageFleetUsers = 'MANAGE_FLEET_USERS',
  ManageIssueTags = 'MANAGE_ISSUE_TAGS',
  ManageLicenses = 'MANAGE_LICENSES',
  ManageOrgs = 'MANAGE_ORGS',
  ManagePartInventory = 'MANAGE_PART_INVENTORY',
  ManageStaffShifts = 'MANAGE_STAFF_SHIFTS',
  ManageTaxi = 'MANAGE_TAXI',
  ManageTaxiVehicleFleets = 'MANAGE_TAXI_VEHICLE_FLEETS',
  ManageTripVehicle = 'MANAGE_TRIP_VEHICLE',
  ManageUser = 'MANAGE_USER',
  ManageUserTrip = 'MANAGE_USER_TRIP',
  ManageVehicle = 'MANAGE_VEHICLE',
  MoveVehicleBetweenFleets = 'MOVE_VEHICLE_BETWEEN_FLEETS',
  RefundUserTrip = 'REFUND_USER_TRIP',
  ScopeGiveCoupon = 'SCOPE_GIVE_COUPON',
  VehiclePhotos = 'VEHICLE_PHOTOS',
  ViewAllAccountingConfigs = 'VIEW_ALL_ACCOUNTING_CONFIGS',
  ViewAllFleets = 'VIEW_ALL_FLEETS',
  ViewAllOperators = 'VIEW_ALL_OPERATORS',
  ViewAllRegions = 'VIEW_ALL_REGIONS',
  ViewAllVehicles = 'VIEW_ALL_VEHICLES',
  ViewAuditUser = 'VIEW_AUDIT_USER',
  ViewDemand = 'VIEW_DEMAND',
  ViewDriverRevenue = 'VIEW_DRIVER_REVENUE',
  ViewHexagonMap = 'VIEW_HEXAGON_MAP',
  ViewInvoices = 'VIEW_INVOICES',
  ViewMarketOpportunities = 'VIEW_MARKET_OPPORTUNITIES',
  ViewRevenueStatistics = 'VIEW_REVENUE_STATISTICS',
  ViewStripeLinks = 'VIEW_STRIPE_LINKS',
  ViewTripStatistics = 'VIEW_TRIP_STATISTICS',
  ViewUserContactInfo = 'VIEW_USER_CONTACT_INFO',
  ViewVehicleStatistics = 'VIEW_VEHICLE_STATISTICS',
  ViewVehicleTechDetails = 'VIEW_VEHICLE_TECH_DETAILS',
}

/** A vehicle */
export interface Vehicle {
  __typename?: 'Vehicle';
  activeShift?: Maybe<StaffShift>;
  apn?: Maybe<Scalars['String']>;
  batteryLevel?: Maybe<Scalars['Float']>;
  bodyCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  controllerUrl?: Maybe<Scalars['String']>;
  fleet?: Maybe<Fleet>;
  id: Scalars['Int'];
  imei?: Maybe<Scalars['String']>;
  inParkingViolation: Scalars['Boolean'];
  isLocked?: Maybe<Scalars['Boolean']>;
  isOverturned?: Maybe<Scalars['Boolean']>;
  lastCharge?: Maybe<Scalars['String']>;
  lastPhoto?: Maybe<Scalars['String']>;
  lastPing?: Maybe<Scalars['DateTime']>;
  lastPosition?: Maybe<Scalars['DateTime']>;
  lastTrip?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  lockColor?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  message?: Maybe<VehicleMessage>;
  parkingViolationPhoto?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['Float']>;
  status: VehiclestatusEnumType;
  statusReason?: Maybe<VehiclestatusReasonEnumType>;
  telemetryConfig?: Maybe<Scalars['JSON']>;
  telemetryVersions?: Maybe<Scalars['JSON']>;
  temporaryId: Scalars['String'];
  vehicleType: VehicleType;
}

export interface VehicleAggregates {
  __typename?: 'VehicleAggregates';
  length: Scalars['Int'];
  revenueInFleet: Scalars['Int'];
  tripCount: Scalars['Int'];
}

/** Vehicle check */
export interface VehicleCheck {
  __typename?: 'VehicleCheck';
  expectedCheckDuration: Scalars['Int'];
  id: Scalars['Int'];
  intervalKm: Scalars['Int'];
  questions: Array<VehicleCheckQuestion>;
  vehicleType?: Maybe<VehicleType>;
}

/** Vehicle check question */
export interface VehicleCheckQuestion {
  __typename?: 'VehicleCheckQuestion';
  answers: Array<VehicleCheckQuestionAnswer>;
  id: Scalars['Int'];
  question: Scalars['String'];
}

/** Vehicle check question */
export interface VehicleCheckQuestionAnswer {
  __typename?: 'VehicleCheckQuestionAnswer';
  answer: Scalars['String'];
  id: Scalars['Int'];
  issueTag?: Maybe<IssueTag>;
}

export interface VehicleCluster {
  __typename?: 'VehicleCluster';
  /** The clusters center location. */
  center: Scalars['GeoJSONCoordinates'];
  fleet: Fleet;
  /** @deprecated Use fleet instead */
  fleetId: Scalars['Int'];
  leafNodes: Array<Maybe<Scalars['GeoJSONCoordinates']>>;
  types: Array<Maybe<VehicleType>>;
  /** Number of vehicles within the current cluster. */
  vehicleCount: Scalars['Int'];
  /** @deprecated Use types instead */
  vehicleTypes: Array<Maybe<Scalars['Int']>>;
}

export enum VehicleColor {
  Black = 'BLACK',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gold = 'GOLD',
  Green = 'GREEN',
  Grey = 'GREY',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Silver = 'SILVER',
  Tan = 'TAN',
  Unknown = 'UNKNOWN',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

export interface VehicleConnection {
  __typename?: 'VehicleConnection';
  edges: Array<VehicleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface VehicleEdge {
  __typename?: 'VehicleEdge';
  cursor: Scalars['String'];
  node: TaxiVehicle;
}

export enum VehicleEvent {
  Deregister = 'DEREGISTER',
  ProviderDropOff = 'PROVIDER_DROP_OFF',
  ProviderPickUp = 'PROVIDER_PICK_UP',
  Register = 'REGISTER',
  ServiceEnd = 'SERVICE_END',
  ServiceStart = 'SERVICE_START',
}

/** Vehicle events with cursor pagination */
export interface VehicleEventPage {
  __typename?: 'VehicleEventPage';
  events: Array<VehicleEvents>;
  pageInfo: CursorPageInfo;
}

export enum VehicleEventReason {
  Charge = 'CHARGE',
  Compliance = 'COMPLIANCE',
  Decommissioned = 'DECOMMISSIONED',
  LowBattery = 'LOW_BATTERY',
  Maintenance = 'MAINTENANCE',
  Missing = 'MISSING',
  OffHours = 'OFF_HOURS',
  Rebalance = 'REBALANCE',
}

/** Vehicle event */
export interface VehicleEvents {
  __typename?: 'VehicleEvents';
  afterState?: Maybe<EventafterStateEnumType>;
  batteryLevel?: Maybe<Scalars['Int']>;
  beforeState?: Maybe<EventbeforeStateEnumType>;
  id: Scalars['Int'];
  location?: Maybe<GeoJsonPoint>;
  pageInfo?: Maybe<CursorPageInfo>;
  reason?: Maybe<Scalars['String']>;
  time: Scalars['Date'];
  type: EventtypeEnumType;
  user?: Maybe<User>;
}

export enum VehicleFeatureEnum {
  BatteryLocking = 'BATTERY_LOCKING',
  HelmetLocking = 'HELMET_LOCKING',
  Locate = 'LOCATE',
  Locking = 'LOCKING',
  SetApn = 'SET_APN',
  SetWorkMode = 'SET_WORK_MODE',
  SpeedLimiting = 'SPEED_LIMITING',
  ToggleHazardLights = 'TOGGLE_HAZARD_LIGHTS',
  Update = 'UPDATE',
  UpdateAudio = 'UPDATE_AUDIO',
}

/** Filter for vehicles */
export interface VehicleFilter {
  batteryRange?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inactiveHours?: InputMaybe<Scalars['Int']>;
  issueType?: InputMaybe<Scalars['String']>;
  issueTypeId?: InputMaybe<Scalars['Int']>;
  /** List of vehicle type groups you want to see, filters are joined with an or condition */
  vehicleTypeGroups?: InputMaybe<Array<VehicleTypeGroupEnum>>;
}

export interface VehicleFleet {
  __typename?: 'VehicleFleet';
  active: Scalars['Boolean'];
  approvedAt: Scalars['DateTime'];
  fleet: TaxiFleet;
  id: Scalars['ID'];
  vehicle: TaxiVehicle;
}

export interface VehicleInfo {
  __typename?: 'VehicleInfo';
  color: VehicleColor;
  id: Scalars['ID'];
  make: Scalars['String'];
  model: Scalars['String'];
  nextInspection: Scalars['DateTime'];
  owner?: Maybe<Driver>;
  ownerDriverId?: Maybe<Scalars['ID']>;
  registeredPlate: Scalars['String'];
  registry?: Maybe<Scalars['JSON']>;
  status: VehicleStatus;
  vin: Scalars['String'];
  weight: Scalars['Float'];
  year: Scalars['Float'];
}

export enum VehicleIotMode {
  Normal = 'NORMAL',
  Test = 'TEST',
}

export interface VehicleMessage {
  __typename?: 'VehicleMessage';
  body: Scalars['String'];
  title: Scalars['String'];
}

export enum VehicleState {
  Assigned = 'ASSIGNED',
  Unassigned = 'UNASSIGNED',
}

/** Vehicle statistics to show in our admin system */
export interface VehicleStatistics {
  __typename?: 'VehicleStatistics';
  available: Scalars['Int'];
  availableGroup: StatisticsAvailableGroup;
  avgBatteryAvailable: Scalars['Float'];
  countOfInactiveBikes: Scalars['Int'];
  decommissioned: Scalars['Int'];
  inactiveGroup: StatisticsInactiveGroup;
  maintenance: Scalars['Int'];
  missing: Scalars['Int'];
  outOfBounds: Scalars['Int'];
  removedGroup: StatisticsRemovedGroup;
  trip: Scalars['Int'];
  tripGroup: StatisticsTripGroup;
  unavailableGroup: StatisticsUnavailableGroup;
}

export enum VehicleStatus {
  Banned = 'BANNED',
  Customs = 'CUSTOMS',
  Delisted = 'DELISTED',
  Expired = 'EXPIRED',
  NoPlates = 'NO_PLATES',
  Ok = 'OK',
  Prelisted = 'PRELISTED',
  Unknown = 'UNKNOWN',
}

export interface VehicleStep {
  __typename?: 'VehicleStep';
  address?: Maybe<Address>;
  completedAt?: Maybe<Scalars['DateTime']>;
  coordinates: TaxiCoordinates;
  estimatedCompleteAt: Scalars['DateTime'];
  id: Scalars['ID'];
  passengerCount: Scalars['Int'];
  route?: Maybe<Scalars['JSON']>;
  timestamp: Scalars['DateTime'];
  trip: TaxiTrip;
  tripId: Scalars['ID'];
  type: StepType;
  vehicle: TaxiVehicle;
}

export interface VehicleTelemetryPoint {
  __typename?: 'VehicleTelemetryPoint';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  speed: Scalars['Int'];
  timestamp: Scalars['String'];
}

/** A type of vehicle */
export interface VehicleType {
  __typename?: 'VehicleType';
  allowFreeRide: Scalars['Boolean'];
  allowGroupRide: Scalars['Boolean'];
  features: Array<VehicleFeatureEnum>;
  group: VehicleTypeGroupRentableEnum;
  icon: MediaAsset;
  id: Scalars['Int'];
  image: MediaAsset;
  requiresLicense: Scalars['Boolean'];
  type: Scalars['String'];
  vehicleName: Scalars['String'];
}

export enum VehicleTypeGroupEnum {
  Bike = 'BIKE',
  Car = 'CAR',
  Scooter = 'SCOOTER',
  Taxi = 'TAXI',
}

export enum VehicleTypeGroupRentableEnum {
  Bike = 'BIKE',
  Car = 'CAR',
  Scooter = 'SCOOTER',
}

export interface VehiclesOnStreet {
  __typename?: 'VehiclesOnStreet';
  available: Scalars['Int'];
  outOfBattery: Scalars['Int'];
  total: Scalars['Int'];
  unavailable: Scalars['Int'];
}

/** A watcher event for a vehicle */
export interface WatcherEvent {
  __typename?: 'WatcherEvent';
  createdAt: Scalars['Date'];
  eventId?: Maybe<Scalars['Int']>;
  fleet: Fleet;
  fleetId: Scalars['Int'];
  id: Scalars['Int'];
  metadata: Scalars['SequelizeJSON'];
  notifications: Array<WatcherEventNotification>;
  type: WatcherEventTypeEnum;
  vehicle: Vehicle;
  vehicleId: Scalars['Int'];
}

export interface WatcherEventDocument {
  __typename?: 'WatcherEventDocument';
  appliesTo: Array<VehicleTypeGroupEnum>;
  description: Scalars['String'];
  name: Scalars['String'];
  trigger: Scalars['String'];
  type: WatcherEventTypeEnum;
  whatHappens: Scalars['String'];
}

/** A notification sent to a user from a watcher event */
export interface WatcherEventNotification {
  __typename?: 'WatcherEventNotification';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  message: Scalars['String'];
  user?: Maybe<User>;
  userId: Scalars['Int'];
}

export enum WatcherEventTypeEnum {
  AbnormalBatteryRemoval = 'ABNORMAL_BATTERY_REMOVAL',
  AbnormalMovement = 'ABNORMAL_MOVEMENT',
  BatteryNotLocked = 'BATTERY_NOT_LOCKED',
  DisconnectedVehicle = 'DISCONNECTED_VEHICLE',
  IotUnplugged = 'IOT_UNPLUGGED',
  MissingVehicleFound = 'MISSING_VEHICLE_FOUND',
  PotentiallyFaultyVehicle = 'POTENTIALLY_FAULTY_VEHICLE',
  PotentiallyHardToLocate = 'POTENTIALLY_HARD_TO_LOCATE',
  ReconnectedVehicle = 'RECONNECTED_VEHICLE',
  ReportedParkingViolation = 'REPORTED_PARKING_VIOLATION',
  ReportedVehicleIssue = 'REPORTED_VEHICLE_ISSUE',
  TripEndedOutOfBounds = 'TRIP_ENDED_OUT_OF_BOUNDS',
  UnexpectedCharging = 'UNEXPECTED_CHARGING',
  VehicleCrashed = 'VEHICLE_CRASHED',
}

/** Geometries that affect scooter rentals in some way. */
export interface Zone {
  __typename?: 'Zone';
  applyToFleets: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  discountPercent?: Maybe<Scalars['Float']>;
  geometry: GeoJsonGeometryInterface;
  icon?: Maybe<MediaAsset>;
  id: Scalars['Int'];
  /** @deprecated deprecated in version 7.1.0 (2023-04-13), use imageUrl instead */
  image?: Maybe<MediaAsset>;
  imageUrl?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  rawDescription?: Maybe<Scalars['SequelizeJSON']>;
  rawTitle?: Maybe<Scalars['SequelizeJSON']>;
  speedLimit?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type: ZoneTypeEnum;
  vehicleTypeGroups: Scalars['SequelizeJSON'];
}

export interface ZoneGeoJsonInput {
  coordinates: Scalars['GeoJSONCoordinates'];
  type: ZoneGeoJsonType;
}

export enum ZoneGeoJsonType {
  Point = 'Point',
  Polygon = 'Polygon',
}

export interface ZoneInput {
  description?: InputMaybe<Scalars['SequelizeJSON']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  geometry: ZoneGeoJsonInput;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  internalName: Scalars['String'];
  speedLimit?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['SequelizeJSON']>;
  type: ZoneTypeEnum;
  vehicleTypeGroups?: InputMaybe<Array<VehicleTypeGroupEnum>>;
}

export enum ZoneTypeEnum {
  Discount = 'DISCOUNT',
  HotSpot = 'HOT_SPOT',
  NoGo = 'NO_GO',
  NoPark = 'NO_PARK',
  OperatingZone = 'OPERATING_ZONE',
  OutOfBounds = 'OUT_OF_BOUNDS',
  ParkingStation = 'PARKING_STATION',
  Slow = 'SLOW',
}

export enum CardproviderEnumType {
  ApplePay = 'APPLE_PAY',
  Stripe = 'STRIPE',
  User = 'USER',
}

export enum CardstatusEnumType {
  Available = 'AVAILABLE',
  Deleted = 'DELETED',
}

/** coordinates and demandcount */
export interface Coordinates {
  __typename?: 'coordinates';
  bikeInCircle: Scalars['Int'];
  coordinates: Array<Array<Array<Scalars['Float']>>>;
  demandCount: Scalars['Int'];
}

export enum EventafterStateEnumType {
  Available = 'AVAILABLE',
  Elsewhere = 'ELSEWHERE',
  Inactive = 'INACTIVE',
  Paused = 'PAUSED',
  Removed = 'REMOVED',
  Reserved = 'RESERVED',
  Trip = 'TRIP',
  Unavailable = 'UNAVAILABLE',
}

export enum EventbeforeStateEnumType {
  Available = 'AVAILABLE',
  Elsewhere = 'ELSEWHERE',
  Inactive = 'INACTIVE',
  Paused = 'PAUSED',
  Removed = 'REMOVED',
  Reserved = 'RESERVED',
  Trip = 'TRIP',
  Unavailable = 'UNAVAILABLE',
}

export enum EventtypeEnumType {
  Alarm = 'ALARM',
  CancelReservation = 'CANCEL_RESERVATION',
  ChangeApn = 'CHANGE_APN',
  ChangeApnFinished = 'CHANGE_APN_FINISHED',
  CityPickUp = 'CITY_PICK_UP',
  ControllerEvent = 'CONTROLLER_EVENT',
  Deregister = 'DEREGISTER',
  ProviderDropOff = 'PROVIDER_DROP_OFF',
  ProviderPickUp = 'PROVIDER_PICK_UP',
  Register = 'REGISTER',
  Reserve = 'RESERVE',
  ServiceEnd = 'SERVICE_END',
  ServiceStart = 'SERVICE_START',
  TripEnd = 'TRIP_END',
  TripEnter = 'TRIP_ENTER',
  TripLeave = 'TRIP_LEAVE',
  TripPause = 'TRIP_PAUSE',
  TripResume = 'TRIP_RESUME',
  TripStart = 'TRIP_START',
}

export enum Fleet_UserroleEnumType {
  Admin = 'ADMIN',
  Charger = 'CHARGER',
  Maintenance = 'MAINTENANCE',
  Regulator = 'REGULATOR',
  ShiftManager = 'SHIFT_MANAGER',
  Support = 'SUPPORT',
  User = 'USER',
}

export enum FleetpreAuthWhenEnumType {
  Always = 'ALWAYS',
  FirstTripOnly = 'FIRST_TRIP_ONLY',
  RareUseOrGroupRide = 'RARE_USE_OR_GROUP_RIDE',
}

export enum InvoicecreatedByUserAffiliationEnumType {
  CustomerSupport = 'CUSTOMER_SUPPORT',
  Executive = 'EXECUTIVE',
  FranchiseManagement = 'FRANCHISE_MANAGEMENT',
  Operator = 'OPERATOR',
  TaxiManagement = 'TAXI_MANAGEMENT',
}

export enum InvoicetypeEnumType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export enum Market_CityregulationStatusScootersEnumType {
  Allowed = 'ALLOWED',
  Na = 'NA',
  NotAllowed = 'NOT_ALLOWED',
  PartiallyAllowed = 'PARTIALLY_ALLOWED',
}

export enum Market_CityregulationStatusTaxisEnumType {
  Allowed = 'ALLOWED',
  Na = 'NA',
  NotAllowed = 'NOT_ALLOWED',
  PartiallyAllowed = 'PARTIALLY_ALLOWED',
}

export enum Parking_ViolationvehicleSelectedByEnumType {
  Automatic = 'AUTOMATIC',
  User = 'USER',
}

export enum ParttypeEnumType {
  Iot = 'IOT',
}

export enum PaymentpaymentTypeEnumType {
  Refund = 'REFUND',
  Sale = 'SALE',
}

export enum RegionstatusEnumType {
  Announced = 'ANNOUNCED',
  Closed = 'CLOSED',
  Operating = 'OPERATING',
  Planned = 'PLANNED',
}

export enum VehiclestatusEnumType {
  Available = 'AVAILABLE',
  Elsewhere = 'ELSEWHERE',
  Inactive = 'INACTIVE',
  Paused = 'PAUSED',
  Removed = 'REMOVED',
  Reserved = 'RESERVED',
  Trip = 'TRIP',
  Unavailable = 'UNAVAILABLE',
}

export enum VehiclestatusReasonEnumType {
  BatteryLocked = 'BATTERY_LOCKED',
  BatteryRemoved = 'BATTERY_REMOVED',
  BatteryReplaced = 'BATTERY_REPLACED',
  BatteryUnlocked = 'BATTERY_UNLOCKED',
  Charge = 'CHARGE',
  CheckDone = 'CHECK_DONE',
  Compliance = 'COMPLIANCE',
  Decommissioned = 'DECOMMISSIONED',
  DoingCheck = 'DOING_CHECK',
  LowBattery = 'LOW_BATTERY',
  Maintenance = 'MAINTENANCE',
  Missing = 'MISSING',
  NoConnection = 'NO_CONNECTION',
  OffHours = 'OFF_HOURS',
  OutOfBounds = 'OUT_OF_BOUNDS',
  Rebalance = 'REBALANCE',
  SimSwapping = 'SIM_SWAPPING',
  Updating = 'UPDATING',
}

export type GetFullAnnouncementQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetFullAnnouncementQuery = {
  __typename?: 'Query';
  announcement?: {
    __typename?: 'Announcement';
    id: number;
    icon?: string | null;
    showFrom: any;
    showTo: any;
    vehicleTypeGroups?: Array<VehicleTypeGroupEnum> | null;
    title?: any | null;
    body?: any | null;
    buttonText?: any | null;
  } | null;
};

export type AddAnnouncementMutationVariables = Exact<{
  fleetId: Scalars['Int'];
  input: AnnouncementInput;
}>;

export type AddAnnouncementMutation = {
  __typename?: 'Mutation';
  addAnnouncement?: boolean | null;
};

export type UpdateAnnouncementMutationVariables = Exact<{
  id: Scalars['Int'];
  input: AnnouncementInput;
}>;

export type UpdateAnnouncementMutation = {
  __typename?: 'Mutation';
  updateAnnouncement?: boolean | null;
};

export type AllAnnouncementsQueryVariables = Exact<{
  regionId: Scalars['Int'];
}>;

export type AllAnnouncementsQuery = {
  __typename?: 'Query';
  announcements: Array<{
    __typename?: 'Announcement';
    id: number;
    title: string;
    body: string;
    icon?: string | null;
    showFrom: any;
    showTo: any;
    locales: Array<string | null>;
    vehicleTypeGroups?: Array<VehicleTypeGroupEnum> | null;
  }>;
};

export type RemoveAnnouncementsMutationVariables = Exact<{
  announcements: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type RemoveAnnouncementsMutation = {
  __typename?: 'Mutation';
  removeAnnouncements?: boolean | null;
};

export type EnabledFeaturesQueryVariables = Exact<{
  regionId?: InputMaybe<Scalars['Int']>;
}>;

export type EnabledFeaturesQuery = {
  __typename?: 'Query';
  enabledFeatures: Array<
    | { __typename?: 'FeatureFlag'; name: string }
    | { __typename?: 'FeatureNumberSetting'; name: string }
    | { __typename?: 'FeatureTextSetting'; name: string }
  >;
};

export type AllIssuesQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  openOnly?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type AllIssuesQuery = {
  __typename?: 'Query';
  allIssues: {
    __typename?: 'AllIssues';
    issues: Array<{
      __typename?: 'Issue';
      id: number;
      comment?: string | null;
      createdAt: any;
      vehicleId: number;
      vehicle?: { __typename?: 'Vehicle'; code?: string | null } | null;
      tags: Array<{ __typename?: 'IssueTag'; name?: string | null }>;
      issueType: { __typename?: 'IssueType'; key: string };
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      cursor?: string | null;
      remaining?: number | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type IssueTagsQueryVariables = Exact<{
  vehicleTypeId?: InputMaybe<Scalars['Int']>;
  issueTypeId?: InputMaybe<Scalars['Int']>;
}>;

export type IssueTagsQuery = {
  __typename?: 'Query';
  issueTags: Array<{
    __typename?: 'IssueTag';
    id: number;
    name?: string | null;
    tag: string;
    vehicleTypes: Array<{
      __typename?: 'VehicleType';
      id: number;
      type: string;
      vehicleName: string;
    }>;
    issueType: { __typename?: 'IssueType'; id: number; name: string };
  }>;
};

export type AddIssueTagMutationVariables = Exact<{
  input: InputIssueTag;
}>;

export type AddIssueTagMutation = {
  __typename?: 'Mutation';
  addIssueTag: { __typename?: 'IssueTag'; id: number; tag: string };
};

export type RemoveIssueTagsMutationVariables = Exact<{
  issueTagIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type RemoveIssueTagsMutation = {
  __typename?: 'Mutation';
  removeIssueTags?: boolean | null;
};

export type IssueTypesQueryVariables = Exact<{ [key: string]: never }>;

export type IssueTypesQuery = {
  __typename?: 'Query';
  issueTypes: Array<{
    __typename?: 'IssueType';
    id: number;
    key: string;
    name: string;
  }>;
};

export type AllPartsQueryVariables = Exact<{
  vehicleTypeId?: InputMaybe<Scalars['Int']>;
}>;

export type AllPartsQuery = {
  __typename?: 'Query';
  allParts: Array<{
    __typename?: 'Part';
    id: number;
    name: string;
    price?: number | null;
    manufacturerId?: string | null;
    currency?: CurrencyEnum | null;
    vehicleTypes: Array<{
      __typename?: 'VehicleType';
      id: number;
      type: string;
      vehicleName: string;
    }>;
  }>;
};

export type PartQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type PartQuery = {
  __typename?: 'Query';
  part?: {
    __typename?: 'Part';
    id: number;
    name: string;
    price?: number | null;
    manufacturerId?: string | null;
    currency?: CurrencyEnum | null;
    vehicleTypes: Array<{ __typename?: 'VehicleType'; id: number }>;
  } | null;
};

export type AddPartMutationVariables = Exact<{
  part: InputPart;
}>;

export type AddPartMutation = {
  __typename?: 'Mutation';
  addPart: { __typename?: 'Part'; id: number };
};

export type UpdatePartMutationVariables = Exact<{
  part: InputPart;
}>;

export type UpdatePartMutation = {
  __typename?: 'Mutation';
  updatePart: { __typename?: 'Part'; id: number };
};

export type GetPartInventoryQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
}>;

export type GetPartInventoryQuery = {
  __typename?: 'Query';
  getPartInventory?: {
    __typename?: 'PartInventory';
    id: number;
    partId: number;
    minAmount?: number | null;
    currentAmount?: number | null;
    recommendedAmount?: number | null;
    part: {
      __typename?: 'Part';
      name: string;
      price?: number | null;
      manufacturerId?: string | null;
    };
  } | null;
};

export type AddPartInventoryMutationVariables = Exact<{
  fleetId: Scalars['Int'];
  partInventory: InputPartInventory;
}>;

export type AddPartInventoryMutation = {
  __typename?: 'Mutation';
  addPartInventory?: boolean | null;
};

export type UpdatePartInventoryMutationVariables = Exact<{
  fleetId: Scalars['Int'];
  partInventory: InputPartInventory;
}>;

export type UpdatePartInventoryMutation = {
  __typename?: 'Mutation';
  updatePartInventory?: boolean | null;
};

export type AllPartInventoryQueryVariables = Exact<{
  fleetId: Scalars['Int'];
}>;

export type AllPartInventoryQuery = {
  __typename?: 'Query';
  allPartInventory: Array<{
    __typename?: 'PartInventory';
    id: number;
    partId: number;
    minAmount?: number | null;
    currentAmount?: number | null;
    recommendedAmount?: number | null;
    part: {
      __typename?: 'Part';
      name: string;
      price?: number | null;
      manufacturerId?: string | null;
    };
  }>;
};

export type RemovePartInventoryMutationVariables = Exact<{
  fleetId: Scalars['Int'];
  partInventoryIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type RemovePartInventoryMutation = {
  __typename?: 'Mutation';
  removePartInventory?: boolean | null;
};

export type AllStaffShiftsQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;

export type AllStaffShiftsQuery = {
  __typename?: 'Query';
  allStaffShifts: {
    __typename?: 'StaffShifts';
    staffShifts: Array<{
      __typename?: 'StaffShift';
      id: number;
      start: any;
      end?: any | null;
      duration: number;
      staffId?: number | null;
      batteriesReplaced?: number | null;
      employee?: {
        __typename?: 'User';
        id: number;
        phone?: string | null;
        name?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      cursor?: string | null;
      remaining?: number | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type UpdateStaffShiftMutationVariables = Exact<{
  staffShift: InputStaffShift;
  fleetId: Scalars['Int'];
}>;

export type UpdateStaffShiftMutation = {
  __typename?: 'Mutation';
  updateStaffShift: boolean;
};

export type GetStaffShiftQueryVariables = Exact<{
  staffShiftId: Scalars['Int'];
  fleetId: Scalars['Int'];
}>;

export type GetStaffShiftQuery = {
  __typename?: 'Query';
  getStaffShift: {
    __typename?: 'StaffShift';
    start: any;
    end?: any | null;
    staffId?: number | null;
  };
};

export type RemoveStaffShiftsMutationVariables = Exact<{
  staffShiftIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
  fleetId: Scalars['Int'];
}>;

export type RemoveStaffShiftsMutation = {
  __typename?: 'Mutation';
  removeStaffShifts?: boolean | null;
};

export type AddStaffShiftMutationVariables = Exact<{
  staffShift: InputStaffShift;
  fleetId: Scalars['Int'];
}>;

export type AddStaffShiftMutation = {
  __typename?: 'Mutation';
  addStaffShift: boolean;
};

export type GetAllStaffQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  roles?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAllStaffQuery = {
  __typename?: 'Query';
  allFleetUsers: Array<{
    __typename?: 'FleetUser';
    user: {
      __typename?: 'User';
      id: number;
      name?: string | null;
      phone?: string | null;
    };
  }>;
};

export type TripsByDatesQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  unit: PeriodUnitEnum;
}>;

export type TripsByDatesQuery = {
  __typename?: 'Query';
  tripsByDates: {
    __typename?: 'TripsByDates';
    dates: Array<{
      __typename?: 'TripsByDate';
      date: string;
      vatRate: number;
      currency: CurrencyEnum;
      totalCount: number;
      freeCount: number;
      penaltyCount: number;
      reservedOnlyCount: number;
      freeCostSum: string;
      reservedMinutesSum: number;
      rideMinutesSum: number;
      pausedMinutesSum: number;
      distanceSum: string;
      co2SavedSum: string;
      uniqueUsersCount: number;
      uniqueVehiclesCount: number;
      averagePayment: number;
      averageWorth: number;
      invoiceAggregate?: {
        __typename?: 'InvoiceAggregate';
        debitValueSum: string;
        creditValueSum: string;
        debitVat: string;
        creditVat: string;
        unpaidUserDebitValueSum: string;
        unpaidUserCreditValueSum: string;
        unpaidOrgDebitValueSum: string;
        unpaidOrgCreditValueSum: string;
      } | null;
      paymentAggregate?: {
        __typename?: 'PaymentAggregate';
        revenueSum: string;
        refundSum: string;
        paidDebtRevenueSum: string;
      } | null;
    }>;
    pageInfo?: {
      __typename?: 'CursorPageInfo';
      cursor?: string | null;
      remaining?: number | null;
      hasNextPage?: boolean | null;
    } | null;
  };
};

export type AllVehicleTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllVehicleTypesQuery = {
  __typename?: 'Query';
  vehicleTypes: Array<{
    __typename?: 'VehicleType';
    id: number;
    vehicleName: string;
    type: string;
  }>;
};

export type GetVehicleEventsQueryVariables = Exact<{
  vehicleId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type GetVehicleEventsQuery = {
  __typename?: 'Query';
  vehicleEvents: {
    __typename?: 'VehicleEventPage';
    events: Array<{
      __typename?: 'VehicleEvents';
      id: number;
      time: any;
      beforeState?: EventbeforeStateEnumType | null;
      afterState?: EventafterStateEnumType | null;
      reason?: string | null;
      batteryLevel?: number | null;
      location?: {
        __typename?: 'GeoJSONPoint';
        type: GeoJsonType;
        coordinates?: any | null;
      } | null;
      user?: {
        __typename?: 'User';
        id: number;
        name?: string | null;
        oid: string;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      cursor?: string | null;
      remaining?: number | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type OperatorsQueryVariables = Exact<{ [key: string]: never }>;

export type OperatorsQuery = {
  __typename?: 'Query';
  operators: Array<{
    __typename?: 'Operator';
    id: number;
    name: string;
    email: string;
    phone?: string | null;
  }>;
};

export type AllRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type AllRegionsQuery = {
  __typename?: 'Query';
  allRegions: Array<{
    __typename?: 'Region';
    id: number;
    name: string;
    latitude: number;
    longitude: number;
    timezone: string;
    requiredLanguages: Array<string>;
    countryCode: string;
    slug?: string | null;
    status: RegionstatusEnumType;
  }>;
};

export type AccountingConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountingConfigsQuery = {
  __typename?: 'Query';
  accountingConfigs: Array<{
    __typename?: 'AccountingConfig';
    id: number;
    name: string;
  }>;
};

export type AddFleetMutationVariables = Exact<{
  input: AddFleetInput;
}>;

export type AddFleetMutation = {
  __typename?: 'Mutation';
  addFleet: { __typename?: 'Fleet'; id: number; name: string };
};

export type EditFleetMutationVariables = Exact<{
  input: EditFleetInput;
}>;

export type EditFleetMutation = {
  __typename?: 'Mutation';
  editFleet: { __typename?: 'Fleet'; id: number; name: string };
};

export type GetFleetQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetFleetQuery = {
  __typename?: 'Query';
  fleet?: {
    __typename?: 'Fleet';
    id: number;
    name: string;
    startCost: number;
    creditsPerMin: number;
    pausedCost: number;
    penaltyCost: number;
    reservedCost: number;
    vatRate: number;
    preAuthAmount?: number | null;
    preAuthWhen: FleetpreAuthWhenEnumType;
    maxReservedMinutes?: number | null;
    maxPausedMinutes?: number | null;
    maxInactiveMinutes?: number | null;
    currency: CurrencyEnum;
    features: Array<FleetFeatureEnum>;
    isPublic: boolean;
    isStaffFleet: boolean;
    availableApns: Array<string>;
    accountingConfigId?: number | null;
  } | null;
};

export type FineTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FineTypesQuery = {
  __typename?: 'Query';
  fineTypes: Array<{ __typename?: 'FineType'; id: number; name: string }>;
};

export type GetInvoiceReasonTagsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInvoiceReasonTagsQuery = {
  __typename?: 'Query';
  invoiceReasonTags: Array<{
    __typename?: 'InvoiceReasonTag';
    id: number;
    name: string;
    isDescriptionRequired: boolean;
  }>;
};

export type InvoiceFragment = {
  __typename?: 'Invoice';
  id: number;
  oid: string;
  reasonDescription?: string | null;
  currency: CurrencyEnum;
  amountDue: number;
  vat: number;
  vatRate: number;
  outstandingAmount?: number | null;
  status: InvoiceStatus;
  createdByUser?: {
    __typename?: 'User';
    name?: string | null;
    oid: string;
  } | null;
  invoiceReasonTag?: { __typename?: 'InvoiceReasonTag'; name: string } | null;
  invoiceItems: Array<{
    __typename?: 'InvoiceItem';
    type: InvoiceItemType;
    label: string;
    units: number;
    cost: number;
    total: number;
  }>;
  discountItems: Array<{
    __typename?: 'DiscountItem';
    type: InvoiceDiscountType;
    label: string;
    total: number;
  }>;
  payments: Array<{
    __typename?: 'Payment';
    id: number;
    cardType?: string | null;
    amount: number;
    createdAt: any;
    card?: { __typename?: 'Card'; lastDigits: string } | null;
  }>;
  issuedFines?: Array<{
    __typename?: 'Fine';
    id: number;
    userId: number;
    publicDescription?: string | null;
    fineType: { __typename?: 'FineType'; name: string };
    invoice: {
      __typename?: 'Invoice';
      id: number;
      status: InvoiceStatus;
      amountDue: number;
      outstandingAmount?: number | null;
      currency: CurrencyEnum;
    };
  }>;
  couponPayments: Array<{
    __typename?: 'CouponPayment';
    couponId: number;
    invoiceAmountPaid: number;
    createdAt: any;
  }>;
  creditInvoices: Array<{
    __typename?: 'Invoice';
    id: number;
    reasonDescription?: string | null;
    amountDue: number;
    createdAt: any;
    createdByUser?: {
      __typename?: 'User';
      name?: string | null;
      oid: string;
    } | null;
    invoiceReasonTag?: {
      __typename?: 'InvoiceReasonTag';
      name: string;
      id: number;
      isDescriptionRequired: boolean;
    } | null;
    invoiceItems: Array<{
      __typename?: 'InvoiceItem';
      referenceItemIndex?: number | null;
      label: string;
      type: InvoiceItemType;
      units: number;
      cost: number;
      total: number;
    }>;
    payments: Array<{
      __typename?: 'Payment';
      id: number;
      amount: number;
      card?: { __typename?: 'Card'; lastDigits: string } | null;
    }>;
    couponPayments: Array<{
      __typename?: 'CouponPayment';
      couponId: number;
      invoiceAmountPaid: number;
    }>;
  }>;
};

export type IssueFineMutationVariables = Exact<{
  input: IssueFineInput;
}>;

export type IssueFineMutation = {
  __typename?: 'Mutation';
  issueFine: { __typename?: 'Fine'; id: number };
};

export type RefundInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
  items?: InputMaybe<Array<InvoiceItemInput> | InvoiceItemInput>;
  invoiceReasonTagId?: InputMaybe<Scalars['Int']>;
  invoiceReasonDescription?: InputMaybe<Scalars['String']>;
  includeInvoiceIssuedFines?: InputMaybe<Scalars['Boolean']>;
}>;

export type RefundInvoiceMutation = {
  __typename?: 'Mutation';
  refundInvoice: {
    __typename?: 'Invoice';
    id: number;
    oid: string;
    reasonDescription?: string | null;
    currency: CurrencyEnum;
    amountDue: number;
    vat: number;
    vatRate: number;
    outstandingAmount?: number | null;
    status: InvoiceStatus;
    createdByUser?: {
      __typename?: 'User';
      name?: string | null;
      oid: string;
    } | null;
    invoiceReasonTag?: { __typename?: 'InvoiceReasonTag'; name: string } | null;
    invoiceItems: Array<{
      __typename?: 'InvoiceItem';
      type: InvoiceItemType;
      label: string;
      units: number;
      cost: number;
      total: number;
    }>;
    discountItems: Array<{
      __typename?: 'DiscountItem';
      type: InvoiceDiscountType;
      label: string;
      total: number;
    }>;
    payments: Array<{
      __typename?: 'Payment';
      id: number;
      cardType?: string | null;
      amount: number;
      createdAt: any;
      card?: { __typename?: 'Card'; lastDigits: string } | null;
    }>;
    issuedFines?: Array<{
      __typename?: 'Fine';
      id: number;
      userId: number;
      publicDescription?: string | null;
      fineType: { __typename?: 'FineType'; name: string };
      invoice: {
        __typename?: 'Invoice';
        id: number;
        status: InvoiceStatus;
        amountDue: number;
        outstandingAmount?: number | null;
        currency: CurrencyEnum;
      };
    }>;
    couponPayments: Array<{
      __typename?: 'CouponPayment';
      couponId: number;
      invoiceAmountPaid: number;
      createdAt: any;
    }>;
    creditInvoices: Array<{
      __typename?: 'Invoice';
      id: number;
      reasonDescription?: string | null;
      amountDue: number;
      createdAt: any;
      createdByUser?: {
        __typename?: 'User';
        name?: string | null;
        oid: string;
      } | null;
      invoiceReasonTag?: {
        __typename?: 'InvoiceReasonTag';
        name: string;
        id: number;
        isDescriptionRequired: boolean;
      } | null;
      invoiceItems: Array<{
        __typename?: 'InvoiceItem';
        referenceItemIndex?: number | null;
        label: string;
        type: InvoiceItemType;
        units: number;
        cost: number;
        total: number;
      }>;
      payments: Array<{
        __typename?: 'Payment';
        id: number;
        amount: number;
        card?: { __typename?: 'Card'; lastDigits: string } | null;
      }>;
      couponPayments: Array<{
        __typename?: 'CouponPayment';
        couponId: number;
        invoiceAmountPaid: number;
      }>;
    }>;
  };
};

export type GetTripsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']>;
  vehicleId?: InputMaybe<Scalars['Int']>;
  fleetId?: InputMaybe<Scalars['Int']>;
  cardId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  includeFleet: Scalars['Boolean'];
}>;

export type GetTripsQuery = {
  __typename?: 'Query';
  allFinishedTrips: {
    __typename?: 'FinishedTrips';
    trips?: Array<{
      __typename?: 'Trip';
      id: number;
      oid: string;
      tripStart: any;
      tripEnd?: any | null;
      minutes?: number | null;
      reservedMinutes?: number | null;
      pausedMinutes?: number | null;
      cost?: number | null;
      actualCost?: number | null;
      isPaid: boolean;
      length?: number | null;
      endReason?: TripEndReasonEnum | null;
      isFree: boolean;
      currency: CurrencyEnum;
      fleet?: { __typename?: 'Fleet'; id: number; name: string };
      mainInvoice?: {
        __typename?: 'Invoice';
        id: number;
        outstandingAmount?: number | null;
      } | null;
      user: { __typename?: 'User'; id: number; oid: string };
      vehicle: { __typename?: 'Vehicle'; id: number; code?: string | null };
    }> | null;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      cursor?: string | null;
      remaining?: number | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type RefundTripMutationVariables = Exact<{
  tripId: Scalars['Int'];
}>;

export type RefundTripMutation = {
  __typename?: 'Mutation';
  refundTrip: { __typename?: 'Trip'; id: number; actualCost?: number | null };
};

export type AllFleetsAndScopesQueryVariables = Exact<{ [key: string]: never }>;

export type AllFleetsAndScopesQuery = {
  __typename?: 'Query';
  allFleetsAndScopes: Array<{
    __typename?: 'FleetAndScopes';
    id: number;
    name: string;
    currency: string;
    scopes: Array<string>;
    features: Array<FleetFeatureEnum>;
    role?: string | null;
    serviceDistance?: number | null;
    hexagonSize?: number | null;
    isPublic: boolean;
    region: {
      __typename?: 'Region';
      id: number;
      name: string;
      requiredLanguages: Array<string>;
      countryCode: string;
      timezone: string;
      vehicleTypeGroups: Array<VehicleTypeGroupEnum>;
      location: { __typename?: 'GeoJSONPoint'; coordinates?: any | null };
    };
  }>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: number;
    oid: string;
    scopes: Array<UserScopeEnum | null>;
    canManage: boolean;
    name?: string | null;
    phone?: string | null;
    email?: string | null;
  };
};

export type ActiveVehiclesQueryVariables = Exact<{
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  minutes: Scalars['Int'];
  fleetId: Scalars['Int'];
}>;

export type ActiveVehiclesQuery = {
  __typename?: 'Query';
  activeVehicles: Array<{
    __typename?: 'InactivityType';
    weight: number;
    latitude: number;
    longitude: number;
  }>;
};

export type CardsQueryVariables = Exact<{
  id: Scalars['Int'];
  canQueryStripeId: Scalars['Boolean'];
}>;

export type CardsQuery = {
  __typename?: 'Query';
  cards: Array<{
    __typename?: 'Card';
    lastDigits: string;
    id: number;
    status: CardstatusEnumType;
    expMonth: string;
    expYear: string;
    stripeFingerprint?: string;
    user?: { __typename?: 'User'; oid: string } | null;
    org?: { __typename?: 'Organization'; id: number; name: string } | null;
  }>;
};

export type CouponGroupQueryVariables = Exact<{
  oid: Scalars['String'];
}>;

export type CouponGroupQuery = {
  __typename?: 'Query';
  couponGroup: {
    __typename?: 'CouponGroup';
    id: number;
    oid: string;
    internalName?: string | null;
    qrCode?: string | null;
    couponLimit?: number | null;
    couponsClaimed: number;
    createdAt: any;
    amount: number;
    validFor?: number | null;
    currency: CurrencyEnum;
    vehicleTypeGroups: Array<VehicleTypeGroupRentableEnum>;
    soldFor: number;
    couponType: {
      __typename?: 'CouponType';
      id: number;
      unit: CouponUnitEnum;
      name: string;
    };
    createdByUser: {
      __typename?: 'User';
      id: number;
      oid: string;
      name?: string | null;
    };
    regions: Array<{ __typename?: 'Region'; id: number; name: string }>;
  };
};

export type PaginatedCouponsQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  couponGroupId: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedCouponsQuery = {
  __typename?: 'Query';
  paginatedCoupons: {
    __typename?: 'PaginatedCoupons';
    coupons: Array<{
      __typename?: 'Coupon';
      id: number;
      oid: string;
      publicId?: string | null;
      redeemedAt?: any | null;
      validUntil?: any | null;
      originalAmount: number;
      amountRemaining: number;
      user?: {
        __typename?: 'User';
        id: number;
        oid: string;
        name?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      remaining?: number | null;
      hasNextPage?: boolean | null;
      cursor?: string | null;
    };
  };
};

export type CouponGroupFragment = {
  __typename?: 'CouponGroup';
  id: number;
  oid: string;
  internalName?: string | null;
  couponLimit?: number | null;
  couponsClaimed: number;
  createdAt: any;
  amount: number;
  validFor?: number | null;
  createdByUserId: number;
  vehicleTypeGroups: Array<VehicleTypeGroupRentableEnum>;
  soldFor: number;
  couponType: {
    __typename?: 'CouponType';
    id: number;
    name: string;
    unit: CouponUnitEnum;
  };
  regions: Array<{ __typename?: 'Region'; id: number; name: string }>;
};

export type CouponGroupsQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  excludeNotIssuedByUser: Scalars['Boolean'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type CouponGroupsQuery = {
  __typename?: 'Query';
  couponGroups: {
    __typename?: 'CouponGroups';
    couponGroups: Array<{
      __typename?: 'CouponGroup';
      id: number;
      oid: string;
      internalName?: string | null;
      couponLimit?: number | null;
      couponsClaimed: number;
      createdAt: any;
      amount: number;
      validFor?: number | null;
      createdByUserId: number;
      vehicleTypeGroups: Array<VehicleTypeGroupRentableEnum>;
      soldFor: number;
      couponType: {
        __typename?: 'CouponType';
        id: number;
        name: string;
        unit: CouponUnitEnum;
      };
      regions: Array<{ __typename?: 'Region'; id: number; name: string }>;
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      remaining?: number | null;
      hasNextPage?: boolean | null;
      cursor?: string | null;
    };
  };
};

export type CreateCouponGroupMutationVariables = Exact<{
  input: CreateCouponGroupInput;
}>;

export type CreateCouponGroupMutation = {
  __typename?: 'Mutation';
  createCouponGroup: {
    __typename?: 'CouponGroup';
    id: number;
    oid: string;
    internalName?: string | null;
    couponLimit?: number | null;
    couponsClaimed: number;
    createdAt: any;
    amount: number;
    validFor?: number | null;
    createdByUserId: number;
    vehicleTypeGroups: Array<VehicleTypeGroupRentableEnum>;
    soldFor: number;
    couponType: {
      __typename?: 'CouponType';
      id: number;
      name: string;
      unit: CouponUnitEnum;
    };
    regions: Array<{ __typename?: 'Region'; id: number; name: string }>;
  };
};

export type CouponTypesQueryVariables = Exact<{ [key: string]: never }>;

export type CouponTypesQuery = {
  __typename?: 'Query';
  couponTypes: Array<{
    __typename?: 'CouponType';
    id: number;
    name: string;
    unit: CouponUnitEnum;
  }>;
};

export type DemandQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;

export type DemandQuery = {
  __typename?: 'Query';
  demand: Array<{
    __typename?: 'Demand';
    id: number;
    latitude: number;
    longitude: number;
  }>;
};

export type FineQueryVariables = Exact<{
  fineId: Scalars['Int'];
  includeInvoiceIssuedFines?: InputMaybe<Scalars['Boolean']>;
}>;

export type FineQuery = {
  __typename?: 'Query';
  fine?: {
    __typename?: 'Fine';
    id: number;
    oid: string;
    publicDescription?: string | null;
    privateDescription?: string | null;
    referenceInvoiceOid: string;
    fineType: { __typename?: 'FineType'; name: string };
    uploads: Array<{
      __typename?: 'FineUpload';
      id: number;
      visibleToUser: boolean;
      photo: string;
    }>;
    user: { __typename?: 'Invoice'; id: number; oid: string };
    invoice: {
      __typename?: 'Invoice';
      id: number;
      oid: string;
      reasonDescription?: string | null;
      currency: CurrencyEnum;
      amountDue: number;
      vat: number;
      vatRate: number;
      outstandingAmount?: number | null;
      status: InvoiceStatus;
      createdByUser?: {
        __typename?: 'User';
        name?: string | null;
        oid: string;
      } | null;
      invoiceReasonTag?: {
        __typename?: 'InvoiceReasonTag';
        name: string;
      } | null;
      invoiceItems: Array<{
        __typename?: 'InvoiceItem';
        type: InvoiceItemType;
        label: string;
        units: number;
        cost: number;
        total: number;
      }>;
      discountItems: Array<{
        __typename?: 'DiscountItem';
        type: InvoiceDiscountType;
        label: string;
        total: number;
      }>;
      payments: Array<{
        __typename?: 'Payment';
        id: number;
        cardType?: string | null;
        amount: number;
        createdAt: any;
        card?: { __typename?: 'Card'; lastDigits: string } | null;
      }>;
      issuedFines?: Array<{
        __typename?: 'Fine';
        id: number;
        userId: number;
        publicDescription?: string | null;
        fineType: { __typename?: 'FineType'; name: string };
        invoice: {
          __typename?: 'Invoice';
          id: number;
          status: InvoiceStatus;
          amountDue: number;
          outstandingAmount?: number | null;
          currency: CurrencyEnum;
        };
      }>;
      couponPayments: Array<{
        __typename?: 'CouponPayment';
        couponId: number;
        invoiceAmountPaid: number;
        createdAt: any;
      }>;
      creditInvoices: Array<{
        __typename?: 'Invoice';
        id: number;
        reasonDescription?: string | null;
        amountDue: number;
        createdAt: any;
        createdByUser?: {
          __typename?: 'User';
          name?: string | null;
          oid: string;
        } | null;
        invoiceReasonTag?: {
          __typename?: 'InvoiceReasonTag';
          name: string;
          id: number;
          isDescriptionRequired: boolean;
        } | null;
        invoiceItems: Array<{
          __typename?: 'InvoiceItem';
          referenceItemIndex?: number | null;
          label: string;
          type: InvoiceItemType;
          units: number;
          cost: number;
          total: number;
        }>;
        payments: Array<{
          __typename?: 'Payment';
          id: number;
          amount: number;
          card?: { __typename?: 'Card'; lastDigits: string } | null;
        }>;
        couponPayments: Array<{
          __typename?: 'CouponPayment';
          couponId: number;
          invoiceAmountPaid: number;
        }>;
      }>;
    };
  } | null;
};

export type StatisticsQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  canQueryVehicleStatistics: Scalars['Boolean'];
  canQueryRevenueStatistics: Scalars['Boolean'];
  canQueryTripStatistics: Scalars['Boolean'];
}>;

export type StatisticsQuery = {
  __typename?: 'Query';
  vehicleStatistics?: {
    __typename?: 'VehicleStatistics';
    avgBatteryAvailable: number;
    countOfInactiveBikes: number;
    availableGroup: {
      __typename?: 'StatisticsAvailableGroup';
      total: number;
      outOfBounds: number;
      missing: number;
    };
    tripGroup: { __typename?: 'StatisticsTripGroup'; total: number };
    unavailableGroup: {
      __typename?: 'StatisticsUnavailableGroup';
      total: number;
      lowBattery: number;
      offHours: number;
      compliance: number;
      maintenance: number;
      noConnection: number;
      batteryRemoved: number;
    };
    removedGroup: {
      __typename?: 'StatisticsRemovedGroup';
      total: number;
      charge: number;
      rebalance: number;
      maintenance: number;
      compliance: number;
    };
    inactiveGroup: { __typename?: 'StatisticsInactiveGroup'; missing: number };
  };
  revenueStatistics?: {
    __typename?: 'RevenueStatistics';
    dayRevenue: number;
    monthRevenue: number;
    revenueByDay: Array<{
      __typename?: 'RevenueByDay';
      date: string;
      totalRevenue: number;
    }>;
    currentPaymentPeriod: {
      __typename?: 'RevenueForPaymentPeriod';
      revenue: number;
      startDate: any;
      endDate: any;
    };
    previousPaymentPeriod: {
      __typename?: 'RevenueForPaymentPeriod';
      revenue: number;
      startDate: any;
      endDate: any;
    };
  };
  tripStatistics?: {
    __typename?: 'TripStatistics';
    dayTripCount: number;
    monthTripCount: number;
    tripsByDay: Array<{
      __typename?: 'TripsByDay';
      date: string;
      count: number;
    }>;
  };
};

export type ScopesAndRolesQueryVariables = Exact<{ [key: string]: never }>;

export type ScopesAndRolesQuery = {
  __typename?: 'Query';
  scopesAndRoles: Array<{
    __typename?: 'ScopeDefinition';
    scope: string;
    roles: Array<UserRoleEnum>;
    description: {
      __typename?: 'ScopeDescription';
      view: Array<string>;
      actions: Array<string>;
    };
  }>;
};

export type AllUsersQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  roles?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type AllUsersQuery = {
  __typename?: 'Query';
  allFleetUsers: Array<{
    __typename?: 'FleetUser';
    role?: Fleet_UserroleEnumType | null;
    watcherTypes: Array<WatcherEventTypeEnum>;
    user: {
      __typename?: 'User';
      id: number;
      oid: string;
      phone?: string | null;
      name?: string | null;
    };
  }>;
};

export type RemoveFleetUsersMutationVariables = Exact<{
  fleetId: Scalars['Int'];
  userIds: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;

export type RemoveFleetUsersMutation = {
  __typename?: 'Mutation';
  removeFleetUsers?: boolean | null;
};

export type UpdateFleetUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  fleetId: Scalars['Int'];
  role?: InputMaybe<UserRoleEnum>;
  selectedWatchers?: InputMaybe<
    Array<WatcherEventTypeEnum> | WatcherEventTypeEnum
  >;
}>;

export type UpdateFleetUserMutation = {
  __typename?: 'Mutation';
  updateFleetUser: boolean;
};

export type AddFleetUserMutationVariables = Exact<{
  input: FleetUserInput;
}>;

export type AddFleetUserMutation = {
  __typename?: 'Mutation';
  addFleetUser: { __typename?: 'User'; id: number };
};

export type AllFleetsWithMetricsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllFleetsWithMetricsQuery = {
  __typename?: 'Query';
  allFleetsWithMetrics: Array<{
    __typename?: 'FleetWithMetrics';
    id: number;
    name: string;
    currency: string;
    features: Array<FleetFeatureEnum>;
    isPublic: boolean;
    vehicleTypeGroup: VehicleTypeGroupEnum;
    creditsPerMin: number;
    startCost: number;
    penaltyCost: number;
    reservedCost: number;
    pausedCost: number;
    preAuthAmount?: number | null;
    preAuthWhen: FleetPreAuthWhenEnum;
    maxReservedMinutes?: number | null;
    maxPausedMinutes?: number | null;
    maxInactiveMinutes?: number | null;
    vehicleStatistics: {
      __typename?: 'FleetVehicleStatistics';
      total: number;
      availablePercentage?: number | null;
      inactive: number;
      removed: number;
      avgBatteryAvailable?: number | null;
      onStreet: {
        __typename?: 'VehiclesOnStreet';
        total: number;
        outOfBattery: number;
        available: number;
        unavailable: number;
      };
    };
  }>;
};

export type HexagonGridBySizeMutationVariables = Exact<{
  hexagonSize: Scalars['Int'];
  serviceDistance: Scalars['Int'];
  fleetId: Scalars['Int'];
}>;

export type HexagonGridBySizeMutation = {
  __typename?: 'Mutation';
  makeHexagonGrid: boolean;
};

export type HexagonGridQueryVariables = Exact<{
  fleetId: Scalars['Int'];
}>;

export type HexagonGridQuery = {
  __typename?: 'Query';
  getHexagons: {
    __typename?: 'HexagonGrid';
    totalDemand: number;
    totalBikes: number;
    coordinates: Array<{
      __typename?: 'coordinates';
      coordinates: Array<Array<Array<number>>>;
      demandCount: number;
      bikeInCircle: number;
    }>;
  };
};

export type InactiveVehiclesQueryVariables = Exact<{
  hours: Scalars['Int'];
  fleetId: Scalars['Int'];
}>;

export type InactiveVehiclesQuery = {
  __typename?: 'Query';
  inactiveVehicles: Array<{
    __typename?: 'InactiviteVehicles';
    longitude: number;
    latitude: number;
    vehicleId: number;
    hours?: number | null;
  }>;
};

export type InactivityQueryVariables = Exact<{
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  fleetId: Scalars['Int'];
}>;

export type InactivityQuery = {
  __typename?: 'Query';
  inactivity: Array<{
    __typename?: 'InactivityType';
    id?: string | null;
    latitude: number;
    weight: number;
    longitude: number;
  }>;
};

export type OpenIssueAggregateQueryVariables = Exact<{
  fleetId: Scalars['Int'];
}>;

export type OpenIssueAggregateQuery = {
  __typename?: 'Query';
  openIssueAggregate?: Array<{
    __typename?: 'IssueAggregate';
    count: number;
    issueType: { __typename?: 'IssueType'; key: string };
    issueTags: Array<{
      __typename?: 'IssueTagAggregate';
      tag: string;
      count: number;
    } | null>;
  }> | null;
};

export type GetLicenseQueryVariables = Exact<{
  workflowId: Scalars['String'];
  canViewAuditUser: Scalars['Boolean'];
}>;

export type GetLicenseQuery = {
  __typename?: 'Query';
  license?: {
    __typename?: 'License';
    id: number;
    status: LicenseVerificationStatusEnum;
    reviewedStatus: LicenseVerificationStatusEnum;
    firstName?: string | null;
    lastName?: string | null;
    documentNumber?: string | null;
    expiryDate?: string | null;
    issuingDate?: string | null;
    dateOfBirth?: string | null;
    images: {
      __typename?: 'LicenseImage';
      face?: string | null;
      front?: string | null;
      back?: string | null;
      liveness?: Array<string | null> | null;
    };
    licenseAudit: Array<{
      __typename?: 'LicenseAudit';
      auditCreatedAt: any;
      firstName?: string | null;
      lastName?: string | null;
      documentNumber?: string | null;
      expiryDate?: string | null;
      issuingDate?: string | null;
      dateOfBirth?: string | null;
      status: LicenseVerificationStatusEnum;
      reviewedAutomatically: boolean;
      reviewedByUser?: {
        __typename?: 'User';
        oid: string;
        name?: string | null;
      } | null;
      auditCreatedByUser?: {
        __typename?: 'User';
        oid: string;
        name?: string | null;
      } | null;
    }>;
    reviewedByUser?: {
      __typename?: 'User';
      oid: string;
      name?: string | null;
    } | null;
  } | null;
};

export type ReviewLicenseMutationVariables = Exact<{
  input: ReviewLicenseInput;
}>;

export type ReviewLicenseMutation = {
  __typename?: 'Mutation';
  reviewLicense?: { __typename?: 'License'; id: number } | null;
};

export type BlockLicenseMutationVariables = Exact<{
  input: LicenseBlocklistInput;
}>;

export type BlockLicenseMutation = {
  __typename?: 'Mutation';
  blockLicense: {
    __typename?: 'License';
    id: number;
    status: LicenseVerificationStatusEnum;
  };
};

export type UnblockLicenseMutationVariables = Exact<{
  input: LicenseBlocklistInput;
}>;

export type UnblockLicenseMutation = {
  __typename?: 'Mutation';
  unblockLicense: {
    __typename?: 'License';
    id: number;
    status: LicenseVerificationStatusEnum;
  };
};

export type MarketCitiesQueryVariables = Exact<{
  populationFrom: Scalars['Int'];
  populationTo: Scalars['Int'];
  services: Array<Scalars['Int']> | Scalars['Int'];
  countries: Array<Scalars['String']> | Scalars['String'];
  continents: Array<Scalars['String']> | Scalars['String'];
}>;

export type MarketCitiesQuery = {
  __typename?: 'Query';
  marketCities: Array<{
    __typename?: 'MarketCity';
    id: number;
    name: string;
    latitude: number;
    longitude: number;
    population?: number | null;
    hasScooters: boolean;
    hasBikes: boolean;
    hasTaxis: boolean;
    hasMopeds: boolean;
    hasCarSharing: boolean;
    services: Array<{
      __typename?: 'MarketServiceLocation';
      id: number;
      name: string;
      color?: string | null;
    }>;
  }>;
};

export type MarketCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type MarketCountriesQuery = {
  __typename?: 'Query';
  marketCountries: Array<{
    __typename?: 'MarketCountry';
    countryCode: string;
    name: string;
  }>;
};

export type MarketServicesQueryVariables = Exact<{ [key: string]: never }>;

export type MarketServicesQuery = {
  __typename?: 'Query';
  marketServices: Array<{
    __typename?: 'MarketService';
    id: number;
    name: string;
    color?: string | null;
  }>;
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: number;
    name: string;
    email: string;
    taxNumber?: string | null;
    address?: string | null;
    postalCode?: string | null;
    isActive: boolean;
    hasDebt: boolean;
    monthlyPayments: boolean;
    joinUrl: string;
    invoiceInfo?: string | null;
    usageRestriction?: {
      __typename?: 'OrganizationUsageRestrictionOutput';
      vehicleTypeGroups?: Array<VehicleTypeGroupEnum> | null;
      timePerDay?: {
        __typename?: 'OrganizationUsageRestrictionTimeLimitOutput';
        sunday: {
          __typename?: 'OrganizationUsageRestrictionDayOutput';
          to: string;
          from: string;
        };
        monday: {
          __typename?: 'OrganizationUsageRestrictionDayOutput';
          to: string;
          from: string;
        };
        tuesday: {
          __typename?: 'OrganizationUsageRestrictionDayOutput';
          to: string;
          from: string;
        };
        wednesday: {
          __typename?: 'OrganizationUsageRestrictionDayOutput';
          to: string;
          from: string;
        };
        thursday: {
          __typename?: 'OrganizationUsageRestrictionDayOutput';
          to: string;
          from: string;
        };
        friday: {
          __typename?: 'OrganizationUsageRestrictionDayOutput';
          to: string;
          from: string;
        };
        saturday: {
          __typename?: 'OrganizationUsageRestrictionDayOutput';
          to: string;
          from: string;
        };
      } | null;
    } | null;
    coupons: Array<{
      __typename?: 'Coupon';
      id: number;
      currency: CurrencyEnum;
      amountRemaining: number;
      couponType: { __typename?: 'CouponType'; unit: CouponUnitEnum };
    }>;
    users?: Array<{
      __typename?: 'OrganizationUser';
      id: number;
      oid: string;
      phone?: string | null;
      email?: string | null;
      name?: string | null;
      isAdmin: boolean;
    }> | null;
    card?: {
      __typename?: 'Card';
      id: number;
      lastDigits: string;
      status: CardstatusEnumType;
    } | null;
    organizationInvoices?: Array<{
      __typename?: 'OrganizationInvoice';
      id: number;
      oid: string;
      amountDue: number;
      endDate: any;
      currency: CurrencyEnum;
      outstandingAmount?: number | null;
      operator: { __typename?: 'Operator'; name: string };
    }> | null;
  };
};

export type EditOrganizationMutationVariables = Exact<{
  input: InputEditOrganization;
}>;

export type EditOrganizationMutation = {
  __typename?: 'Mutation';
  editOrganization: {
    __typename?: 'Organization';
    id: number;
    name: string;
    email: string;
    address?: string | null;
    taxNumber?: string | null;
    monthlyPayments: boolean;
    isActive: boolean;
  };
};

export type AddOrganizationMutationVariables = Exact<{
  input: InputAddOrganization;
}>;

export type AddOrganizationMutation = {
  __typename?: 'Mutation';
  addOrganization: {
    __typename?: 'Organization';
    id: number;
    name: string;
    email: string;
    address?: string | null;
    postalCode?: string | null;
    taxNumber?: string | null;
    monthlyPayments: boolean;
    isActive: boolean;
  };
};

export type RemoveOrgUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  orgId: Scalars['Int'];
}>;

export type RemoveOrgUserMutation = {
  __typename?: 'Mutation';
  removeOrgUser: { __typename?: 'RemoveOrganizationUser'; id: number };
};

export type AddOrgUserMutationVariables = Exact<{
  input: InputAddOrgUser;
}>;

export type AddOrgUserMutation = {
  __typename?: 'Mutation';
  addOrgUser: { __typename?: 'AddOrgUser'; userId: number };
};

export type EditOrgUserMutationVariables = Exact<{
  input: InputEditOrgUser;
}>;

export type EditOrgUserMutation = {
  __typename?: 'Mutation';
  editOrgUser: {
    __typename?: 'OrganizationUser';
    id: number;
    name?: string | null;
    isAdmin: boolean;
  };
};

export type SetOrgDiscountMutationVariables = Exact<{
  fleetId: Scalars['Int'];
  organizationId: Scalars['Int'];
  discount: Scalars['Int'];
}>;

export type SetOrgDiscountMutation = {
  __typename?: 'Mutation';
  setOrganizationCoupon?: {
    __typename?: 'Coupon';
    id: number;
    amountRemaining: number;
    currency: CurrencyEnum;
    couponType: { __typename?: 'CouponType'; id: number; unit: CouponUnitEnum };
  } | null;
};

export type OrganizationsQueryVariables = Exact<{
  fleetId?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrder>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'Organizations';
    organizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
      isActive: boolean;
      hasDebt: boolean;
      monthlyPayments: boolean;
      userCount?: number | null;
      createdAt: any;
      taxNumber?: string | null;
      email: string;
      coupons: Array<{
        __typename?: 'Coupon';
        id: number;
        currency: CurrencyEnum;
        amountRemaining: number;
        couponType: { __typename?: 'CouponType'; unit: CouponUnitEnum };
      }>;
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      remaining?: number | null;
      hasNextPage?: boolean | null;
      cursor?: string | null;
    };
  };
};

export type RegionQueryVariables = Exact<{
  regionId: Scalars['Int'];
  canQueryFleets: Scalars['Boolean'];
  canQueryRegistrationCampaigns: Scalars['Boolean'];
}>;

export type RegionQuery = {
  __typename?: 'Query';
  region?: {
    __typename?: 'Region';
    id: number;
    name: string;
    latitude: number;
    longitude: number;
    timezone: string;
    requiredLanguages: Array<string>;
    countryCode: string;
    slug?: string | null;
    status: RegionstatusEnumType;
    fleets?: Array<{
      __typename?: 'Fleet';
      id: number;
      name: string;
      isPublic: boolean;
    }>;
    registrationCampaigns?: Array<{
      __typename?: 'RegistrationCampaign';
      id: number;
      from: any;
      to: any;
      freeRides?: number | null;
      couponGroupId?: number | null;
    }>;
  } | null;
};

export type CreateRegistrationCampaignMutationVariables = Exact<{
  regionId: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  freeRides?: InputMaybe<Scalars['Int']>;
}>;

export type CreateRegistrationCampaignMutation = {
  __typename?: 'Mutation';
  createRegistrationCampaign: {
    __typename?: 'RegistrationCampaign';
    id: number;
    from: any;
    to: any;
    freeRides?: number | null;
    couponGroupId?: number | null;
  };
};

export type RemoteSerialVehicleQueryVariables = Exact<{
  deviceId: Scalars['String'];
}>;

export type RemoteSerialVehicleQuery = {
  __typename?: 'Query';
  vehicle?: {
    __typename?: 'Vehicle';
    id: number;
    code?: string | null;
    batteryLevel?: number | null;
    lastPosition?: any | null;
    longitude?: number | null;
    latitude?: number | null;
    vehicleType: { __typename?: 'VehicleType'; vehicleName: string };
  } | null;
};

export type ToggleVehicleLockByDeviceIdMutationVariables = Exact<{
  deviceId: Scalars['String'];
  lock: Scalars['Boolean'];
}>;

export type ToggleVehicleLockByDeviceIdMutation = {
  __typename?: 'Mutation';
  toggleVehicleLockByDeviceId: boolean;
};

export type DemoModeCodeQueryVariables = Exact<{
  imei: Scalars['String'];
}>;

export type DemoModeCodeQuery = { __typename?: 'Query'; demoModeCode: string };

export type VehicleByImeiQueryVariables = Exact<{
  deviceId: Scalars['String'];
}>;

export type VehicleByImeiQuery = {
  __typename?: 'Query';
  vehicle?: {
    __typename?: 'Vehicle';
    id: number;
    code?: string | null;
    batteryLevel?: number | null;
  } | null;
};

export type ToggleIotModeMutationVariables = Exact<{
  vehicleId: Scalars['Int'];
  mode: VehicleIotMode;
}>;

export type ToggleIotModeMutation = {
  __typename?: 'Mutation';
  toggleIotMode: boolean;
};

export type LocateVehicleMutationVariables = Exact<{
  vehicleId: Scalars['Int'];
}>;

export type LocateVehicleMutation = {
  __typename?: 'Mutation';
  locateVehicle: { __typename?: 'Vehicle'; id: number };
};

export type AllIssuesAndRepairsQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  vehicleId?: InputMaybe<Scalars['Int']>;
  repairedOnly?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type AllIssuesAndRepairsQuery = {
  __typename?: 'Query';
  allIssues: {
    __typename?: 'AllIssues';
    issues: Array<{
      __typename?: 'Issue';
      id: number;
      comment?: string | null;
      createdAt: any;
      vehicleId: number;
      vehicle?: { __typename?: 'Vehicle'; code?: string | null } | null;
      tags: Array<{ __typename?: 'IssueTag'; name?: string | null }>;
      repair?: {
        __typename?: 'Repair';
        fixedAt?: string | null;
        comment?: string | null;
        fixedBy?: { __typename?: 'User'; name?: string | null } | null;
        realIssueType?: { __typename?: 'IssueType'; key: string } | null;
        parts?: Array<{ __typename?: 'Part'; name: string } | null> | null;
      } | null;
      issueType: { __typename?: 'IssueType'; id: number; key: string };
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      cursor?: string | null;
      remaining?: number | null;
      hasNextPage?: boolean | null;
    };
  };
};

export type StaffShiftsAggregateByUserQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;

export type StaffShiftsAggregateByUserQuery = {
  __typename?: 'Query';
  staffShiftsAggregateByUser: Array<{
    __typename?: 'StaffShiftAggregateByUser';
    duration: number;
    batteriesReplaced?: number | null;
    employee?: {
      __typename?: 'User';
      name?: string | null;
      phone?: string | null;
    } | null;
  }>;
};

export type DriverAccountingPeriodsQueryVariables = Exact<{
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  fleetId: Scalars['Int'];
}>;

export type DriverAccountingPeriodsQuery = {
  __typename?: 'Query';
  driverAccountingPeriods: Array<{
    __typename?: 'DriverAccountingPeriod';
    id: number;
    xeroInvoiceId?: string | null;
    startTime: any;
    endTime: any;
    amountDue: number;
    adjustedAmountDue: number;
    currency: CurrencyEnum;
    driver: { __typename?: 'Driver'; id: string; name?: string | null };
    owner?: { __typename?: 'Driver'; id: string; name?: string | null } | null;
    taxiVehicle: {
      __typename?: 'TaxiVehicle';
      id: string;
      licensePlate: string;
    };
  }>;
};

export type MapQueryVariables = Exact<{
  filter?: InputMaybe<TaxiVehicleFilterInput>;
}>;

export type MapQuery = {
  __typename?: 'Query';
  allTaxiVehicles: {
    __typename?: 'VehicleConnection';
    edges: Array<{
      __typename?: 'VehicleEdge';
      node: {
        __typename?: 'TaxiVehicle';
        id: string;
        online: boolean;
        passengerCapacity: number;
        position: {
          __typename?: 'Position';
          id: string;
          heading?: number | null;
          speed?: number | null;
          coordinates?: {
            __typename?: 'TaxiCoordinates';
            latitude: number;
            longitude: number;
          } | null;
        };
      };
    }>;
  };
  allTaxiTrips: {
    __typename?: 'TripConnection';
    edges: Array<{
      __typename?: 'TripEdge';
      node: {
        __typename?: 'TaxiTrip';
        id: string;
        passengerCount: number;
        startedAt?: any | null;
        endedAt?: any | null;
        settledAt?: any | null;
        createdAt: any;
        lastProcessedAt?: any | null;
        departure?: any | null;
        reason?: any | null;
        state: TripState;
        pickupCoordinate: {
          __typename?: 'TaxiCoordinates';
          latitude: number;
          longitude: number;
        };
        dropoffCoordinate?: {
          __typename?: 'TaxiCoordinates';
          latitude: number;
          longitude: number;
        } | null;
        quote: { __typename?: 'TripQuote'; id: string };
      };
    }>;
  };
};

export type TaxiTripMarkerQueryVariables = Exact<{
  taxiTripId: Scalars['ID'];
}>;

export type TaxiTripMarkerQuery = {
  __typename?: 'Query';
  TaxiTrip?: {
    __typename?: 'TaxiTrip';
    id: string;
    quote: { __typename?: 'TripQuote'; id: string; route: any };
    rider: { __typename?: 'User'; name?: string | null; phone?: string | null };
  } | null;
};

export type TaxiTripPanelQueryVariables = Exact<{
  taxiTripId: Scalars['ID'];
}>;

export type TaxiTripPanelQuery = {
  __typename?: 'Query';
  TaxiTrip?: {
    __typename?: 'TaxiTrip';
    id: string;
    startedAt?: any | null;
    endedAt?: any | null;
    createdAt: any;
    acceptedAt?: any | null;
    settledAt?: any | null;
    quote: {
      __typename?: 'TripQuote';
      id: string;
      route: any;
      distance?: number | null;
      duration?: number | null;
      createdAt: any;
      options: Array<{
        __typename?: 'TripQuoteOption';
        total: number;
        earning: number;
        currency: CurrencyEnum;
        eta: Array<number>;
        fleet: { __typename?: 'TaxiFleet'; id: string };
      }>;
    };
    rider: { __typename?: 'User'; name?: string | null; phone?: string | null };
    fleet: { __typename?: 'TaxiFleet'; id: string; name: string };
    vehicle?: { __typename?: 'TaxiVehicle'; id: string } | null;
    pickupAddress?: {
      __typename?: 'Address';
      name?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
    } | null;
    dropoffAddress?: {
      __typename?: 'Address';
      name?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
    } | null;
  } | null;
};

export type SelectedTaxiVehicleQueryVariables = Exact<{
  taxiVehicleId: Scalars['ID'];
}>;

export type SelectedTaxiVehicleQuery = {
  __typename?: 'Query';
  TaxiVehicle: {
    __typename?: 'TaxiVehicle';
    id: string;
    online: boolean;
    passengerCapacity: number;
    licensePlate: string;
    state: VehicleState;
    driver: {
      __typename?: 'Driver';
      id: string;
      name?: string | null;
      phoneNumber?: string | null;
    };
    fleets: Array<{
      __typename?: 'VehicleFleet';
      fleet: {
        __typename?: 'TaxiFleet';
        id: string;
        name: string;
        description?: string | null;
        maximumPassengers: number;
        pool: boolean;
      };
    }>;
    info?: {
      __typename?: 'VehicleInfo';
      id: string;
      make: string;
      model: string;
      year: number;
    } | null;
    steps: Array<{
      __typename?: 'VehicleStep';
      id: string;
      estimatedCompleteAt: any;
      route?: any | null;
      type: StepType;
      address?: {
        __typename?: 'Address';
        streetName?: string | null;
        houseNumber?: string | null;
      } | null;
    }>;
  };
};

export type DriverQueryVariables = Exact<{
  driverId: Scalars['ID'];
}>;

export type DriverQuery = {
  __typename?: 'Query';
  Driver: {
    __typename?: 'Driver';
    id: string;
    name?: string | null;
    phoneNumber?: string | null;
    profileImageUrl?: string | null;
    email?: string | null;
    nationalId?: string | null;
    bankAccount?: string | null;
    activeVehicleId?: string | null;
    permitType?: TaxiPermitType | null;
    approvedAt?: any | null;
    vehicles: Array<{
      __typename?: 'TaxiVehicle';
      id: string;
      online: boolean;
      passengerCapacity: number;
      licensePlate: string;
      approvedAt?: any | null;
      info?: {
        __typename?: 'VehicleInfo';
        id: string;
        make: string;
        model: string;
        color: VehicleColor;
        registeredPlate: string;
        vin: string;
        year: number;
        weight: number;
        status: VehicleStatus;
        nextInspection: any;
      } | null;
      fleets: Array<{
        __typename?: 'VehicleFleet';
        id: string;
        active: boolean;
        fleet: {
          __typename?: 'TaxiFleet';
          id: string;
          name: string;
          description?: string | null;
          pool: boolean;
        };
      }>;
    }>;
  };
};

export type UpdateDriverDetailsMutationVariables = Exact<{
  input: UpdateDriverInput;
  driverId: Scalars['ID'];
}>;

export type UpdateDriverDetailsMutation = {
  __typename?: 'Mutation';
  updateDriverDetails: {
    __typename?: 'Driver';
    id: string;
    name?: string | null;
    phoneNumber?: string | null;
    email?: string | null;
    nationalId?: string | null;
    bankAccount?: string | null;
    permitType?: TaxiPermitType | null;
  };
};

export type UpdateDriverApprovalMutationVariables = Exact<{
  approved: Scalars['Boolean'];
  driverId: Scalars['ID'];
}>;

export type UpdateDriverApprovalMutation = {
  __typename?: 'Mutation';
  updateDriverApproval: {
    __typename?: 'Driver';
    id: string;
    approvedAt?: any | null;
  };
};

export type AllTaxiDrivesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isPending?: InputMaybe<Scalars['Boolean']>;
}>;

export type AllTaxiDrivesQuery = {
  __typename?: 'Query';
  allTaxiDrivers: {
    __typename?: 'DriverConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      __typename?: 'DriverEdge';
      cursor: string;
      node: {
        __typename?: 'Driver';
        id: string;
        name?: string | null;
        phoneNumber?: string | null;
        email?: string | null;
        nationalId?: string | null;
        approvedAt?: any | null;
        permitType?: TaxiPermitType | null;
        createdAt: any;
      };
    }>;
  };
};

export type CreateVehicleMutationVariables = Exact<{
  driverId?: InputMaybe<Scalars['ID']>;
  licensePlate: Scalars['String'];
}>;

export type CreateVehicleMutation = {
  __typename?: 'Mutation';
  createVehicle: {
    __typename?: 'TaxiVehicle';
    id: string;
    licensePlate: string;
    info?: {
      __typename?: 'VehicleInfo';
      make: string;
      model: string;
      id: string;
      year: number;
      nextInspection: any;
    } | null;
  };
};

export type RemoveVehicleMutationVariables = Exact<{
  removeVehicleId: Scalars['ID'];
}>;

export type RemoveVehicleMutation = {
  __typename?: 'Mutation';
  removeVehicle: boolean;
};

export type SetActiveVehicleMutationVariables = Exact<{
  driverId?: InputMaybe<Scalars['ID']>;
  vehicleId: Scalars['ID'];
}>;

export type SetActiveVehicleMutation = {
  __typename?: 'Mutation';
  setActiveVehicle: { __typename?: 'Driver'; id: string };
};

export type TaxiTripQueryVariables = Exact<{
  taxiTripId: Scalars['ID'];
  includeInvoiceIssuedFines?: InputMaybe<Scalars['Boolean']>;
}>;

export type TaxiTripQuery = {
  __typename?: 'Query';
  TaxiTrip?: {
    __typename?: 'TaxiTrip';
    id: string;
    startedAt?: any | null;
    endedAt?: any | null;
    createdAt: any;
    acceptedAt?: any | null;
    settledAt?: any | null;
    state: TripState;
    quote: {
      __typename?: 'TripQuote';
      id: string;
      route: any;
      distance?: number | null;
      duration?: number | null;
      createdAt: any;
      options: Array<{
        __typename?: 'TripQuoteOption';
        total: number;
        earning: number;
        currency: CurrencyEnum;
        eta: Array<number>;
        fleet: { __typename?: 'TaxiFleet'; id: string };
      }>;
    };
    rider: { __typename?: 'User'; name?: string | null; phone?: string | null };
    fleet: { __typename?: 'TaxiFleet'; id: string; name: string };
    vehicle?: {
      __typename?: 'TaxiVehicle';
      id: string;
      licensePlate: string;
      driver: { __typename?: 'Driver'; id: string; name?: string | null };
    } | null;
    pickupAddress?: {
      __typename?: 'Address';
      name?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
    } | null;
    dropoffAddress?: {
      __typename?: 'Address';
      name?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
    } | null;
    invoice?: {
      __typename?: 'Invoice';
      id: number;
      oid: string;
      reasonDescription?: string | null;
      currency: CurrencyEnum;
      amountDue: number;
      vat: number;
      vatRate: number;
      outstandingAmount?: number | null;
      status: InvoiceStatus;
      org?: { __typename?: 'Organization'; id: number; name: string } | null;
      payment?: {
        __typename?: 'Payment';
        card?: {
          __typename?: 'Card';
          id: number;
          lastDigits: string;
          provider: CardproviderEnumType;
        } | null;
      } | null;
      createdByUser?: {
        __typename?: 'User';
        name?: string | null;
        oid: string;
      } | null;
      invoiceReasonTag?: {
        __typename?: 'InvoiceReasonTag';
        name: string;
      } | null;
      invoiceItems: Array<{
        __typename?: 'InvoiceItem';
        type: InvoiceItemType;
        label: string;
        units: number;
        cost: number;
        total: number;
      }>;
      discountItems: Array<{
        __typename?: 'DiscountItem';
        type: InvoiceDiscountType;
        label: string;
        total: number;
      }>;
      payments: Array<{
        __typename?: 'Payment';
        id: number;
        cardType?: string | null;
        amount: number;
        createdAt: any;
        card?: { __typename?: 'Card'; lastDigits: string } | null;
      }>;
      issuedFines?: Array<{
        __typename?: 'Fine';
        id: number;
        userId: number;
        publicDescription?: string | null;
        fineType: { __typename?: 'FineType'; name: string };
        invoice: {
          __typename?: 'Invoice';
          id: number;
          status: InvoiceStatus;
          amountDue: number;
          outstandingAmount?: number | null;
          currency: CurrencyEnum;
        };
      }>;
      couponPayments: Array<{
        __typename?: 'CouponPayment';
        couponId: number;
        invoiceAmountPaid: number;
        createdAt: any;
      }>;
      creditInvoices: Array<{
        __typename?: 'Invoice';
        id: number;
        reasonDescription?: string | null;
        amountDue: number;
        createdAt: any;
        createdByUser?: {
          __typename?: 'User';
          name?: string | null;
          oid: string;
        } | null;
        invoiceReasonTag?: {
          __typename?: 'InvoiceReasonTag';
          name: string;
          id: number;
          isDescriptionRequired: boolean;
        } | null;
        invoiceItems: Array<{
          __typename?: 'InvoiceItem';
          referenceItemIndex?: number | null;
          label: string;
          type: InvoiceItemType;
          units: number;
          cost: number;
          total: number;
        }>;
        payments: Array<{
          __typename?: 'Payment';
          id: number;
          amount: number;
          card?: { __typename?: 'Card'; lastDigits: string } | null;
        }>;
        couponPayments: Array<{
          __typename?: 'CouponPayment';
          couponId: number;
          invoiceAmountPaid: number;
        }>;
      }>;
    } | null;
  } | null;
};

export type AllTaxiFleetsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTaxiFleetsQuery = {
  __typename?: 'Query';
  allTaxiFleets: {
    __typename?: 'FleetConnection';
    edges: Array<{
      __typename?: 'FleetEdge';
      node: {
        __typename?: 'TaxiFleet';
        id: string;
        name: string;
        pool: boolean;
      };
    }>;
  };
};

export type TaxiVehicleQueryVariables = Exact<{
  taxiVehicleId: Scalars['ID'];
}>;

export type TaxiVehicleQuery = {
  __typename?: 'Query';
  TaxiVehicle: {
    __typename?: 'TaxiVehicle';
    id: string;
    driverId: string;
    licensePlate: string;
    passengerCapacity: number;
    online: boolean;
    state: VehicleState;
    approvedAt?: any | null;
    driver: {
      __typename?: 'Driver';
      approvedAt?: any | null;
      id: string;
      name?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      nationalId?: string | null;
      profileImageUrl?: string | null;
      activeVehicleId?: string | null;
    };
    info?: {
      __typename?: 'VehicleInfo';
      id: string;
      make: string;
      model: string;
      color: VehicleColor;
      vin: string;
      registeredPlate: string;
      year: number;
      weight: number;
      status: VehicleStatus;
      nextInspection: any;
      owner?: {
        __typename?: 'Driver';
        id: string;
        name?: string | null;
      } | null;
    } | null;
    fleets: Array<{
      __typename?: 'VehicleFleet';
      id: string;
      active: boolean;
      fleet: {
        __typename?: 'TaxiFleet';
        id: string;
        name: string;
        description?: string | null;
        pool: boolean;
        maximumPassengers: number;
      };
    }>;
  };
};

export type UpdateVehicleApprovalMutationVariables = Exact<{
  approved: Scalars['Boolean'];
  vehicleId: Scalars['ID'];
}>;

export type UpdateVehicleApprovalMutation = {
  __typename?: 'Mutation';
  updateVehicleApproval: {
    __typename?: 'TaxiVehicle';
    id: string;
    approvedAt?: any | null;
  };
};

export type UpdateTaxiVehicleFleetsMutationVariables = Exact<{
  fleetIds: Array<Scalars['ID']> | Scalars['ID'];
  vehicleId: Scalars['ID'];
}>;

export type UpdateTaxiVehicleFleetsMutation = {
  __typename?: 'Mutation';
  updateTaxiVehicleFleets: Array<{
    __typename?: 'VehicleFleet';
    id: string;
    active: boolean;
    fleet: { __typename?: 'TaxiFleet'; id: string };
  }>;
};

export type SetVehicleInfoOwnerDriverMutationVariables = Exact<{
  vehicleInfoId: Scalars['ID'];
  driverId?: InputMaybe<Scalars['ID']>;
}>;

export type SetVehicleInfoOwnerDriverMutation = {
  __typename?: 'Mutation';
  setVehicleInfoOwnerDriver: {
    __typename?: 'VehicleInfo';
    id: string;
    ownerDriverId?: string | null;
  };
};

export type TripDetailsFragment = {
  __typename?: 'Trip';
  usedUserEndLocation: boolean;
  endReason?: TripEndReasonEnum | null;
  tripStart: any;
  tripEnd?: any | null;
  length?: number | null;
  minutes?: number | null;
  photo?: string | null;
  path?: {
    __typename?: 'GeoJSONLineString';
    type: GeoJsonType;
    coordinates?: any | null;
  } | null;
  user: { __typename?: 'User'; id: number; oid: string };
  vehicle: { __typename?: 'Vehicle'; id: number; code?: string | null };
};

export type TripPaymentFragment = {
  __typename?: 'Trip';
  card?: {
    __typename?: 'Card';
    id: number;
    lastDigits: string;
    provider: CardproviderEnumType;
  } | null;
  org?: { __typename?: 'Organization'; id: number; name: string } | null;
  coupon?: {
    __typename?: 'Coupon';
    id: number;
    couponType: { __typename?: 'CouponType'; id: number; name: string };
  } | null;
};

export type RefundTripInvoiceMutationVariables = Exact<{
  tripId: Scalars['Int'];
  items?: InputMaybe<Array<InvoiceItemInput> | InvoiceItemInput>;
  includeInvoiceIssuedFines?: InputMaybe<Scalars['Boolean']>;
}>;

export type RefundTripInvoiceMutation = {
  __typename?: 'Mutation';
  refundTrip: {
    __typename?: 'Trip';
    id: number;
    invoice?: {
      __typename?: 'Invoice';
      id: number;
      oid: string;
      reasonDescription?: string | null;
      currency: CurrencyEnum;
      amountDue: number;
      vat: number;
      vatRate: number;
      outstandingAmount?: number | null;
      status: InvoiceStatus;
      createdByUser?: {
        __typename?: 'User';
        name?: string | null;
        oid: string;
      } | null;
      invoiceReasonTag?: {
        __typename?: 'InvoiceReasonTag';
        name: string;
      } | null;
      invoiceItems: Array<{
        __typename?: 'InvoiceItem';
        type: InvoiceItemType;
        label: string;
        units: number;
        cost: number;
        total: number;
      }>;
      discountItems: Array<{
        __typename?: 'DiscountItem';
        type: InvoiceDiscountType;
        label: string;
        total: number;
      }>;
      payments: Array<{
        __typename?: 'Payment';
        id: number;
        cardType?: string | null;
        amount: number;
        createdAt: any;
        card?: { __typename?: 'Card'; lastDigits: string } | null;
      }>;
      issuedFines?: Array<{
        __typename?: 'Fine';
        id: number;
        userId: number;
        publicDescription?: string | null;
        fineType: { __typename?: 'FineType'; name: string };
        invoice: {
          __typename?: 'Invoice';
          id: number;
          status: InvoiceStatus;
          amountDue: number;
          outstandingAmount?: number | null;
          currency: CurrencyEnum;
        };
      }>;
      couponPayments: Array<{
        __typename?: 'CouponPayment';
        couponId: number;
        invoiceAmountPaid: number;
        createdAt: any;
      }>;
      creditInvoices: Array<{
        __typename?: 'Invoice';
        id: number;
        reasonDescription?: string | null;
        amountDue: number;
        createdAt: any;
        createdByUser?: {
          __typename?: 'User';
          name?: string | null;
          oid: string;
        } | null;
        invoiceReasonTag?: {
          __typename?: 'InvoiceReasonTag';
          name: string;
          id: number;
          isDescriptionRequired: boolean;
        } | null;
        invoiceItems: Array<{
          __typename?: 'InvoiceItem';
          referenceItemIndex?: number | null;
          label: string;
          type: InvoiceItemType;
          units: number;
          cost: number;
          total: number;
        }>;
        payments: Array<{
          __typename?: 'Payment';
          id: number;
          amount: number;
          card?: { __typename?: 'Card'; lastDigits: string } | null;
        }>;
        couponPayments: Array<{
          __typename?: 'CouponPayment';
          couponId: number;
          invoiceAmountPaid: number;
        }>;
      }>;
    } | null;
  };
};

export type TripQueryVariables = Exact<{
  tripId?: InputMaybe<Scalars['Int']>;
  tripOid?: InputMaybe<Scalars['String']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  fleetId?: InputMaybe<Scalars['Int']>;
  includeFleet?: InputMaybe<Scalars['Boolean']>;
  includeInvoiceIssuedFines?: InputMaybe<Scalars['Boolean']>;
}>;

export type TripQuery = {
  __typename?: 'Query';
  trip?: {
    __typename?: 'Trip';
    id: number;
    oid: string;
    usedUserEndLocation: boolean;
    endReason?: TripEndReasonEnum | null;
    tripStart: any;
    tripEnd?: any | null;
    length?: number | null;
    minutes?: number | null;
    photo?: string | null;
    invoice?: {
      __typename?: 'Invoice';
      id: number;
      oid: string;
      reasonDescription?: string | null;
      currency: CurrencyEnum;
      amountDue: number;
      vat: number;
      vatRate: number;
      outstandingAmount?: number | null;
      status: InvoiceStatus;
      createdByUser?: {
        __typename?: 'User';
        name?: string | null;
        oid: string;
      } | null;
      invoiceReasonTag?: {
        __typename?: 'InvoiceReasonTag';
        name: string;
      } | null;
      invoiceItems: Array<{
        __typename?: 'InvoiceItem';
        type: InvoiceItemType;
        label: string;
        units: number;
        cost: number;
        total: number;
      }>;
      discountItems: Array<{
        __typename?: 'DiscountItem';
        type: InvoiceDiscountType;
        label: string;
        total: number;
      }>;
      payments: Array<{
        __typename?: 'Payment';
        id: number;
        cardType?: string | null;
        amount: number;
        createdAt: any;
        card?: { __typename?: 'Card'; lastDigits: string } | null;
      }>;
      issuedFines?: Array<{
        __typename?: 'Fine';
        id: number;
        userId: number;
        publicDescription?: string | null;
        fineType: { __typename?: 'FineType'; name: string };
        invoice: {
          __typename?: 'Invoice';
          id: number;
          status: InvoiceStatus;
          amountDue: number;
          outstandingAmount?: number | null;
          currency: CurrencyEnum;
        };
      }>;
      couponPayments: Array<{
        __typename?: 'CouponPayment';
        couponId: number;
        invoiceAmountPaid: number;
        createdAt: any;
      }>;
      creditInvoices: Array<{
        __typename?: 'Invoice';
        id: number;
        reasonDescription?: string | null;
        amountDue: number;
        createdAt: any;
        createdByUser?: {
          __typename?: 'User';
          name?: string | null;
          oid: string;
        } | null;
        invoiceReasonTag?: {
          __typename?: 'InvoiceReasonTag';
          name: string;
          id: number;
          isDescriptionRequired: boolean;
        } | null;
        invoiceItems: Array<{
          __typename?: 'InvoiceItem';
          referenceItemIndex?: number | null;
          label: string;
          type: InvoiceItemType;
          units: number;
          cost: number;
          total: number;
        }>;
        payments: Array<{
          __typename?: 'Payment';
          id: number;
          amount: number;
          card?: { __typename?: 'Card'; lastDigits: string } | null;
        }>;
        couponPayments: Array<{
          __typename?: 'CouponPayment';
          couponId: number;
          invoiceAmountPaid: number;
        }>;
      }>;
    } | null;
    fleet?: { __typename?: 'Fleet'; id: number; name: string };
    path?: {
      __typename?: 'GeoJSONLineString';
      type: GeoJsonType;
      coordinates?: any | null;
    } | null;
    user: { __typename?: 'User'; id: number; oid: string };
    vehicle: { __typename?: 'Vehicle'; id: number; code?: string | null };
    card?: {
      __typename?: 'Card';
      id: number;
      lastDigits: string;
      provider: CardproviderEnumType;
    } | null;
    org?: { __typename?: 'Organization'; id: number; name: string } | null;
    coupon?: {
      __typename?: 'Coupon';
      id: number;
      couponType: { __typename?: 'CouponType'; id: number; name: string };
    } | null;
  } | null;
};

export type TripRouteQueryVariables = Exact<{
  tripOid: Scalars['String'];
  fleetId?: InputMaybe<Scalars['Int']>;
}>;

export type TripRouteQuery = {
  __typename?: 'Query';
  trip?: {
    __typename?: 'Trip';
    path?: {
      __typename?: 'GeoJSONLineString';
      type: GeoJsonType;
      coordinates?: any | null;
    } | null;
  } | null;
};

export type UnmetDemandQueryVariables = Exact<{
  nearestBike: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;

export type UnmetDemandQuery = {
  __typename?: 'Query';
  unmetDemand: Array<{
    __typename?: 'Demand';
    id: number;
    latitude: number;
    longitude: number;
  }>;
};

export type UserDetailsFragment = {
  __typename?: 'User';
  id: number;
  freeRides: number;
  activeTrips: Array<{
    __typename?: 'ActiveTrip';
    masterTripId?: number | null;
    tripStart: any;
    id: number;
    parkStatus: ParkStatusEnum;
    vehicle: {
      __typename?: 'Vehicle';
      id: number;
      code?: string | null;
      lastPing?: any | null;
    };
  }>;
};

export type UserQueryVariables = Exact<{
  phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  oid?: InputMaybe<Scalars['String']>;
  canQueryEmail: Scalars['Boolean'];
  canQueryCards: Scalars['Boolean'];
  canQueryCoupons: Scalars['Boolean'];
  canQueryStripeId: Scalars['Boolean'];
  canQueryLicenses: Scalars['Boolean'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: number;
    oid: string;
    stripeId?: string | null;
    phone?: string | null;
    email?: string | null;
    createdAt?: any | null;
    name?: string | null;
    deletionRequestedAt?: any | null;
    freeRides: number;
    signUpRegionV2?: {
      __typename?: 'Region';
      id: number;
      name: string;
      country: { __typename?: 'Country'; name: string };
    } | null;
    licenses?: Array<{
      __typename?: 'License';
      id: number;
      workflowId: string;
      status: LicenseVerificationStatusEnum;
      createdAt: any;
      documentNumber?: string | null;
      reviewedAutomatically: boolean;
    }> | null;
    cards?: Array<{
      __typename?: 'Card';
      lastDigits: string;
      id: number;
      status: CardstatusEnumType;
    }>;
    coupons?: Array<{
      __typename?: 'Coupon';
      id: number;
      currency: CurrencyEnum;
      amountRemaining: number;
      originalAmount: number;
      redeemedAt?: any | null;
      validUntil?: any | null;
      couponType: {
        __typename?: 'CouponType';
        id: number;
        unit: CouponUnitEnum;
        name: string;
      };
      couponGroup: {
        __typename?: 'PublicCouponGroup';
        internalName?: string | null;
      };
    }>;
    organizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
      isMissingRequiredCard?: boolean | null;
    }>;
    activeTrips: Array<{
      __typename?: 'ActiveTrip';
      masterTripId?: number | null;
      tripStart: any;
      id: number;
      parkStatus: ParkStatusEnum;
      vehicle: {
        __typename?: 'Vehicle';
        id: number;
        code?: string | null;
        lastPing?: any | null;
      };
    }>;
  } | null;
};

export type RemoveCardMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type RemoveCardMutation = {
  __typename?: 'Mutation';
  removeCard?: boolean | null;
};

export type EndRentMutationVariables = Exact<{
  tripId: Scalars['Int'];
}>;

export type EndRentMutation = {
  __typename?: 'Mutation';
  endRent: { __typename?: 'Trip'; id: number };
};

export type GiveCouponMutationVariables = Exact<{
  input: GiveCouponInput;
}>;

export type GiveCouponMutation = {
  __typename?: 'Mutation';
  giveCoupon: {
    __typename?: 'GiveCouponPayload';
    user: { __typename?: 'User'; id: number; freeRides: number };
  };
};

export type VehicleDetailsFragment = {
  __typename?: 'Vehicle';
  id: number;
  apn?: string | null;
  imei?: string | null;
  bodyCode?: string | null;
  controllerUrl?: string | null;
  telemetryConfig?: any | null;
  telemetryVersions?: any | null;
  vehicleType: { __typename?: 'VehicleType'; vehicleName: string };
};

export type VehicleQueryVariables = Exact<{
  code: Scalars['String'];
  includeTechDetails?: InputMaybe<Scalars['Boolean']>;
}>;

export type VehicleQuery = {
  __typename?: 'Query';
  vehicle?: {
    __typename?: 'Vehicle';
    id: number;
    code?: string | null;
    status: VehiclestatusEnumType;
    apn?: string | null;
    imei?: string | null;
    bodyCode?: string | null;
    controllerUrl?: string | null;
    telemetryConfig?: any | null;
    telemetryVersions?: any | null;
    fleet?: { __typename?: 'Fleet'; id: number; currency: CurrencyEnum } | null;
    vehicleType: { __typename?: 'VehicleType'; vehicleName: string };
  } | null;
};

export type UpdateVehicleStatusMutationVariables = Exact<{
  vehicleId: Scalars['Int'];
  event: VehicleEvent;
  reason?: InputMaybe<VehicleEventReason>;
}>;

export type UpdateVehicleStatusMutation = {
  __typename?: 'Mutation';
  updateVehicleStatus: {
    __typename?: 'Vehicle';
    status: VehiclestatusEnumType;
  };
};

export type VehicleAggregatesQueryVariables = Exact<{
  vehicleId: Scalars['Int'];
}>;

export type VehicleAggregatesQuery = {
  __typename?: 'Query';
  vehicleAggregates: {
    __typename?: 'VehicleAggregates';
    length: number;
    tripCount: number;
    revenueInFleet: number;
  };
};

export type VehicleConnectionStatusQueryVariables = Exact<{
  vehicleId: Scalars['Int'];
}>;

export type VehicleConnectionStatusQuery = {
  __typename?: 'Query';
  vehicle?: {
    __typename?: 'Vehicle';
    lastPosition?: any | null;
    lastPing?: any | null;
  } | null;
};

export type AllVehiclesQueryVariables = Exact<{
  regionId: Scalars['Int'];
  filter?: InputMaybe<VehicleFilter>;
}>;

export type AllVehiclesQuery = {
  __typename?: 'Query';
  allVehicles: Array<{
    __typename?: 'Vehicle';
    id: number;
    code?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    batteryLevel?: number | null;
    range?: number | null;
    status: VehiclestatusEnumType;
    statusReason?: VehiclestatusReasonEnumType | null;
    lockColor?: string | null;
    lastPosition?: any | null;
    fleet?: {
      __typename?: 'Fleet';
      id: number;
      isPublic: boolean;
      isStaffFleet: boolean;
      currency: CurrencyEnum;
    } | null;
    vehicleType: { __typename?: 'VehicleType'; id: number; type: string };
  }>;
};

export type VehicleMapZonesQueryVariables = Exact<{
  regionId: Scalars['Int'];
}>;

export type VehicleMapZonesQuery = {
  __typename?: 'Query';
  changedZones: {
    __typename?: 'ChangedZones';
    updated: Array<{
      __typename?: 'Zone';
      id: number;
      type: ZoneTypeEnum;
      vehicleTypeGroups: any;
      geometry:
        | {
            __typename?: 'GeoJSONLineString';
            type: GeoJsonType;
            coordinates?: any | null;
          }
        | {
            __typename?: 'GeoJSONMultiPolygon';
            type: GeoJsonType;
            coordinates?: any | null;
          }
        | {
            __typename?: 'GeoJSONPoint';
            type: GeoJsonType;
            coordinates?: any | null;
          }
        | {
            __typename?: 'GeoJSONPolygon';
            type: GeoJsonType;
            coordinates?: any | null;
          };
      icon?: { __typename?: 'MediaAsset'; id: number; darkUrl: string } | null;
    }>;
  };
};

export type VehiclePathQueryVariables = Exact<{
  vehicleCode: Scalars['String'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
}>;

export type VehiclePathQuery = {
  __typename?: 'Query';
  vehiclePath: Array<{
    __typename?: 'VehicleTelemetryPoint';
    latitude: number;
    longitude: number;
    speed: number;
    timestamp: string;
  }>;
};

export type VehicleSimQueryVariables = Exact<{
  deviceId: Scalars['String'];
}>;

export type VehicleSimQuery = {
  __typename?: 'Query';
  vehicle?: {
    __typename?: 'Vehicle';
    id: number;
    code?: string | null;
    batteryLevel?: number | null;
  } | null;
};

export type SetApnMutationVariables = Exact<{
  vehicleId: Scalars['Int'];
  apn: Scalars['String'];
}>;

export type SetApnMutation = {
  __typename?: 'Mutation';
  changeApn: { __typename?: 'Vehicle'; id: number };
};

export type AllVehiclesInFleetQueryVariables = Exact<{
  fleetId?: InputMaybe<Scalars['Int']>;
}>;

export type AllVehiclesInFleetQuery = {
  __typename?: 'Query';
  allVehiclesInFleet: Array<{
    __typename?: 'Vehicle';
    id: number;
    code?: string | null;
    bodyCode?: string | null;
    imei?: string | null;
    batteryLevel?: number | null;
    status: VehiclestatusEnumType;
    statusReason?: VehiclestatusReasonEnumType | null;
    lastPosition?: any | null;
    lastPing?: any | null;
    vehicleType: { __typename?: 'VehicleType'; vehicleName: string };
  }>;
};

export type AllWatcherEventsQueryVariables = Exact<{
  fleetId: Scalars['Int'];
  vehicleId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<WatcherEventTypeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type AllWatcherEventsQuery = {
  __typename?: 'Query';
  allWatcherEvents: {
    __typename?: 'AllWatcherEvents';
    watcherEvents: Array<{
      __typename?: 'WatcherEvent';
      id: number;
      metadata: any;
      type: WatcherEventTypeEnum;
      createdAt: any;
      notifications: Array<{
        __typename?: 'WatcherEventNotification';
        message: string;
        user?: {
          __typename?: 'User';
          oid: string;
          name?: string | null;
        } | null;
      }>;
      vehicle: { __typename?: 'Vehicle'; id: number; code?: string | null };
    }>;
    pageInfo: {
      __typename?: 'CursorPageInfo';
      remaining?: number | null;
      hasNextPage?: boolean | null;
      cursor?: string | null;
    };
  };
};

export type AllWatcherDocumentationSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllWatcherDocumentationSummaryQuery = {
  __typename?: 'Query';
  watcherDocumentation: Array<{
    __typename?: 'WatcherEventDocument';
    type: WatcherEventTypeEnum;
    name: string;
  }>;
};

export type AllWatcherDocumentationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllWatcherDocumentationQuery = {
  __typename?: 'Query';
  watcherDocumentation: Array<{
    __typename?: 'WatcherEventDocument';
    type: WatcherEventTypeEnum;
    name: string;
    description: string;
    appliesTo: Array<VehicleTypeGroupEnum>;
    trigger: string;
    whatHappens: string;
  }>;
};

export type ZoneEditorZoneFragment = {
  __typename?: 'Zone';
  id: number;
  type: ZoneTypeEnum;
  internalName?: string | null;
  speedLimit?: number | null;
  discountPercent?: number | null;
  vehicleTypeGroups: any;
  geometry:
    | {
        __typename?: 'GeoJSONLineString';
        type: GeoJsonType;
        coordinates?: any | null;
      }
    | {
        __typename?: 'GeoJSONMultiPolygon';
        type: GeoJsonType;
        coordinates?: any | null;
      }
    | {
        __typename?: 'GeoJSONPoint';
        type: GeoJsonType;
        coordinates?: any | null;
      }
    | {
        __typename?: 'GeoJSONPolygon';
        type: GeoJsonType;
        coordinates?: any | null;
      };
};

export type ZoneEditorZonesQueryVariables = Exact<{
  fleetId: Scalars['Int'];
}>;

export type ZoneEditorZonesQuery = {
  __typename?: 'Query';
  allZonesAdmin: Array<{
    __typename?: 'Zone';
    id: number;
    type: ZoneTypeEnum;
    internalName?: string | null;
    speedLimit?: number | null;
    discountPercent?: number | null;
    vehicleTypeGroups: any;
    geometry:
      | {
          __typename?: 'GeoJSONLineString';
          type: GeoJsonType;
          coordinates?: any | null;
        }
      | {
          __typename?: 'GeoJSONMultiPolygon';
          type: GeoJsonType;
          coordinates?: any | null;
        }
      | {
          __typename?: 'GeoJSONPoint';
          type: GeoJsonType;
          coordinates?: any | null;
        }
      | {
          __typename?: 'GeoJSONPolygon';
          type: GeoJsonType;
          coordinates?: any | null;
        };
  }>;
};

export type UpdateZonesMutationVariables = Exact<{
  input: UpdateZonesInput;
}>;

export type UpdateZonesMutation = {
  __typename?: 'Mutation';
  updateZones: Array<{
    __typename?: 'Zone';
    id: number;
    type: ZoneTypeEnum;
    internalName?: string | null;
    speedLimit?: number | null;
    discountPercent?: number | null;
    vehicleTypeGroups: any;
    geometry:
      | {
          __typename?: 'GeoJSONLineString';
          type: GeoJsonType;
          coordinates?: any | null;
        }
      | {
          __typename?: 'GeoJSONMultiPolygon';
          type: GeoJsonType;
          coordinates?: any | null;
        }
      | {
          __typename?: 'GeoJSONPoint';
          type: GeoJsonType;
          coordinates?: any | null;
        }
      | {
          __typename?: 'GeoJSONPolygon';
          type: GeoJsonType;
          coordinates?: any | null;
        };
  }>;
};

export const InvoiceFragmentDoc = gql`
  fragment Invoice on Invoice {
    id
    oid
    reasonDescription
    createdByUser {
      name
      oid
    }
    currency
    invoiceReasonTag {
      name
    }
    invoiceItems {
      type
      label
      units
      cost
      total
    }
    discountItems {
      type
      label
      total
    }
    amountDue
    vat
    vatRate
    outstandingAmount
    status
    payments {
      id
      cardType
      amount
      createdAt
      card {
        lastDigits
      }
    }
    issuedFines @include(if: $includeInvoiceIssuedFines) {
      id
      userId
      publicDescription
      fineType {
        name
      }
      invoice {
        id
        status
        amountDue
        outstandingAmount
        currency
      }
    }
    couponPayments {
      couponId
      invoiceAmountPaid
      createdAt
    }
    creditInvoices {
      id
      createdByUser {
        name
        oid
      }
      reasonDescription
      invoiceReasonTag {
        name
        id
        isDescriptionRequired
      }
      invoiceItems {
        referenceItemIndex
        label
        type
        units
        cost
        total
      }
      amountDue
      createdAt
      payments {
        id
        amount
        card {
          lastDigits
        }
      }
      couponPayments {
        couponId
        invoiceAmountPaid
      }
    }
  }
`;
export const CouponGroupFragmentDoc = gql`
  fragment CouponGroup on CouponGroup {
    id
    oid
    internalName
    couponType {
      id
      name
      unit
    }
    couponLimit
    couponsClaimed
    createdAt
    amount
    validFor
    createdByUserId
    regions {
      id
      name
    }
    vehicleTypeGroups
    soldFor
  }
`;
export const TripDetailsFragmentDoc = gql`
  fragment TripDetails on Trip {
    usedUserEndLocation
    endReason
    tripStart
    tripEnd
    length
    minutes
    path {
      type
      coordinates
    }
    photo
    user {
      id
      oid
    }
    vehicle {
      id
      code
    }
  }
`;
export const TripPaymentFragmentDoc = gql`
  fragment TripPayment on Trip {
    card {
      id
      lastDigits
      provider
    }
    org {
      id
      name
    }
    coupon {
      id
      couponType {
        id
        name
      }
    }
  }
`;
export const UserDetailsFragmentDoc = gql`
  fragment UserDetails on User {
    id
    freeRides
    activeTrips {
      masterTripId
      tripStart
      id
      parkStatus
      vehicle {
        id
        code
        lastPing
      }
    }
  }
`;
export const VehicleDetailsFragmentDoc = gql`
  fragment VehicleDetails on Vehicle {
    id
    apn
    imei
    bodyCode
    vehicleType {
      vehicleName
    }
    controllerUrl @include(if: $includeTechDetails)
    telemetryConfig @include(if: $includeTechDetails)
    telemetryVersions @include(if: $includeTechDetails)
  }
`;
export const ZoneEditorZoneFragmentDoc = gql`
  fragment ZoneEditorZone on Zone {
    id
    type
    internalName
    speedLimit
    discountPercent
    geometry {
      type
      coordinates
    }
    vehicleTypeGroups
  }
`;
export const GetFullAnnouncementDocument = gql`
  query GetFullAnnouncement($id: Int!) {
    announcement(id: $id) {
      id
      title: rawTitle
      body: rawBody
      buttonText: rawButtonText
      icon
      showFrom
      showTo
      vehicleTypeGroups
    }
  }
`;

/**
 * __useGetFullAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetFullAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullAnnouncementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFullAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFullAnnouncementQuery,
    GetFullAnnouncementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFullAnnouncementQuery,
    GetFullAnnouncementQueryVariables
  >(GetFullAnnouncementDocument, options);
}
export function useGetFullAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFullAnnouncementQuery,
    GetFullAnnouncementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFullAnnouncementQuery,
    GetFullAnnouncementQueryVariables
  >(GetFullAnnouncementDocument, options);
}
export type GetFullAnnouncementQueryHookResult = ReturnType<
  typeof useGetFullAnnouncementQuery
>;
export type GetFullAnnouncementLazyQueryHookResult = ReturnType<
  typeof useGetFullAnnouncementLazyQuery
>;
export type GetFullAnnouncementQueryResult = Apollo.QueryResult<
  GetFullAnnouncementQuery,
  GetFullAnnouncementQueryVariables
>;
export const AddAnnouncementDocument = gql`
  mutation AddAnnouncement($fleetId: Int!, $input: AnnouncementInput!) {
    addAnnouncement(fleetId: $fleetId, input: $input)
  }
`;
export type AddAnnouncementMutationFn = Apollo.MutationFunction<
  AddAnnouncementMutation,
  AddAnnouncementMutationVariables
>;

/**
 * __useAddAnnouncementMutation__
 *
 * To run a mutation, you first call `useAddAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnouncementMutation, { data, loading, error }] = useAddAnnouncementMutation({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAnnouncementMutation,
    AddAnnouncementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAnnouncementMutation,
    AddAnnouncementMutationVariables
  >(AddAnnouncementDocument, options);
}
export type AddAnnouncementMutationHookResult = ReturnType<
  typeof useAddAnnouncementMutation
>;
export type AddAnnouncementMutationResult =
  Apollo.MutationResult<AddAnnouncementMutation>;
export type AddAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  AddAnnouncementMutation,
  AddAnnouncementMutationVariables
>;
export const UpdateAnnouncementDocument = gql`
  mutation UpdateAnnouncement($id: Int!, $input: AnnouncementInput!) {
    updateAnnouncement(id: $id, input: $input)
  }
`;
export type UpdateAnnouncementMutationFn = Apollo.MutationFunction<
  UpdateAnnouncementMutation,
  UpdateAnnouncementMutationVariables
>;

/**
 * __useUpdateAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementMutation, { data, loading, error }] = useUpdateAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnnouncementMutation,
    UpdateAnnouncementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnnouncementMutation,
    UpdateAnnouncementMutationVariables
  >(UpdateAnnouncementDocument, options);
}
export type UpdateAnnouncementMutationHookResult = ReturnType<
  typeof useUpdateAnnouncementMutation
>;
export type UpdateAnnouncementMutationResult =
  Apollo.MutationResult<UpdateAnnouncementMutation>;
export type UpdateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnnouncementMutation,
  UpdateAnnouncementMutationVariables
>;
export const AllAnnouncementsDocument = gql`
  query AllAnnouncements($regionId: Int!) {
    announcements(regionId: $regionId) {
      id
      title
      body
      icon
      showFrom
      showTo
      locales
      vehicleTypeGroups
    }
  }
`;

/**
 * __useAllAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAllAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAnnouncementsQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useAllAnnouncementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllAnnouncementsQuery,
    AllAnnouncementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllAnnouncementsQuery, AllAnnouncementsQueryVariables>(
    AllAnnouncementsDocument,
    options,
  );
}
export function useAllAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAnnouncementsQuery,
    AllAnnouncementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAnnouncementsQuery,
    AllAnnouncementsQueryVariables
  >(AllAnnouncementsDocument, options);
}
export type AllAnnouncementsQueryHookResult = ReturnType<
  typeof useAllAnnouncementsQuery
>;
export type AllAnnouncementsLazyQueryHookResult = ReturnType<
  typeof useAllAnnouncementsLazyQuery
>;
export type AllAnnouncementsQueryResult = Apollo.QueryResult<
  AllAnnouncementsQuery,
  AllAnnouncementsQueryVariables
>;
export const RemoveAnnouncementsDocument = gql`
  mutation RemoveAnnouncements($announcements: [Int!]!) {
    removeAnnouncements(announcementIds: $announcements)
  }
`;
export type RemoveAnnouncementsMutationFn = Apollo.MutationFunction<
  RemoveAnnouncementsMutation,
  RemoveAnnouncementsMutationVariables
>;

/**
 * __useRemoveAnnouncementsMutation__
 *
 * To run a mutation, you first call `useRemoveAnnouncementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnnouncementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnnouncementsMutation, { data, loading, error }] = useRemoveAnnouncementsMutation({
 *   variables: {
 *      announcements: // value for 'announcements'
 *   },
 * });
 */
export function useRemoveAnnouncementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAnnouncementsMutation,
    RemoveAnnouncementsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAnnouncementsMutation,
    RemoveAnnouncementsMutationVariables
  >(RemoveAnnouncementsDocument, options);
}
export type RemoveAnnouncementsMutationHookResult = ReturnType<
  typeof useRemoveAnnouncementsMutation
>;
export type RemoveAnnouncementsMutationResult =
  Apollo.MutationResult<RemoveAnnouncementsMutation>;
export type RemoveAnnouncementsMutationOptions = Apollo.BaseMutationOptions<
  RemoveAnnouncementsMutation,
  RemoveAnnouncementsMutationVariables
>;
export const EnabledFeaturesDocument = gql`
  query EnabledFeatures($regionId: Int) {
    enabledFeatures(regionId: $regionId) {
      name
    }
  }
`;

/**
 * __useEnabledFeaturesQuery__
 *
 * To run a query within a React component, call `useEnabledFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnabledFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnabledFeaturesQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useEnabledFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EnabledFeaturesQuery,
    EnabledFeaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnabledFeaturesQuery, EnabledFeaturesQueryVariables>(
    EnabledFeaturesDocument,
    options,
  );
}
export function useEnabledFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnabledFeaturesQuery,
    EnabledFeaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnabledFeaturesQuery,
    EnabledFeaturesQueryVariables
  >(EnabledFeaturesDocument, options);
}
export type EnabledFeaturesQueryHookResult = ReturnType<
  typeof useEnabledFeaturesQuery
>;
export type EnabledFeaturesLazyQueryHookResult = ReturnType<
  typeof useEnabledFeaturesLazyQuery
>;
export type EnabledFeaturesQueryResult = Apollo.QueryResult<
  EnabledFeaturesQuery,
  EnabledFeaturesQueryVariables
>;
export const AllIssuesDocument = gql`
  query AllIssues(
    $fleetId: Int!
    $openOnly: Boolean
    $limit: Int
    $cursor: String
  ) {
    allIssues(
      fleetId: $fleetId
      openOnly: $openOnly
      limit: $limit
      cursor: $cursor
    ) {
      issues {
        id
        comment
        createdAt
        vehicleId
        vehicle {
          code
        }
        tags {
          name
        }
        issueType {
          key
        }
      }
      pageInfo {
        cursor
        remaining
        hasNextPage
      }
    }
  }
`;

/**
 * __useAllIssuesQuery__
 *
 * To run a query within a React component, call `useAllIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIssuesQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      openOnly: // value for 'openOnly'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAllIssuesQuery(
  baseOptions: Apollo.QueryHookOptions<AllIssuesQuery, AllIssuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllIssuesQuery, AllIssuesQueryVariables>(
    AllIssuesDocument,
    options,
  );
}
export function useAllIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllIssuesQuery,
    AllIssuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllIssuesQuery, AllIssuesQueryVariables>(
    AllIssuesDocument,
    options,
  );
}
export type AllIssuesQueryHookResult = ReturnType<typeof useAllIssuesQuery>;
export type AllIssuesLazyQueryHookResult = ReturnType<
  typeof useAllIssuesLazyQuery
>;
export type AllIssuesQueryResult = Apollo.QueryResult<
  AllIssuesQuery,
  AllIssuesQueryVariables
>;
export const IssueTagsDocument = gql`
  query IssueTags($vehicleTypeId: Int, $issueTypeId: Int) {
    issueTags(vehicleTypeId: $vehicleTypeId, issueTypeId: $issueTypeId) {
      id
      name
      tag
      vehicleTypes {
        id
        type
        vehicleName
      }
      issueType {
        id
        name
      }
    }
  }
`;

/**
 * __useIssueTagsQuery__
 *
 * To run a query within a React component, call `useIssueTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssueTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueTagsQuery({
 *   variables: {
 *      vehicleTypeId: // value for 'vehicleTypeId'
 *      issueTypeId: // value for 'issueTypeId'
 *   },
 * });
 */
export function useIssueTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IssueTagsQuery,
    IssueTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IssueTagsQuery, IssueTagsQueryVariables>(
    IssueTagsDocument,
    options,
  );
}
export function useIssueTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IssueTagsQuery,
    IssueTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IssueTagsQuery, IssueTagsQueryVariables>(
    IssueTagsDocument,
    options,
  );
}
export type IssueTagsQueryHookResult = ReturnType<typeof useIssueTagsQuery>;
export type IssueTagsLazyQueryHookResult = ReturnType<
  typeof useIssueTagsLazyQuery
>;
export type IssueTagsQueryResult = Apollo.QueryResult<
  IssueTagsQuery,
  IssueTagsQueryVariables
>;
export const AddIssueTagDocument = gql`
  mutation AddIssueTag($input: InputIssueTag!) {
    addIssueTag(input: $input) {
      id
      tag
    }
  }
`;
export type AddIssueTagMutationFn = Apollo.MutationFunction<
  AddIssueTagMutation,
  AddIssueTagMutationVariables
>;

/**
 * __useAddIssueTagMutation__
 *
 * To run a mutation, you first call `useAddIssueTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIssueTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIssueTagMutation, { data, loading, error }] = useAddIssueTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIssueTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIssueTagMutation,
    AddIssueTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddIssueTagMutation, AddIssueTagMutationVariables>(
    AddIssueTagDocument,
    options,
  );
}
export type AddIssueTagMutationHookResult = ReturnType<
  typeof useAddIssueTagMutation
>;
export type AddIssueTagMutationResult =
  Apollo.MutationResult<AddIssueTagMutation>;
export type AddIssueTagMutationOptions = Apollo.BaseMutationOptions<
  AddIssueTagMutation,
  AddIssueTagMutationVariables
>;
export const RemoveIssueTagsDocument = gql`
  mutation RemoveIssueTags($issueTagIds: [Int!]!) {
    removeIssueTags(issueTagIds: $issueTagIds)
  }
`;
export type RemoveIssueTagsMutationFn = Apollo.MutationFunction<
  RemoveIssueTagsMutation,
  RemoveIssueTagsMutationVariables
>;

/**
 * __useRemoveIssueTagsMutation__
 *
 * To run a mutation, you first call `useRemoveIssueTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIssueTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIssueTagsMutation, { data, loading, error }] = useRemoveIssueTagsMutation({
 *   variables: {
 *      issueTagIds: // value for 'issueTagIds'
 *   },
 * });
 */
export function useRemoveIssueTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveIssueTagsMutation,
    RemoveIssueTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveIssueTagsMutation,
    RemoveIssueTagsMutationVariables
  >(RemoveIssueTagsDocument, options);
}
export type RemoveIssueTagsMutationHookResult = ReturnType<
  typeof useRemoveIssueTagsMutation
>;
export type RemoveIssueTagsMutationResult =
  Apollo.MutationResult<RemoveIssueTagsMutation>;
export type RemoveIssueTagsMutationOptions = Apollo.BaseMutationOptions<
  RemoveIssueTagsMutation,
  RemoveIssueTagsMutationVariables
>;
export const IssueTypesDocument = gql`
  query IssueTypes {
    issueTypes {
      id
      key
      name
    }
  }
`;

/**
 * __useIssueTypesQuery__
 *
 * To run a query within a React component, call `useIssueTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssueTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIssueTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IssueTypesQuery,
    IssueTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IssueTypesQuery, IssueTypesQueryVariables>(
    IssueTypesDocument,
    options,
  );
}
export function useIssueTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IssueTypesQuery,
    IssueTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IssueTypesQuery, IssueTypesQueryVariables>(
    IssueTypesDocument,
    options,
  );
}
export type IssueTypesQueryHookResult = ReturnType<typeof useIssueTypesQuery>;
export type IssueTypesLazyQueryHookResult = ReturnType<
  typeof useIssueTypesLazyQuery
>;
export type IssueTypesQueryResult = Apollo.QueryResult<
  IssueTypesQuery,
  IssueTypesQueryVariables
>;
export const AllPartsDocument = gql`
  query AllParts($vehicleTypeId: Int) {
    allParts(vehicleTypeId: $vehicleTypeId) {
      id
      name
      price
      manufacturerId
      currency
      vehicleTypes {
        id
        type
        vehicleName
      }
    }
  }
`;

/**
 * __useAllPartsQuery__
 *
 * To run a query within a React component, call `useAllPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPartsQuery({
 *   variables: {
 *      vehicleTypeId: // value for 'vehicleTypeId'
 *   },
 * });
 */
export function useAllPartsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllPartsQuery, AllPartsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPartsQuery, AllPartsQueryVariables>(
    AllPartsDocument,
    options,
  );
}
export function useAllPartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPartsQuery,
    AllPartsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllPartsQuery, AllPartsQueryVariables>(
    AllPartsDocument,
    options,
  );
}
export type AllPartsQueryHookResult = ReturnType<typeof useAllPartsQuery>;
export type AllPartsLazyQueryHookResult = ReturnType<
  typeof useAllPartsLazyQuery
>;
export type AllPartsQueryResult = Apollo.QueryResult<
  AllPartsQuery,
  AllPartsQueryVariables
>;
export const PartDocument = gql`
  query Part($id: Int) {
    part(id: $id) {
      id
      name
      price
      manufacturerId
      currency
      vehicleTypes {
        id
      }
    }
  }
`;

/**
 * __usePartQuery__
 *
 * To run a query within a React component, call `usePartQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartQuery(
  baseOptions?: Apollo.QueryHookOptions<PartQuery, PartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartQuery, PartQueryVariables>(PartDocument, options);
}
export function usePartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartQuery, PartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartQuery, PartQueryVariables>(
    PartDocument,
    options,
  );
}
export type PartQueryHookResult = ReturnType<typeof usePartQuery>;
export type PartLazyQueryHookResult = ReturnType<typeof usePartLazyQuery>;
export type PartQueryResult = Apollo.QueryResult<PartQuery, PartQueryVariables>;
export const AddPartDocument = gql`
  mutation AddPart($part: InputPart!) {
    addPart(part: $part) {
      id
    }
  }
`;
export type AddPartMutationFn = Apollo.MutationFunction<
  AddPartMutation,
  AddPartMutationVariables
>;

/**
 * __useAddPartMutation__
 *
 * To run a mutation, you first call `useAddPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartMutation, { data, loading, error }] = useAddPartMutation({
 *   variables: {
 *      part: // value for 'part'
 *   },
 * });
 */
export function useAddPartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartMutation,
    AddPartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPartMutation, AddPartMutationVariables>(
    AddPartDocument,
    options,
  );
}
export type AddPartMutationHookResult = ReturnType<typeof useAddPartMutation>;
export type AddPartMutationResult = Apollo.MutationResult<AddPartMutation>;
export type AddPartMutationOptions = Apollo.BaseMutationOptions<
  AddPartMutation,
  AddPartMutationVariables
>;
export const UpdatePartDocument = gql`
  mutation UpdatePart($part: InputPart!) {
    updatePart(part: $part) {
      id
    }
  }
`;
export type UpdatePartMutationFn = Apollo.MutationFunction<
  UpdatePartMutation,
  UpdatePartMutationVariables
>;

/**
 * __useUpdatePartMutation__
 *
 * To run a mutation, you first call `useUpdatePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartMutation, { data, loading, error }] = useUpdatePartMutation({
 *   variables: {
 *      part: // value for 'part'
 *   },
 * });
 */
export function useUpdatePartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePartMutation,
    UpdatePartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePartMutation, UpdatePartMutationVariables>(
    UpdatePartDocument,
    options,
  );
}
export type UpdatePartMutationHookResult = ReturnType<
  typeof useUpdatePartMutation
>;
export type UpdatePartMutationResult =
  Apollo.MutationResult<UpdatePartMutation>;
export type UpdatePartMutationOptions = Apollo.BaseMutationOptions<
  UpdatePartMutation,
  UpdatePartMutationVariables
>;
export const GetPartInventoryDocument = gql`
  query GetPartInventory($fleetId: Int!, $id: Int) {
    getPartInventory(fleetId: $fleetId, id: $id) {
      id
      partId
      minAmount
      currentAmount
      recommendedAmount
      part {
        name
        price
        manufacturerId
      }
    }
  }
`;

/**
 * __useGetPartInventoryQuery__
 *
 * To run a query within a React component, call `useGetPartInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartInventoryQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPartInventoryQuery,
    GetPartInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartInventoryQuery, GetPartInventoryQueryVariables>(
    GetPartInventoryDocument,
    options,
  );
}
export function useGetPartInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartInventoryQuery,
    GetPartInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartInventoryQuery,
    GetPartInventoryQueryVariables
  >(GetPartInventoryDocument, options);
}
export type GetPartInventoryQueryHookResult = ReturnType<
  typeof useGetPartInventoryQuery
>;
export type GetPartInventoryLazyQueryHookResult = ReturnType<
  typeof useGetPartInventoryLazyQuery
>;
export type GetPartInventoryQueryResult = Apollo.QueryResult<
  GetPartInventoryQuery,
  GetPartInventoryQueryVariables
>;
export const AddPartInventoryDocument = gql`
  mutation AddPartInventory(
    $fleetId: Int!
    $partInventory: InputPartInventory!
  ) {
    addPartInventory(fleetId: $fleetId, partInventory: $partInventory)
  }
`;
export type AddPartInventoryMutationFn = Apollo.MutationFunction<
  AddPartInventoryMutation,
  AddPartInventoryMutationVariables
>;

/**
 * __useAddPartInventoryMutation__
 *
 * To run a mutation, you first call `useAddPartInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartInventoryMutation, { data, loading, error }] = useAddPartInventoryMutation({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      partInventory: // value for 'partInventory'
 *   },
 * });
 */
export function useAddPartInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPartInventoryMutation,
    AddPartInventoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPartInventoryMutation,
    AddPartInventoryMutationVariables
  >(AddPartInventoryDocument, options);
}
export type AddPartInventoryMutationHookResult = ReturnType<
  typeof useAddPartInventoryMutation
>;
export type AddPartInventoryMutationResult =
  Apollo.MutationResult<AddPartInventoryMutation>;
export type AddPartInventoryMutationOptions = Apollo.BaseMutationOptions<
  AddPartInventoryMutation,
  AddPartInventoryMutationVariables
>;
export const UpdatePartInventoryDocument = gql`
  mutation UpdatePartInventory(
    $fleetId: Int!
    $partInventory: InputPartInventory!
  ) {
    updatePartInventory(fleetId: $fleetId, partInventory: $partInventory)
  }
`;
export type UpdatePartInventoryMutationFn = Apollo.MutationFunction<
  UpdatePartInventoryMutation,
  UpdatePartInventoryMutationVariables
>;

/**
 * __useUpdatePartInventoryMutation__
 *
 * To run a mutation, you first call `useUpdatePartInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartInventoryMutation, { data, loading, error }] = useUpdatePartInventoryMutation({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      partInventory: // value for 'partInventory'
 *   },
 * });
 */
export function useUpdatePartInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePartInventoryMutation,
    UpdatePartInventoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePartInventoryMutation,
    UpdatePartInventoryMutationVariables
  >(UpdatePartInventoryDocument, options);
}
export type UpdatePartInventoryMutationHookResult = ReturnType<
  typeof useUpdatePartInventoryMutation
>;
export type UpdatePartInventoryMutationResult =
  Apollo.MutationResult<UpdatePartInventoryMutation>;
export type UpdatePartInventoryMutationOptions = Apollo.BaseMutationOptions<
  UpdatePartInventoryMutation,
  UpdatePartInventoryMutationVariables
>;
export const AllPartInventoryDocument = gql`
  query AllPartInventory($fleetId: Int!) {
    allPartInventory(fleetId: $fleetId) {
      id
      partId
      minAmount
      currentAmount
      recommendedAmount
      part {
        name
        price
        manufacturerId
      }
    }
  }
`;

/**
 * __useAllPartInventoryQuery__
 *
 * To run a query within a React component, call `useAllPartInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPartInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPartInventoryQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useAllPartInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllPartInventoryQuery,
    AllPartInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPartInventoryQuery, AllPartInventoryQueryVariables>(
    AllPartInventoryDocument,
    options,
  );
}
export function useAllPartInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPartInventoryQuery,
    AllPartInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPartInventoryQuery,
    AllPartInventoryQueryVariables
  >(AllPartInventoryDocument, options);
}
export type AllPartInventoryQueryHookResult = ReturnType<
  typeof useAllPartInventoryQuery
>;
export type AllPartInventoryLazyQueryHookResult = ReturnType<
  typeof useAllPartInventoryLazyQuery
>;
export type AllPartInventoryQueryResult = Apollo.QueryResult<
  AllPartInventoryQuery,
  AllPartInventoryQueryVariables
>;
export const RemovePartInventoryDocument = gql`
  mutation RemovePartInventory($fleetId: Int!, $partInventoryIds: [Int!]!) {
    removePartInventory(fleetId: $fleetId, partInventoryIds: $partInventoryIds)
  }
`;
export type RemovePartInventoryMutationFn = Apollo.MutationFunction<
  RemovePartInventoryMutation,
  RemovePartInventoryMutationVariables
>;

/**
 * __useRemovePartInventoryMutation__
 *
 * To run a mutation, you first call `useRemovePartInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartInventoryMutation, { data, loading, error }] = useRemovePartInventoryMutation({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      partInventoryIds: // value for 'partInventoryIds'
 *   },
 * });
 */
export function useRemovePartInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePartInventoryMutation,
    RemovePartInventoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePartInventoryMutation,
    RemovePartInventoryMutationVariables
  >(RemovePartInventoryDocument, options);
}
export type RemovePartInventoryMutationHookResult = ReturnType<
  typeof useRemovePartInventoryMutation
>;
export type RemovePartInventoryMutationResult =
  Apollo.MutationResult<RemovePartInventoryMutation>;
export type RemovePartInventoryMutationOptions = Apollo.BaseMutationOptions<
  RemovePartInventoryMutation,
  RemovePartInventoryMutationVariables
>;
export const AllStaffShiftsDocument = gql`
  query AllStaffShifts(
    $fleetId: Int!
    $limit: Int
    $cursor: String
    $startDate: String
    $endDate: String
  ) {
    allStaffShifts(
      fleetId: $fleetId
      limit: $limit
      cursor: $cursor
      startDate: $startDate
      endDate: $endDate
    ) {
      staffShifts {
        id
        start
        end
        duration
        staffId
        employee {
          id
          phone
          name
        }
        batteriesReplaced
      }
      pageInfo {
        cursor
        remaining
        hasNextPage
      }
    }
  }
`;

/**
 * __useAllStaffShiftsQuery__
 *
 * To run a query within a React component, call `useAllStaffShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStaffShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStaffShiftsQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAllStaffShiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllStaffShiftsQuery,
    AllStaffShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllStaffShiftsQuery, AllStaffShiftsQueryVariables>(
    AllStaffShiftsDocument,
    options,
  );
}
export function useAllStaffShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllStaffShiftsQuery,
    AllStaffShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllStaffShiftsQuery, AllStaffShiftsQueryVariables>(
    AllStaffShiftsDocument,
    options,
  );
}
export type AllStaffShiftsQueryHookResult = ReturnType<
  typeof useAllStaffShiftsQuery
>;
export type AllStaffShiftsLazyQueryHookResult = ReturnType<
  typeof useAllStaffShiftsLazyQuery
>;
export type AllStaffShiftsQueryResult = Apollo.QueryResult<
  AllStaffShiftsQuery,
  AllStaffShiftsQueryVariables
>;
export const UpdateStaffShiftDocument = gql`
  mutation UpdateStaffShift($staffShift: InputStaffShift!, $fleetId: Int!) {
    updateStaffShift(staffShift: $staffShift, fleetId: $fleetId)
  }
`;
export type UpdateStaffShiftMutationFn = Apollo.MutationFunction<
  UpdateStaffShiftMutation,
  UpdateStaffShiftMutationVariables
>;

/**
 * __useUpdateStaffShiftMutation__
 *
 * To run a mutation, you first call `useUpdateStaffShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffShiftMutation, { data, loading, error }] = useUpdateStaffShiftMutation({
 *   variables: {
 *      staffShift: // value for 'staffShift'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useUpdateStaffShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStaffShiftMutation,
    UpdateStaffShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStaffShiftMutation,
    UpdateStaffShiftMutationVariables
  >(UpdateStaffShiftDocument, options);
}
export type UpdateStaffShiftMutationHookResult = ReturnType<
  typeof useUpdateStaffShiftMutation
>;
export type UpdateStaffShiftMutationResult =
  Apollo.MutationResult<UpdateStaffShiftMutation>;
export type UpdateStaffShiftMutationOptions = Apollo.BaseMutationOptions<
  UpdateStaffShiftMutation,
  UpdateStaffShiftMutationVariables
>;
export const GetStaffShiftDocument = gql`
  query GetStaffShift($staffShiftId: Int!, $fleetId: Int!) {
    getStaffShift(staffShiftId: $staffShiftId, fleetId: $fleetId) {
      start
      end
      staffId
    }
  }
`;

/**
 * __useGetStaffShiftQuery__
 *
 * To run a query within a React component, call `useGetStaffShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffShiftQuery({
 *   variables: {
 *      staffShiftId: // value for 'staffShiftId'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useGetStaffShiftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStaffShiftQuery,
    GetStaffShiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStaffShiftQuery, GetStaffShiftQueryVariables>(
    GetStaffShiftDocument,
    options,
  );
}
export function useGetStaffShiftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStaffShiftQuery,
    GetStaffShiftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStaffShiftQuery, GetStaffShiftQueryVariables>(
    GetStaffShiftDocument,
    options,
  );
}
export type GetStaffShiftQueryHookResult = ReturnType<
  typeof useGetStaffShiftQuery
>;
export type GetStaffShiftLazyQueryHookResult = ReturnType<
  typeof useGetStaffShiftLazyQuery
>;
export type GetStaffShiftQueryResult = Apollo.QueryResult<
  GetStaffShiftQuery,
  GetStaffShiftQueryVariables
>;
export const RemoveStaffShiftsDocument = gql`
  mutation RemoveStaffShifts($staffShiftIds: [Int]!, $fleetId: Int!) {
    removeStaffShifts(staffShiftIds: $staffShiftIds, fleetId: $fleetId)
  }
`;
export type RemoveStaffShiftsMutationFn = Apollo.MutationFunction<
  RemoveStaffShiftsMutation,
  RemoveStaffShiftsMutationVariables
>;

/**
 * __useRemoveStaffShiftsMutation__
 *
 * To run a mutation, you first call `useRemoveStaffShiftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStaffShiftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStaffShiftsMutation, { data, loading, error }] = useRemoveStaffShiftsMutation({
 *   variables: {
 *      staffShiftIds: // value for 'staffShiftIds'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useRemoveStaffShiftsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveStaffShiftsMutation,
    RemoveStaffShiftsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveStaffShiftsMutation,
    RemoveStaffShiftsMutationVariables
  >(RemoveStaffShiftsDocument, options);
}
export type RemoveStaffShiftsMutationHookResult = ReturnType<
  typeof useRemoveStaffShiftsMutation
>;
export type RemoveStaffShiftsMutationResult =
  Apollo.MutationResult<RemoveStaffShiftsMutation>;
export type RemoveStaffShiftsMutationOptions = Apollo.BaseMutationOptions<
  RemoveStaffShiftsMutation,
  RemoveStaffShiftsMutationVariables
>;
export const AddStaffShiftDocument = gql`
  mutation AddStaffShift($staffShift: InputStaffShift!, $fleetId: Int!) {
    addStaffShift(staffShift: $staffShift, fleetId: $fleetId)
  }
`;
export type AddStaffShiftMutationFn = Apollo.MutationFunction<
  AddStaffShiftMutation,
  AddStaffShiftMutationVariables
>;

/**
 * __useAddStaffShiftMutation__
 *
 * To run a mutation, you first call `useAddStaffShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStaffShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStaffShiftMutation, { data, loading, error }] = useAddStaffShiftMutation({
 *   variables: {
 *      staffShift: // value for 'staffShift'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useAddStaffShiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddStaffShiftMutation,
    AddStaffShiftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddStaffShiftMutation,
    AddStaffShiftMutationVariables
  >(AddStaffShiftDocument, options);
}
export type AddStaffShiftMutationHookResult = ReturnType<
  typeof useAddStaffShiftMutation
>;
export type AddStaffShiftMutationResult =
  Apollo.MutationResult<AddStaffShiftMutation>;
export type AddStaffShiftMutationOptions = Apollo.BaseMutationOptions<
  AddStaffShiftMutation,
  AddStaffShiftMutationVariables
>;
export const GetAllStaffDocument = gql`
  query GetAllStaff($fleetId: Int!, $roles: [String!]) {
    allFleetUsers(fleetId: $fleetId, roles: $roles) {
      user {
        id
        name
        phone
      }
    }
  }
`;

/**
 * __useGetAllStaffQuery__
 *
 * To run a query within a React component, call `useGetAllStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStaffQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useGetAllStaffQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllStaffQuery,
    GetAllStaffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllStaffQuery, GetAllStaffQueryVariables>(
    GetAllStaffDocument,
    options,
  );
}
export function useGetAllStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllStaffQuery,
    GetAllStaffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllStaffQuery, GetAllStaffQueryVariables>(
    GetAllStaffDocument,
    options,
  );
}
export type GetAllStaffQueryHookResult = ReturnType<typeof useGetAllStaffQuery>;
export type GetAllStaffLazyQueryHookResult = ReturnType<
  typeof useGetAllStaffLazyQuery
>;
export type GetAllStaffQueryResult = Apollo.QueryResult<
  GetAllStaffQuery,
  GetAllStaffQueryVariables
>;
export const TripsByDatesDocument = gql`
  query TripsByDates(
    $fleetId: Int!
    $limit: Int
    $cursor: String
    $unit: PeriodUnitEnum!
  ) {
    tripsByDates(
      fleetId: $fleetId
      limit: $limit
      cursor: $cursor
      unit: $unit
    ) {
      dates {
        date
        vatRate
        currency
        totalCount
        freeCount
        penaltyCount
        reservedOnlyCount
        freeCostSum
        reservedMinutesSum
        rideMinutesSum
        pausedMinutesSum
        distanceSum
        co2SavedSum
        uniqueUsersCount
        uniqueVehiclesCount
        averagePayment
        averageWorth
        invoiceAggregate {
          debitValueSum
          creditValueSum
          debitVat
          creditVat
          unpaidUserDebitValueSum
          unpaidUserCreditValueSum
          unpaidOrgDebitValueSum
          unpaidOrgCreditValueSum
        }
        paymentAggregate {
          revenueSum
          refundSum
          paidDebtRevenueSum
        }
      }
      pageInfo {
        cursor
        remaining
        hasNextPage
      }
    }
  }
`;

/**
 * __useTripsByDatesQuery__
 *
 * To run a query within a React component, call `useTripsByDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsByDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsByDatesQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      unit: // value for 'unit'
 *   },
 * });
 */
export function useTripsByDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TripsByDatesQuery,
    TripsByDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripsByDatesQuery, TripsByDatesQueryVariables>(
    TripsByDatesDocument,
    options,
  );
}
export function useTripsByDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripsByDatesQuery,
    TripsByDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripsByDatesQuery, TripsByDatesQueryVariables>(
    TripsByDatesDocument,
    options,
  );
}
export type TripsByDatesQueryHookResult = ReturnType<
  typeof useTripsByDatesQuery
>;
export type TripsByDatesLazyQueryHookResult = ReturnType<
  typeof useTripsByDatesLazyQuery
>;
export type TripsByDatesQueryResult = Apollo.QueryResult<
  TripsByDatesQuery,
  TripsByDatesQueryVariables
>;
export const AllVehicleTypesDocument = gql`
  query AllVehicleTypes {
    vehicleTypes {
      id
      vehicleName
      type
    }
  }
`;

/**
 * __useAllVehicleTypesQuery__
 *
 * To run a query within a React component, call `useAllVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVehicleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllVehicleTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllVehicleTypesQuery,
    AllVehicleTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllVehicleTypesQuery, AllVehicleTypesQueryVariables>(
    AllVehicleTypesDocument,
    options,
  );
}
export function useAllVehicleTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllVehicleTypesQuery,
    AllVehicleTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllVehicleTypesQuery,
    AllVehicleTypesQueryVariables
  >(AllVehicleTypesDocument, options);
}
export type AllVehicleTypesQueryHookResult = ReturnType<
  typeof useAllVehicleTypesQuery
>;
export type AllVehicleTypesLazyQueryHookResult = ReturnType<
  typeof useAllVehicleTypesLazyQuery
>;
export type AllVehicleTypesQueryResult = Apollo.QueryResult<
  AllVehicleTypesQuery,
  AllVehicleTypesQueryVariables
>;
export const GetVehicleEventsDocument = gql`
  query GetVehicleEvents($vehicleId: Int!, $limit: Int, $cursor: String) {
    vehicleEvents(vehicleId: $vehicleId, limit: $limit, cursor: $cursor) {
      events {
        id
        time
        beforeState
        afterState
        reason
        location {
          type
          coordinates
        }
        batteryLevel
        user {
          id
          name
          oid
        }
      }
      pageInfo {
        cursor
        remaining
        hasNextPage
      }
    }
  }
`;

/**
 * __useGetVehicleEventsQuery__
 *
 * To run a query within a React component, call `useGetVehicleEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleEventsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetVehicleEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVehicleEventsQuery,
    GetVehicleEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleEventsQuery, GetVehicleEventsQueryVariables>(
    GetVehicleEventsDocument,
    options,
  );
}
export function useGetVehicleEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVehicleEventsQuery,
    GetVehicleEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVehicleEventsQuery,
    GetVehicleEventsQueryVariables
  >(GetVehicleEventsDocument, options);
}
export type GetVehicleEventsQueryHookResult = ReturnType<
  typeof useGetVehicleEventsQuery
>;
export type GetVehicleEventsLazyQueryHookResult = ReturnType<
  typeof useGetVehicleEventsLazyQuery
>;
export type GetVehicleEventsQueryResult = Apollo.QueryResult<
  GetVehicleEventsQuery,
  GetVehicleEventsQueryVariables
>;
export const OperatorsDocument = gql`
  query Operators {
    operators {
      id
      name
      email
      phone
    }
  }
`;

/**
 * __useOperatorsQuery__
 *
 * To run a query within a React component, call `useOperatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OperatorsQuery,
    OperatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperatorsQuery, OperatorsQueryVariables>(
    OperatorsDocument,
    options,
  );
}
export function useOperatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorsQuery,
    OperatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperatorsQuery, OperatorsQueryVariables>(
    OperatorsDocument,
    options,
  );
}
export type OperatorsQueryHookResult = ReturnType<typeof useOperatorsQuery>;
export type OperatorsLazyQueryHookResult = ReturnType<
  typeof useOperatorsLazyQuery
>;
export type OperatorsQueryResult = Apollo.QueryResult<
  OperatorsQuery,
  OperatorsQueryVariables
>;
export const AllRegionsDocument = gql`
  query AllRegions {
    allRegions {
      id
      name
      latitude
      longitude
      timezone
      requiredLanguages
      countryCode
      slug
      status
    }
  }
`;

/**
 * __useAllRegionsQuery__
 *
 * To run a query within a React component, call `useAllRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllRegionsQuery,
    AllRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllRegionsQuery, AllRegionsQueryVariables>(
    AllRegionsDocument,
    options,
  );
}
export function useAllRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllRegionsQuery,
    AllRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllRegionsQuery, AllRegionsQueryVariables>(
    AllRegionsDocument,
    options,
  );
}
export type AllRegionsQueryHookResult = ReturnType<typeof useAllRegionsQuery>;
export type AllRegionsLazyQueryHookResult = ReturnType<
  typeof useAllRegionsLazyQuery
>;
export type AllRegionsQueryResult = Apollo.QueryResult<
  AllRegionsQuery,
  AllRegionsQueryVariables
>;
export const AccountingConfigsDocument = gql`
  query AccountingConfigs {
    accountingConfigs {
      id
      name
    }
  }
`;

/**
 * __useAccountingConfigsQuery__
 *
 * To run a query within a React component, call `useAccountingConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountingConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountingConfigsQuery,
    AccountingConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountingConfigsQuery,
    AccountingConfigsQueryVariables
  >(AccountingConfigsDocument, options);
}
export function useAccountingConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountingConfigsQuery,
    AccountingConfigsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountingConfigsQuery,
    AccountingConfigsQueryVariables
  >(AccountingConfigsDocument, options);
}
export type AccountingConfigsQueryHookResult = ReturnType<
  typeof useAccountingConfigsQuery
>;
export type AccountingConfigsLazyQueryHookResult = ReturnType<
  typeof useAccountingConfigsLazyQuery
>;
export type AccountingConfigsQueryResult = Apollo.QueryResult<
  AccountingConfigsQuery,
  AccountingConfigsQueryVariables
>;
export const AddFleetDocument = gql`
  mutation AddFleet($input: AddFleetInput!) {
    addFleet(input: $input) {
      id
      name
    }
  }
`;
export type AddFleetMutationFn = Apollo.MutationFunction<
  AddFleetMutation,
  AddFleetMutationVariables
>;

/**
 * __useAddFleetMutation__
 *
 * To run a mutation, you first call `useAddFleetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFleetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFleetMutation, { data, loading, error }] = useAddFleetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFleetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFleetMutation,
    AddFleetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFleetMutation, AddFleetMutationVariables>(
    AddFleetDocument,
    options,
  );
}
export type AddFleetMutationHookResult = ReturnType<typeof useAddFleetMutation>;
export type AddFleetMutationResult = Apollo.MutationResult<AddFleetMutation>;
export type AddFleetMutationOptions = Apollo.BaseMutationOptions<
  AddFleetMutation,
  AddFleetMutationVariables
>;
export const EditFleetDocument = gql`
  mutation EditFleet($input: EditFleetInput!) {
    editFleet(input: $input) {
      id
      name
    }
  }
`;
export type EditFleetMutationFn = Apollo.MutationFunction<
  EditFleetMutation,
  EditFleetMutationVariables
>;

/**
 * __useEditFleetMutation__
 *
 * To run a mutation, you first call `useEditFleetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFleetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFleetMutation, { data, loading, error }] = useEditFleetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFleetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditFleetMutation,
    EditFleetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditFleetMutation, EditFleetMutationVariables>(
    EditFleetDocument,
    options,
  );
}
export type EditFleetMutationHookResult = ReturnType<
  typeof useEditFleetMutation
>;
export type EditFleetMutationResult = Apollo.MutationResult<EditFleetMutation>;
export type EditFleetMutationOptions = Apollo.BaseMutationOptions<
  EditFleetMutation,
  EditFleetMutationVariables
>;
export const GetFleetDocument = gql`
  query GetFleet($id: Int!) {
    fleet(id: $id) {
      id
      name
      startCost
      creditsPerMin
      pausedCost
      penaltyCost
      reservedCost
      vatRate
      preAuthAmount
      preAuthWhen
      maxReservedMinutes
      maxPausedMinutes
      maxInactiveMinutes
      currency
      features
      isPublic
      isStaffFleet
      availableApns
      accountingConfigId
    }
  }
`;

/**
 * __useGetFleetQuery__
 *
 * To run a query within a React component, call `useGetFleetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFleetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFleetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFleetQuery(
  baseOptions: Apollo.QueryHookOptions<GetFleetQuery, GetFleetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFleetQuery, GetFleetQueryVariables>(
    GetFleetDocument,
    options,
  );
}
export function useGetFleetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFleetQuery,
    GetFleetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFleetQuery, GetFleetQueryVariables>(
    GetFleetDocument,
    options,
  );
}
export type GetFleetQueryHookResult = ReturnType<typeof useGetFleetQuery>;
export type GetFleetLazyQueryHookResult = ReturnType<
  typeof useGetFleetLazyQuery
>;
export type GetFleetQueryResult = Apollo.QueryResult<
  GetFleetQuery,
  GetFleetQueryVariables
>;
export const FineTypesDocument = gql`
  query FineTypes {
    fineTypes {
      id
      name
    }
  }
`;

/**
 * __useFineTypesQuery__
 *
 * To run a query within a React component, call `useFineTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFineTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFineTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFineTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FineTypesQuery,
    FineTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FineTypesQuery, FineTypesQueryVariables>(
    FineTypesDocument,
    options,
  );
}
export function useFineTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FineTypesQuery,
    FineTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FineTypesQuery, FineTypesQueryVariables>(
    FineTypesDocument,
    options,
  );
}
export type FineTypesQueryHookResult = ReturnType<typeof useFineTypesQuery>;
export type FineTypesLazyQueryHookResult = ReturnType<
  typeof useFineTypesLazyQuery
>;
export type FineTypesQueryResult = Apollo.QueryResult<
  FineTypesQuery,
  FineTypesQueryVariables
>;
export const GetInvoiceReasonTagsDocument = gql`
  query GetInvoiceReasonTags {
    invoiceReasonTags {
      id
      name
      isDescriptionRequired
    }
  }
`;

/**
 * __useGetInvoiceReasonTagsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceReasonTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceReasonTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceReasonTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceReasonTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvoiceReasonTagsQuery,
    GetInvoiceReasonTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoiceReasonTagsQuery,
    GetInvoiceReasonTagsQueryVariables
  >(GetInvoiceReasonTagsDocument, options);
}
export function useGetInvoiceReasonTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceReasonTagsQuery,
    GetInvoiceReasonTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoiceReasonTagsQuery,
    GetInvoiceReasonTagsQueryVariables
  >(GetInvoiceReasonTagsDocument, options);
}
export type GetInvoiceReasonTagsQueryHookResult = ReturnType<
  typeof useGetInvoiceReasonTagsQuery
>;
export type GetInvoiceReasonTagsLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceReasonTagsLazyQuery
>;
export type GetInvoiceReasonTagsQueryResult = Apollo.QueryResult<
  GetInvoiceReasonTagsQuery,
  GetInvoiceReasonTagsQueryVariables
>;
export const IssueFineDocument = gql`
  mutation IssueFine($input: IssueFineInput!) {
    issueFine(input: $input) {
      id
    }
  }
`;
export type IssueFineMutationFn = Apollo.MutationFunction<
  IssueFineMutation,
  IssueFineMutationVariables
>;

/**
 * __useIssueFineMutation__
 *
 * To run a mutation, you first call `useIssueFineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueFineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueFineMutation, { data, loading, error }] = useIssueFineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueFineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IssueFineMutation,
    IssueFineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssueFineMutation, IssueFineMutationVariables>(
    IssueFineDocument,
    options,
  );
}
export type IssueFineMutationHookResult = ReturnType<
  typeof useIssueFineMutation
>;
export type IssueFineMutationResult = Apollo.MutationResult<IssueFineMutation>;
export type IssueFineMutationOptions = Apollo.BaseMutationOptions<
  IssueFineMutation,
  IssueFineMutationVariables
>;
export const RefundInvoiceDocument = gql`
  mutation RefundInvoice(
    $invoiceId: Int!
    $items: [InvoiceItemInput!]
    $invoiceReasonTagId: Int
    $invoiceReasonDescription: String
    $includeInvoiceIssuedFines: Boolean = false
  ) {
    refundInvoice(
      invoiceId: $invoiceId
      items: $items
      invoiceReasonTagId: $invoiceReasonTagId
      invoiceReasonDescription: $invoiceReasonDescription
    ) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;
export type RefundInvoiceMutationFn = Apollo.MutationFunction<
  RefundInvoiceMutation,
  RefundInvoiceMutationVariables
>;

/**
 * __useRefundInvoiceMutation__
 *
 * To run a mutation, you first call `useRefundInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundInvoiceMutation, { data, loading, error }] = useRefundInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      items: // value for 'items'
 *      invoiceReasonTagId: // value for 'invoiceReasonTagId'
 *      invoiceReasonDescription: // value for 'invoiceReasonDescription'
 *      includeInvoiceIssuedFines: // value for 'includeInvoiceIssuedFines'
 *   },
 * });
 */
export function useRefundInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefundInvoiceMutation,
    RefundInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefundInvoiceMutation,
    RefundInvoiceMutationVariables
  >(RefundInvoiceDocument, options);
}
export type RefundInvoiceMutationHookResult = ReturnType<
  typeof useRefundInvoiceMutation
>;
export type RefundInvoiceMutationResult =
  Apollo.MutationResult<RefundInvoiceMutation>;
export type RefundInvoiceMutationOptions = Apollo.BaseMutationOptions<
  RefundInvoiceMutation,
  RefundInvoiceMutationVariables
>;
export const GetTripsDocument = gql`
  query GetTrips(
    $userId: Int
    $vehicleId: Int
    $fleetId: Int
    $cardId: Int
    $limit: Int
    $cursor: String
    $includeFleet: Boolean!
  ) {
    allFinishedTrips(
      userId: $userId
      vehicleId: $vehicleId
      fleetId: $fleetId
      cardIdForPan: $cardId
      limit: $limit
      cursor: $cursor
    ) {
      trips {
        id
        oid
        fleet @include(if: $includeFleet) {
          id
          name
        }
        tripStart
        tripEnd
        minutes
        reservedMinutes
        pausedMinutes
        cost
        actualCost
        isPaid
        length
        endReason
        isFree
        currency
        mainInvoice {
          id
          outstandingAmount
        }
        user {
          id
          oid
        }
        vehicle {
          id
          code
        }
      }
      pageInfo {
        cursor
        remaining
        hasNextPage
      }
    }
  }
`;

/**
 * __useGetTripsQuery__
 *
 * To run a query within a React component, call `useGetTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTripsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      vehicleId: // value for 'vehicleId'
 *      fleetId: // value for 'fleetId'
 *      cardId: // value for 'cardId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      includeFleet: // value for 'includeFleet'
 *   },
 * });
 */
export function useGetTripsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTripsQuery, GetTripsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTripsQuery, GetTripsQueryVariables>(
    GetTripsDocument,
    options,
  );
}
export function useGetTripsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTripsQuery,
    GetTripsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTripsQuery, GetTripsQueryVariables>(
    GetTripsDocument,
    options,
  );
}
export type GetTripsQueryHookResult = ReturnType<typeof useGetTripsQuery>;
export type GetTripsLazyQueryHookResult = ReturnType<
  typeof useGetTripsLazyQuery
>;
export type GetTripsQueryResult = Apollo.QueryResult<
  GetTripsQuery,
  GetTripsQueryVariables
>;
export const RefundTripDocument = gql`
  mutation RefundTrip($tripId: Int!) {
    refundTrip(tripId: $tripId) {
      id
      actualCost
    }
  }
`;
export type RefundTripMutationFn = Apollo.MutationFunction<
  RefundTripMutation,
  RefundTripMutationVariables
>;

/**
 * __useRefundTripMutation__
 *
 * To run a mutation, you first call `useRefundTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundTripMutation, { data, loading, error }] = useRefundTripMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useRefundTripMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefundTripMutation,
    RefundTripMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefundTripMutation, RefundTripMutationVariables>(
    RefundTripDocument,
    options,
  );
}
export type RefundTripMutationHookResult = ReturnType<
  typeof useRefundTripMutation
>;
export type RefundTripMutationResult =
  Apollo.MutationResult<RefundTripMutation>;
export type RefundTripMutationOptions = Apollo.BaseMutationOptions<
  RefundTripMutation,
  RefundTripMutationVariables
>;
export const AllFleetsAndScopesDocument = gql`
  query AllFleetsAndScopes {
    allFleetsAndScopes {
      id
      name
      currency
      scopes
      features
      region {
        id
        name
        requiredLanguages
        location {
          coordinates
        }
        countryCode
        timezone
        vehicleTypeGroups
      }
      role
      serviceDistance
      hexagonSize
      isPublic
    }
  }
`;

/**
 * __useAllFleetsAndScopesQuery__
 *
 * To run a query within a React component, call `useAllFleetsAndScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFleetsAndScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFleetsAndScopesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFleetsAndScopesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllFleetsAndScopesQuery,
    AllFleetsAndScopesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllFleetsAndScopesQuery,
    AllFleetsAndScopesQueryVariables
  >(AllFleetsAndScopesDocument, options);
}
export function useAllFleetsAndScopesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllFleetsAndScopesQuery,
    AllFleetsAndScopesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllFleetsAndScopesQuery,
    AllFleetsAndScopesQueryVariables
  >(AllFleetsAndScopesDocument, options);
}
export type AllFleetsAndScopesQueryHookResult = ReturnType<
  typeof useAllFleetsAndScopesQuery
>;
export type AllFleetsAndScopesLazyQueryHookResult = ReturnType<
  typeof useAllFleetsAndScopesLazyQuery
>;
export type AllFleetsAndScopesQueryResult = Apollo.QueryResult<
  AllFleetsAndScopesQuery,
  AllFleetsAndScopesQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      id
      oid
      scopes
      canManage
      name
      phone
      email
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ActiveVehiclesDocument = gql`
  query ActiveVehicles(
    $startTime: Int!
    $endTime: Int!
    $startDate: String!
    $endDate: String!
    $minutes: Int!
    $fleetId: Int!
  ) {
    activeVehicles(
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
      minutes: $minutes
      fleetId: $fleetId
    ) {
      weight
      latitude
      longitude
    }
  }
`;

/**
 * __useActiveVehiclesQuery__
 *
 * To run a query within a React component, call `useActiveVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveVehiclesQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      minutes: // value for 'minutes'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useActiveVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveVehiclesQuery,
    ActiveVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveVehiclesQuery, ActiveVehiclesQueryVariables>(
    ActiveVehiclesDocument,
    options,
  );
}
export function useActiveVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveVehiclesQuery,
    ActiveVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveVehiclesQuery, ActiveVehiclesQueryVariables>(
    ActiveVehiclesDocument,
    options,
  );
}
export type ActiveVehiclesQueryHookResult = ReturnType<
  typeof useActiveVehiclesQuery
>;
export type ActiveVehiclesLazyQueryHookResult = ReturnType<
  typeof useActiveVehiclesLazyQuery
>;
export type ActiveVehiclesQueryResult = Apollo.QueryResult<
  ActiveVehiclesQuery,
  ActiveVehiclesQueryVariables
>;
export const CardsDocument = gql`
  query Cards($id: Int!, $canQueryStripeId: Boolean!) {
    cards(id: $id) {
      lastDigits
      id
      status
      expMonth
      expYear
      stripeFingerprint @include(if: $canQueryStripeId)
      user {
        oid
      }
      org {
        id
        name
      }
    }
  }
`;

/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      canQueryStripeId: // value for 'canQueryStripeId'
 *   },
 * });
 */
export function useCardsQuery(
  baseOptions: Apollo.QueryHookOptions<CardsQuery, CardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CardsQuery, CardsQueryVariables>(
    CardsDocument,
    options,
  );
}
export function useCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CardsQuery, CardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CardsQuery, CardsQueryVariables>(
    CardsDocument,
    options,
  );
}
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>;
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>;
export type CardsQueryResult = Apollo.QueryResult<
  CardsQuery,
  CardsQueryVariables
>;
export const CouponGroupDocument = gql`
  query CouponGroup($oid: String!) {
    couponGroup(oid: $oid) {
      id
      oid
      internalName
      qrCode
      couponType {
        id
        unit
        name
      }
      couponLimit
      couponsClaimed
      createdAt
      amount
      validFor
      createdAt
      createdByUser {
        id
        oid
        name
      }
      currency
      regions {
        id
        name
      }
      vehicleTypeGroups
      soldFor
    }
  }
`;

/**
 * __useCouponGroupQuery__
 *
 * To run a query within a React component, call `useCouponGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponGroupQuery({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useCouponGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    CouponGroupQuery,
    CouponGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CouponGroupQuery, CouponGroupQueryVariables>(
    CouponGroupDocument,
    options,
  );
}
export function useCouponGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CouponGroupQuery,
    CouponGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CouponGroupQuery, CouponGroupQueryVariables>(
    CouponGroupDocument,
    options,
  );
}
export type CouponGroupQueryHookResult = ReturnType<typeof useCouponGroupQuery>;
export type CouponGroupLazyQueryHookResult = ReturnType<
  typeof useCouponGroupLazyQuery
>;
export type CouponGroupQueryResult = Apollo.QueryResult<
  CouponGroupQuery,
  CouponGroupQueryVariables
>;
export const PaginatedCouponsDocument = gql`
  query PaginatedCoupons(
    $fleetId: Int!
    $couponGroupId: Int!
    $cursor: String
    $limit: Int
  ) {
    paginatedCoupons(
      fleetId: $fleetId
      couponGroupId: $couponGroupId
      cursor: $cursor
      limit: $limit
    ) {
      coupons {
        id
        oid
        publicId
        redeemedAt
        validUntil
        originalAmount
        amountRemaining
        user {
          id
          oid
          name
        }
      }
      pageInfo {
        remaining
        hasNextPage
        cursor
      }
    }
  }
`;

/**
 * __usePaginatedCouponsQuery__
 *
 * To run a query within a React component, call `usePaginatedCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCouponsQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      couponGroupId: // value for 'couponGroupId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePaginatedCouponsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedCouponsQuery,
    PaginatedCouponsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedCouponsQuery, PaginatedCouponsQueryVariables>(
    PaginatedCouponsDocument,
    options,
  );
}
export function usePaginatedCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedCouponsQuery,
    PaginatedCouponsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedCouponsQuery,
    PaginatedCouponsQueryVariables
  >(PaginatedCouponsDocument, options);
}
export type PaginatedCouponsQueryHookResult = ReturnType<
  typeof usePaginatedCouponsQuery
>;
export type PaginatedCouponsLazyQueryHookResult = ReturnType<
  typeof usePaginatedCouponsLazyQuery
>;
export type PaginatedCouponsQueryResult = Apollo.QueryResult<
  PaginatedCouponsQuery,
  PaginatedCouponsQueryVariables
>;
export const CouponGroupsDocument = gql`
  query CouponGroups(
    $fleetId: Int!
    $excludeNotIssuedByUser: Boolean!
    $cursor: String
    $limit: Int
  ) {
    couponGroups(
      fleetId: $fleetId
      excludeNotIssuedByUser: $excludeNotIssuedByUser
      excludeSingular: true
      cursor: $cursor
      limit: $limit
    ) {
      couponGroups {
        ...CouponGroup
      }
      pageInfo {
        remaining
        hasNextPage
        cursor
      }
    }
  }
  ${CouponGroupFragmentDoc}
`;

/**
 * __useCouponGroupsQuery__
 *
 * To run a query within a React component, call `useCouponGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponGroupsQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      excludeNotIssuedByUser: // value for 'excludeNotIssuedByUser'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCouponGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CouponGroupsQuery,
    CouponGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CouponGroupsQuery, CouponGroupsQueryVariables>(
    CouponGroupsDocument,
    options,
  );
}
export function useCouponGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CouponGroupsQuery,
    CouponGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CouponGroupsQuery, CouponGroupsQueryVariables>(
    CouponGroupsDocument,
    options,
  );
}
export type CouponGroupsQueryHookResult = ReturnType<
  typeof useCouponGroupsQuery
>;
export type CouponGroupsLazyQueryHookResult = ReturnType<
  typeof useCouponGroupsLazyQuery
>;
export type CouponGroupsQueryResult = Apollo.QueryResult<
  CouponGroupsQuery,
  CouponGroupsQueryVariables
>;
export const CreateCouponGroupDocument = gql`
  mutation CreateCouponGroup($input: CreateCouponGroupInput!) {
    createCouponGroup(input: $input) {
      ...CouponGroup
    }
  }
  ${CouponGroupFragmentDoc}
`;
export type CreateCouponGroupMutationFn = Apollo.MutationFunction<
  CreateCouponGroupMutation,
  CreateCouponGroupMutationVariables
>;

/**
 * __useCreateCouponGroupMutation__
 *
 * To run a mutation, you first call `useCreateCouponGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponGroupMutation, { data, loading, error }] = useCreateCouponGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCouponGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCouponGroupMutation,
    CreateCouponGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCouponGroupMutation,
    CreateCouponGroupMutationVariables
  >(CreateCouponGroupDocument, options);
}
export type CreateCouponGroupMutationHookResult = ReturnType<
  typeof useCreateCouponGroupMutation
>;
export type CreateCouponGroupMutationResult =
  Apollo.MutationResult<CreateCouponGroupMutation>;
export type CreateCouponGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateCouponGroupMutation,
  CreateCouponGroupMutationVariables
>;
export const CouponTypesDocument = gql`
  query CouponTypes {
    couponTypes {
      id
      name
      unit
    }
  }
`;

/**
 * __useCouponTypesQuery__
 *
 * To run a query within a React component, call `useCouponTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCouponTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CouponTypesQuery,
    CouponTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CouponTypesQuery, CouponTypesQueryVariables>(
    CouponTypesDocument,
    options,
  );
}
export function useCouponTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CouponTypesQuery,
    CouponTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CouponTypesQuery, CouponTypesQueryVariables>(
    CouponTypesDocument,
    options,
  );
}
export type CouponTypesQueryHookResult = ReturnType<typeof useCouponTypesQuery>;
export type CouponTypesLazyQueryHookResult = ReturnType<
  typeof useCouponTypesLazyQuery
>;
export type CouponTypesQueryResult = Apollo.QueryResult<
  CouponTypesQuery,
  CouponTypesQueryVariables
>;
export const DemandDocument = gql`
  query Demand($startDate: String!, $endDate: String!) {
    demand(startDate: $startDate, endDate: $endDate) {
      id
      latitude
      longitude
    }
  }
`;

/**
 * __useDemandQuery__
 *
 * To run a query within a React component, call `useDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemandQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDemandQuery(
  baseOptions: Apollo.QueryHookOptions<DemandQuery, DemandQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemandQuery, DemandQueryVariables>(
    DemandDocument,
    options,
  );
}
export function useDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DemandQuery, DemandQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DemandQuery, DemandQueryVariables>(
    DemandDocument,
    options,
  );
}
export type DemandQueryHookResult = ReturnType<typeof useDemandQuery>;
export type DemandLazyQueryHookResult = ReturnType<typeof useDemandLazyQuery>;
export type DemandQueryResult = Apollo.QueryResult<
  DemandQuery,
  DemandQueryVariables
>;
export const FineDocument = gql`
  query Fine($fineId: Int!, $includeInvoiceIssuedFines: Boolean = false) {
    fine(fineId: $fineId) {
      id
      oid
      publicDescription
      privateDescription
      referenceInvoiceOid
      fineType {
        name
      }
      uploads {
        id
        visibleToUser
        photo
      }
      user {
        id
        oid
      }
      invoice {
        ...Invoice
      }
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useFineQuery__
 *
 * To run a query within a React component, call `useFineQuery` and pass it any options that fit your needs.
 * When your component renders, `useFineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFineQuery({
 *   variables: {
 *      fineId: // value for 'fineId'
 *      includeInvoiceIssuedFines: // value for 'includeInvoiceIssuedFines'
 *   },
 * });
 */
export function useFineQuery(
  baseOptions: Apollo.QueryHookOptions<FineQuery, FineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FineQuery, FineQueryVariables>(FineDocument, options);
}
export function useFineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FineQuery, FineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FineQuery, FineQueryVariables>(
    FineDocument,
    options,
  );
}
export type FineQueryHookResult = ReturnType<typeof useFineQuery>;
export type FineLazyQueryHookResult = ReturnType<typeof useFineLazyQuery>;
export type FineQueryResult = Apollo.QueryResult<FineQuery, FineQueryVariables>;
export const StatisticsDocument = gql`
  query Statistics(
    $fleetId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $canQueryVehicleStatistics: Boolean!
    $canQueryRevenueStatistics: Boolean!
    $canQueryTripStatistics: Boolean!
  ) {
    vehicleStatistics(fleetId: $fleetId)
      @include(if: $canQueryVehicleStatistics) {
      availableGroup {
        total
        outOfBounds
        missing
      }
      tripGroup {
        total
      }
      unavailableGroup {
        total
        lowBattery
        offHours
        compliance
        maintenance
        noConnection
        batteryRemoved
      }
      removedGroup {
        total
        charge
        rebalance
        maintenance
        compliance
      }
      inactiveGroup {
        missing
      }
      avgBatteryAvailable
      countOfInactiveBikes
    }
    revenueStatistics(
      fleetId: $fleetId
      startDate: $startDate
      endDate: $endDate
    ) @include(if: $canQueryRevenueStatistics) {
      revenueByDay {
        date
        totalRevenue
      }
      dayRevenue
      monthRevenue
      currentPaymentPeriod {
        revenue
        startDate
        endDate
      }
      previousPaymentPeriod {
        revenue
        startDate
        endDate
      }
    }
    tripStatistics(fleetId: $fleetId, startDate: $startDate, endDate: $endDate)
      @include(if: $canQueryTripStatistics) {
      tripsByDay {
        date
        count
      }
      dayTripCount
      monthTripCount
    }
  }
`;

/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      canQueryVehicleStatistics: // value for 'canQueryVehicleStatistics'
 *      canQueryRevenueStatistics: // value for 'canQueryRevenueStatistics'
 *      canQueryTripStatistics: // value for 'canQueryTripStatistics'
 *   },
 * });
 */
export function useStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StatisticsQuery,
    StatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StatisticsQuery, StatisticsQueryVariables>(
    StatisticsDocument,
    options,
  );
}
export function useStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StatisticsQuery,
    StatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StatisticsQuery, StatisticsQueryVariables>(
    StatisticsDocument,
    options,
  );
}
export type StatisticsQueryHookResult = ReturnType<typeof useStatisticsQuery>;
export type StatisticsLazyQueryHookResult = ReturnType<
  typeof useStatisticsLazyQuery
>;
export type StatisticsQueryResult = Apollo.QueryResult<
  StatisticsQuery,
  StatisticsQueryVariables
>;
export const ScopesAndRolesDocument = gql`
  query ScopesAndRoles {
    scopesAndRoles {
      scope
      description {
        view
        actions
      }
      roles
    }
  }
`;

/**
 * __useScopesAndRolesQuery__
 *
 * To run a query within a React component, call `useScopesAndRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScopesAndRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScopesAndRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useScopesAndRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScopesAndRolesQuery,
    ScopesAndRolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScopesAndRolesQuery, ScopesAndRolesQueryVariables>(
    ScopesAndRolesDocument,
    options,
  );
}
export function useScopesAndRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScopesAndRolesQuery,
    ScopesAndRolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScopesAndRolesQuery, ScopesAndRolesQueryVariables>(
    ScopesAndRolesDocument,
    options,
  );
}
export type ScopesAndRolesQueryHookResult = ReturnType<
  typeof useScopesAndRolesQuery
>;
export type ScopesAndRolesLazyQueryHookResult = ReturnType<
  typeof useScopesAndRolesLazyQuery
>;
export type ScopesAndRolesQueryResult = Apollo.QueryResult<
  ScopesAndRolesQuery,
  ScopesAndRolesQueryVariables
>;
export const AllUsersDocument = gql`
  query AllUsers($fleetId: Int!, $roles: [String!]) {
    allFleetUsers(fleetId: $fleetId, roles: $roles) {
      role
      user {
        id
        oid
        phone
        name
      }
      watcherTypes
    }
  }
`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    options,
  );
}
export function useAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    options,
  );
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<
  typeof useAllUsersLazyQuery
>;
export type AllUsersQueryResult = Apollo.QueryResult<
  AllUsersQuery,
  AllUsersQueryVariables
>;
export const RemoveFleetUsersDocument = gql`
  mutation RemoveFleetUsers($fleetId: Int!, $userIds: [Int]!) {
    removeFleetUsers(fleetId: $fleetId, userIds: $userIds)
  }
`;
export type RemoveFleetUsersMutationFn = Apollo.MutationFunction<
  RemoveFleetUsersMutation,
  RemoveFleetUsersMutationVariables
>;

/**
 * __useRemoveFleetUsersMutation__
 *
 * To run a mutation, you first call `useRemoveFleetUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFleetUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFleetUsersMutation, { data, loading, error }] = useRemoveFleetUsersMutation({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useRemoveFleetUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFleetUsersMutation,
    RemoveFleetUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFleetUsersMutation,
    RemoveFleetUsersMutationVariables
  >(RemoveFleetUsersDocument, options);
}
export type RemoveFleetUsersMutationHookResult = ReturnType<
  typeof useRemoveFleetUsersMutation
>;
export type RemoveFleetUsersMutationResult =
  Apollo.MutationResult<RemoveFleetUsersMutation>;
export type RemoveFleetUsersMutationOptions = Apollo.BaseMutationOptions<
  RemoveFleetUsersMutation,
  RemoveFleetUsersMutationVariables
>;
export const UpdateFleetUserDocument = gql`
  mutation UpdateFleetUser(
    $userId: Int!
    $fleetId: Int!
    $role: UserRoleEnum
    $selectedWatchers: [WatcherEventTypeEnum!]
  ) {
    updateFleetUser(
      userId: $userId
      fleetId: $fleetId
      role: $role
      selectedWatchers: $selectedWatchers
    )
  }
`;
export type UpdateFleetUserMutationFn = Apollo.MutationFunction<
  UpdateFleetUserMutation,
  UpdateFleetUserMutationVariables
>;

/**
 * __useUpdateFleetUserMutation__
 *
 * To run a mutation, you first call `useUpdateFleetUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFleetUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFleetUserMutation, { data, loading, error }] = useUpdateFleetUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      fleetId: // value for 'fleetId'
 *      role: // value for 'role'
 *      selectedWatchers: // value for 'selectedWatchers'
 *   },
 * });
 */
export function useUpdateFleetUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFleetUserMutation,
    UpdateFleetUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFleetUserMutation,
    UpdateFleetUserMutationVariables
  >(UpdateFleetUserDocument, options);
}
export type UpdateFleetUserMutationHookResult = ReturnType<
  typeof useUpdateFleetUserMutation
>;
export type UpdateFleetUserMutationResult =
  Apollo.MutationResult<UpdateFleetUserMutation>;
export type UpdateFleetUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateFleetUserMutation,
  UpdateFleetUserMutationVariables
>;
export const AddFleetUserDocument = gql`
  mutation AddFleetUser($input: FleetUserInput!) {
    addFleetUser(input: $input) {
      id
    }
  }
`;
export type AddFleetUserMutationFn = Apollo.MutationFunction<
  AddFleetUserMutation,
  AddFleetUserMutationVariables
>;

/**
 * __useAddFleetUserMutation__
 *
 * To run a mutation, you first call `useAddFleetUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFleetUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFleetUserMutation, { data, loading, error }] = useAddFleetUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFleetUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFleetUserMutation,
    AddFleetUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddFleetUserMutation,
    AddFleetUserMutationVariables
  >(AddFleetUserDocument, options);
}
export type AddFleetUserMutationHookResult = ReturnType<
  typeof useAddFleetUserMutation
>;
export type AddFleetUserMutationResult =
  Apollo.MutationResult<AddFleetUserMutation>;
export type AddFleetUserMutationOptions = Apollo.BaseMutationOptions<
  AddFleetUserMutation,
  AddFleetUserMutationVariables
>;
export const AllFleetsWithMetricsDocument = gql`
  query AllFleetsWithMetrics {
    allFleetsWithMetrics {
      id
      name
      currency
      features
      isPublic
      vehicleTypeGroup
      creditsPerMin
      startCost
      penaltyCost
      reservedCost
      pausedCost
      preAuthAmount
      preAuthWhen
      maxReservedMinutes
      maxPausedMinutes
      maxInactiveMinutes
      vehicleStatistics {
        total
        availablePercentage
        inactive
        removed
        onStreet {
          total
          outOfBattery
          available
          unavailable
        }
        avgBatteryAvailable
      }
    }
  }
`;

/**
 * __useAllFleetsWithMetricsQuery__
 *
 * To run a query within a React component, call `useAllFleetsWithMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFleetsWithMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFleetsWithMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFleetsWithMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllFleetsWithMetricsQuery,
    AllFleetsWithMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllFleetsWithMetricsQuery,
    AllFleetsWithMetricsQueryVariables
  >(AllFleetsWithMetricsDocument, options);
}
export function useAllFleetsWithMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllFleetsWithMetricsQuery,
    AllFleetsWithMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllFleetsWithMetricsQuery,
    AllFleetsWithMetricsQueryVariables
  >(AllFleetsWithMetricsDocument, options);
}
export type AllFleetsWithMetricsQueryHookResult = ReturnType<
  typeof useAllFleetsWithMetricsQuery
>;
export type AllFleetsWithMetricsLazyQueryHookResult = ReturnType<
  typeof useAllFleetsWithMetricsLazyQuery
>;
export type AllFleetsWithMetricsQueryResult = Apollo.QueryResult<
  AllFleetsWithMetricsQuery,
  AllFleetsWithMetricsQueryVariables
>;
export const HexagonGridBySizeDocument = gql`
  mutation HexagonGridBySize(
    $hexagonSize: Int!
    $serviceDistance: Int!
    $fleetId: Int!
  ) {
    makeHexagonGrid(
      hexagonSize: $hexagonSize
      serviceDistance: $serviceDistance
      fleetId: $fleetId
    )
  }
`;
export type HexagonGridBySizeMutationFn = Apollo.MutationFunction<
  HexagonGridBySizeMutation,
  HexagonGridBySizeMutationVariables
>;

/**
 * __useHexagonGridBySizeMutation__
 *
 * To run a mutation, you first call `useHexagonGridBySizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHexagonGridBySizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hexagonGridBySizeMutation, { data, loading, error }] = useHexagonGridBySizeMutation({
 *   variables: {
 *      hexagonSize: // value for 'hexagonSize'
 *      serviceDistance: // value for 'serviceDistance'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useHexagonGridBySizeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HexagonGridBySizeMutation,
    HexagonGridBySizeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HexagonGridBySizeMutation,
    HexagonGridBySizeMutationVariables
  >(HexagonGridBySizeDocument, options);
}
export type HexagonGridBySizeMutationHookResult = ReturnType<
  typeof useHexagonGridBySizeMutation
>;
export type HexagonGridBySizeMutationResult =
  Apollo.MutationResult<HexagonGridBySizeMutation>;
export type HexagonGridBySizeMutationOptions = Apollo.BaseMutationOptions<
  HexagonGridBySizeMutation,
  HexagonGridBySizeMutationVariables
>;
export const HexagonGridDocument = gql`
  query HexagonGrid($fleetId: Int!) {
    getHexagons(fleetId: $fleetId) {
      coordinates {
        coordinates
        demandCount
        bikeInCircle
      }
      totalDemand
      totalBikes
    }
  }
`;

/**
 * __useHexagonGridQuery__
 *
 * To run a query within a React component, call `useHexagonGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useHexagonGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHexagonGridQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useHexagonGridQuery(
  baseOptions: Apollo.QueryHookOptions<
    HexagonGridQuery,
    HexagonGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HexagonGridQuery, HexagonGridQueryVariables>(
    HexagonGridDocument,
    options,
  );
}
export function useHexagonGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HexagonGridQuery,
    HexagonGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HexagonGridQuery, HexagonGridQueryVariables>(
    HexagonGridDocument,
    options,
  );
}
export type HexagonGridQueryHookResult = ReturnType<typeof useHexagonGridQuery>;
export type HexagonGridLazyQueryHookResult = ReturnType<
  typeof useHexagonGridLazyQuery
>;
export type HexagonGridQueryResult = Apollo.QueryResult<
  HexagonGridQuery,
  HexagonGridQueryVariables
>;
export const InactiveVehiclesDocument = gql`
  query InactiveVehicles($hours: Int!, $fleetId: Int!) {
    inactiveVehicles(hours: $hours, fleetId: $fleetId) {
      longitude
      latitude
      vehicleId
      hours
    }
  }
`;

/**
 * __useInactiveVehiclesQuery__
 *
 * To run a query within a React component, call `useInactiveVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInactiveVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInactiveVehiclesQuery({
 *   variables: {
 *      hours: // value for 'hours'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useInactiveVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    InactiveVehiclesQuery,
    InactiveVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InactiveVehiclesQuery, InactiveVehiclesQueryVariables>(
    InactiveVehiclesDocument,
    options,
  );
}
export function useInactiveVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InactiveVehiclesQuery,
    InactiveVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InactiveVehiclesQuery,
    InactiveVehiclesQueryVariables
  >(InactiveVehiclesDocument, options);
}
export type InactiveVehiclesQueryHookResult = ReturnType<
  typeof useInactiveVehiclesQuery
>;
export type InactiveVehiclesLazyQueryHookResult = ReturnType<
  typeof useInactiveVehiclesLazyQuery
>;
export type InactiveVehiclesQueryResult = Apollo.QueryResult<
  InactiveVehiclesQuery,
  InactiveVehiclesQueryVariables
>;
export const InactivityDocument = gql`
  query Inactivity(
    $startTime: Int!
    $endTime: Int!
    $startDate: String!
    $endDate: String!
    $fleetId: Int!
  ) {
    inactivity(
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
      fleetId: $fleetId
    ) {
      id
      latitude
      weight
      longitude
    }
  }
`;

/**
 * __useInactivityQuery__
 *
 * To run a query within a React component, call `useInactivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useInactivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInactivityQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useInactivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    InactivityQuery,
    InactivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InactivityQuery, InactivityQueryVariables>(
    InactivityDocument,
    options,
  );
}
export function useInactivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InactivityQuery,
    InactivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InactivityQuery, InactivityQueryVariables>(
    InactivityDocument,
    options,
  );
}
export type InactivityQueryHookResult = ReturnType<typeof useInactivityQuery>;
export type InactivityLazyQueryHookResult = ReturnType<
  typeof useInactivityLazyQuery
>;
export type InactivityQueryResult = Apollo.QueryResult<
  InactivityQuery,
  InactivityQueryVariables
>;
export const OpenIssueAggregateDocument = gql`
  query OpenIssueAggregate($fleetId: Int!) {
    openIssueAggregate(fleetId: $fleetId) {
      issueType {
        key
      }
      count
      issueTags {
        tag
        count
      }
    }
  }
`;

/**
 * __useOpenIssueAggregateQuery__
 *
 * To run a query within a React component, call `useOpenIssueAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenIssueAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenIssueAggregateQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useOpenIssueAggregateQuery(
  baseOptions: Apollo.QueryHookOptions<
    OpenIssueAggregateQuery,
    OpenIssueAggregateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OpenIssueAggregateQuery,
    OpenIssueAggregateQueryVariables
  >(OpenIssueAggregateDocument, options);
}
export function useOpenIssueAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OpenIssueAggregateQuery,
    OpenIssueAggregateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OpenIssueAggregateQuery,
    OpenIssueAggregateQueryVariables
  >(OpenIssueAggregateDocument, options);
}
export type OpenIssueAggregateQueryHookResult = ReturnType<
  typeof useOpenIssueAggregateQuery
>;
export type OpenIssueAggregateLazyQueryHookResult = ReturnType<
  typeof useOpenIssueAggregateLazyQuery
>;
export type OpenIssueAggregateQueryResult = Apollo.QueryResult<
  OpenIssueAggregateQuery,
  OpenIssueAggregateQueryVariables
>;
export const GetLicenseDocument = gql`
  query GetLicense($workflowId: String!, $canViewAuditUser: Boolean!) {
    license(workflowId: $workflowId) {
      id
      status
      reviewedStatus
      firstName
      lastName
      documentNumber
      expiryDate
      issuingDate
      dateOfBirth
      images {
        face
        front
        back
        liveness
      }
      licenseAudit {
        auditCreatedAt
        firstName
        lastName
        documentNumber
        expiryDate
        issuingDate
        dateOfBirth
        status
        reviewedAutomatically
        reviewedByUser @include(if: $canViewAuditUser) {
          oid
          name
        }
        auditCreatedByUser @include(if: $canViewAuditUser) {
          oid
          name
        }
      }
      reviewedByUser @include(if: $canViewAuditUser) {
        oid
        name
      }
    }
  }
`;

/**
 * __useGetLicenseQuery__
 *
 * To run a query within a React component, call `useGetLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicenseQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      canViewAuditUser: // value for 'canViewAuditUser'
 *   },
 * });
 */
export function useGetLicenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLicenseQuery,
    GetLicenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLicenseQuery, GetLicenseQueryVariables>(
    GetLicenseDocument,
    options,
  );
}
export function useGetLicenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLicenseQuery,
    GetLicenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLicenseQuery, GetLicenseQueryVariables>(
    GetLicenseDocument,
    options,
  );
}
export type GetLicenseQueryHookResult = ReturnType<typeof useGetLicenseQuery>;
export type GetLicenseLazyQueryHookResult = ReturnType<
  typeof useGetLicenseLazyQuery
>;
export type GetLicenseQueryResult = Apollo.QueryResult<
  GetLicenseQuery,
  GetLicenseQueryVariables
>;
export const ReviewLicenseDocument = gql`
  mutation ReviewLicense($input: ReviewLicenseInput!) {
    reviewLicense(input: $input) {
      id
    }
  }
`;
export type ReviewLicenseMutationFn = Apollo.MutationFunction<
  ReviewLicenseMutation,
  ReviewLicenseMutationVariables
>;

/**
 * __useReviewLicenseMutation__
 *
 * To run a mutation, you first call `useReviewLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewLicenseMutation, { data, loading, error }] = useReviewLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewLicenseMutation,
    ReviewLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReviewLicenseMutation,
    ReviewLicenseMutationVariables
  >(ReviewLicenseDocument, options);
}
export type ReviewLicenseMutationHookResult = ReturnType<
  typeof useReviewLicenseMutation
>;
export type ReviewLicenseMutationResult =
  Apollo.MutationResult<ReviewLicenseMutation>;
export type ReviewLicenseMutationOptions = Apollo.BaseMutationOptions<
  ReviewLicenseMutation,
  ReviewLicenseMutationVariables
>;
export const BlockLicenseDocument = gql`
  mutation BlockLicense($input: LicenseBlocklistInput!) {
    blockLicense(input: $input) {
      id
      status
    }
  }
`;
export type BlockLicenseMutationFn = Apollo.MutationFunction<
  BlockLicenseMutation,
  BlockLicenseMutationVariables
>;

/**
 * __useBlockLicenseMutation__
 *
 * To run a mutation, you first call `useBlockLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockLicenseMutation, { data, loading, error }] = useBlockLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockLicenseMutation,
    BlockLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BlockLicenseMutation,
    BlockLicenseMutationVariables
  >(BlockLicenseDocument, options);
}
export type BlockLicenseMutationHookResult = ReturnType<
  typeof useBlockLicenseMutation
>;
export type BlockLicenseMutationResult =
  Apollo.MutationResult<BlockLicenseMutation>;
export type BlockLicenseMutationOptions = Apollo.BaseMutationOptions<
  BlockLicenseMutation,
  BlockLicenseMutationVariables
>;
export const UnblockLicenseDocument = gql`
  mutation UnblockLicense($input: LicenseBlocklistInput!) {
    unblockLicense(input: $input) {
      id
      status
    }
  }
`;
export type UnblockLicenseMutationFn = Apollo.MutationFunction<
  UnblockLicenseMutation,
  UnblockLicenseMutationVariables
>;

/**
 * __useUnblockLicenseMutation__
 *
 * To run a mutation, you first call `useUnblockLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockLicenseMutation, { data, loading, error }] = useUnblockLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnblockLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnblockLicenseMutation,
    UnblockLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnblockLicenseMutation,
    UnblockLicenseMutationVariables
  >(UnblockLicenseDocument, options);
}
export type UnblockLicenseMutationHookResult = ReturnType<
  typeof useUnblockLicenseMutation
>;
export type UnblockLicenseMutationResult =
  Apollo.MutationResult<UnblockLicenseMutation>;
export type UnblockLicenseMutationOptions = Apollo.BaseMutationOptions<
  UnblockLicenseMutation,
  UnblockLicenseMutationVariables
>;
export const MarketCitiesDocument = gql`
  query MarketCities(
    $populationFrom: Int!
    $populationTo: Int!
    $services: [Int!]!
    $countries: [String!]!
    $continents: [String!]!
  ) {
    marketCities(
      populationFrom: $populationFrom
      populationTo: $populationTo
      services: $services
      countries: $countries
      continents: $continents
    ) {
      id
      name
      latitude
      longitude
      population
      hasScooters
      hasBikes
      hasTaxis
      hasMopeds
      hasCarSharing
      services {
        id
        name
        color
      }
    }
  }
`;

/**
 * __useMarketCitiesQuery__
 *
 * To run a query within a React component, call `useMarketCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketCitiesQuery({
 *   variables: {
 *      populationFrom: // value for 'populationFrom'
 *      populationTo: // value for 'populationTo'
 *      services: // value for 'services'
 *      countries: // value for 'countries'
 *      continents: // value for 'continents'
 *   },
 * });
 */
export function useMarketCitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketCitiesQuery,
    MarketCitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketCitiesQuery, MarketCitiesQueryVariables>(
    MarketCitiesDocument,
    options,
  );
}
export function useMarketCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketCitiesQuery,
    MarketCitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketCitiesQuery, MarketCitiesQueryVariables>(
    MarketCitiesDocument,
    options,
  );
}
export type MarketCitiesQueryHookResult = ReturnType<
  typeof useMarketCitiesQuery
>;
export type MarketCitiesLazyQueryHookResult = ReturnType<
  typeof useMarketCitiesLazyQuery
>;
export type MarketCitiesQueryResult = Apollo.QueryResult<
  MarketCitiesQuery,
  MarketCitiesQueryVariables
>;
export const MarketCountriesDocument = gql`
  query MarketCountries {
    marketCountries {
      countryCode
      name
    }
  }
`;

/**
 * __useMarketCountriesQuery__
 *
 * To run a query within a React component, call `useMarketCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketCountriesQuery,
    MarketCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketCountriesQuery, MarketCountriesQueryVariables>(
    MarketCountriesDocument,
    options,
  );
}
export function useMarketCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketCountriesQuery,
    MarketCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketCountriesQuery,
    MarketCountriesQueryVariables
  >(MarketCountriesDocument, options);
}
export type MarketCountriesQueryHookResult = ReturnType<
  typeof useMarketCountriesQuery
>;
export type MarketCountriesLazyQueryHookResult = ReturnType<
  typeof useMarketCountriesLazyQuery
>;
export type MarketCountriesQueryResult = Apollo.QueryResult<
  MarketCountriesQuery,
  MarketCountriesQueryVariables
>;
export const MarketServicesDocument = gql`
  query MarketServices {
    marketServices {
      id
      name
      color
    }
  }
`;

/**
 * __useMarketServicesQuery__
 *
 * To run a query within a React component, call `useMarketServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketServicesQuery,
    MarketServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketServicesQuery, MarketServicesQueryVariables>(
    MarketServicesDocument,
    options,
  );
}
export function useMarketServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketServicesQuery,
    MarketServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MarketServicesQuery, MarketServicesQueryVariables>(
    MarketServicesDocument,
    options,
  );
}
export type MarketServicesQueryHookResult = ReturnType<
  typeof useMarketServicesQuery
>;
export type MarketServicesLazyQueryHookResult = ReturnType<
  typeof useMarketServicesLazyQuery
>;
export type MarketServicesQueryResult = Apollo.QueryResult<
  MarketServicesQuery,
  MarketServicesQueryVariables
>;
export const GetOrganizationDocument = gql`
  query GetOrganization($id: Int!) {
    organization(id: $id) {
      id
      name
      email
      taxNumber
      address
      postalCode
      isActive
      hasDebt
      monthlyPayments
      joinUrl
      invoiceInfo
      usageRestriction {
        timePerDay {
          sunday {
            to
            from
          }
          monday {
            to
            from
          }
          tuesday {
            to
            from
          }
          wednesday {
            to
            from
          }
          thursday {
            to
            from
          }
          friday {
            to
            from
          }
          saturday {
            to
            from
          }
        }
        vehicleTypeGroups
      }
      coupons {
        id
        currency
        amountRemaining
        couponType {
          unit
        }
      }
      users {
        id
        oid
        phone
        email
        name
        isAdmin
      }
      card {
        id
        lastDigits
        status
      }
      organizationInvoices {
        id
        oid
        amountDue
        endDate
        currency
        operator {
          name
        }
        outstandingAmount
      }
    }
  }
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, options);
}
export type GetOrganizationQueryHookResult = ReturnType<
  typeof useGetOrganizationQuery
>;
export type GetOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationLazyQuery
>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const EditOrganizationDocument = gql`
  mutation EditOrganization($input: InputEditOrganization!) {
    editOrganization(input: $input) {
      id
      name
      email
      address
      taxNumber
      monthlyPayments
      isActive
    }
  }
`;
export type EditOrganizationMutationFn = Apollo.MutationFunction<
  EditOrganizationMutation,
  EditOrganizationMutationVariables
>;

/**
 * __useEditOrganizationMutation__
 *
 * To run a mutation, you first call `useEditOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationMutation, { data, loading, error }] = useEditOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditOrganizationMutation,
    EditOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditOrganizationMutation,
    EditOrganizationMutationVariables
  >(EditOrganizationDocument, options);
}
export type EditOrganizationMutationHookResult = ReturnType<
  typeof useEditOrganizationMutation
>;
export type EditOrganizationMutationResult =
  Apollo.MutationResult<EditOrganizationMutation>;
export type EditOrganizationMutationOptions = Apollo.BaseMutationOptions<
  EditOrganizationMutation,
  EditOrganizationMutationVariables
>;
export const AddOrganizationDocument = gql`
  mutation AddOrganization($input: InputAddOrganization!) {
    addOrganization(input: $input) {
      id
      name
      email
      address
      postalCode
      taxNumber
      monthlyPayments
      isActive
    }
  }
`;
export type AddOrganizationMutationFn = Apollo.MutationFunction<
  AddOrganizationMutation,
  AddOrganizationMutationVariables
>;

/**
 * __useAddOrganizationMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMutation, { data, loading, error }] = useAddOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrganizationMutation,
    AddOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddOrganizationMutation,
    AddOrganizationMutationVariables
  >(AddOrganizationDocument, options);
}
export type AddOrganizationMutationHookResult = ReturnType<
  typeof useAddOrganizationMutation
>;
export type AddOrganizationMutationResult =
  Apollo.MutationResult<AddOrganizationMutation>;
export type AddOrganizationMutationOptions = Apollo.BaseMutationOptions<
  AddOrganizationMutation,
  AddOrganizationMutationVariables
>;
export const RemoveOrgUserDocument = gql`
  mutation RemoveOrgUser($userId: Int!, $orgId: Int!) {
    removeOrgUser(userId: $userId, orgId: $orgId) {
      id
    }
  }
`;
export type RemoveOrgUserMutationFn = Apollo.MutationFunction<
  RemoveOrgUserMutation,
  RemoveOrgUserMutationVariables
>;

/**
 * __useRemoveOrgUserMutation__
 *
 * To run a mutation, you first call `useRemoveOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgUserMutation, { data, loading, error }] = useRemoveOrgUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useRemoveOrgUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrgUserMutation,
    RemoveOrgUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOrgUserMutation,
    RemoveOrgUserMutationVariables
  >(RemoveOrgUserDocument, options);
}
export type RemoveOrgUserMutationHookResult = ReturnType<
  typeof useRemoveOrgUserMutation
>;
export type RemoveOrgUserMutationResult =
  Apollo.MutationResult<RemoveOrgUserMutation>;
export type RemoveOrgUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrgUserMutation,
  RemoveOrgUserMutationVariables
>;
export const AddOrgUserDocument = gql`
  mutation AddOrgUser($input: InputAddOrgUser!) {
    addOrgUser(input: $input) {
      userId
    }
  }
`;
export type AddOrgUserMutationFn = Apollo.MutationFunction<
  AddOrgUserMutation,
  AddOrgUserMutationVariables
>;

/**
 * __useAddOrgUserMutation__
 *
 * To run a mutation, you first call `useAddOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrgUserMutation, { data, loading, error }] = useAddOrgUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrgUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrgUserMutation,
    AddOrgUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrgUserMutation, AddOrgUserMutationVariables>(
    AddOrgUserDocument,
    options,
  );
}
export type AddOrgUserMutationHookResult = ReturnType<
  typeof useAddOrgUserMutation
>;
export type AddOrgUserMutationResult =
  Apollo.MutationResult<AddOrgUserMutation>;
export type AddOrgUserMutationOptions = Apollo.BaseMutationOptions<
  AddOrgUserMutation,
  AddOrgUserMutationVariables
>;
export const EditOrgUserDocument = gql`
  mutation EditOrgUser($input: InputEditOrgUser!) {
    editOrgUser(input: $input) {
      id
      name
      isAdmin
    }
  }
`;
export type EditOrgUserMutationFn = Apollo.MutationFunction<
  EditOrgUserMutation,
  EditOrgUserMutationVariables
>;

/**
 * __useEditOrgUserMutation__
 *
 * To run a mutation, you first call `useEditOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgUserMutation, { data, loading, error }] = useEditOrgUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditOrgUserMutation,
    EditOrgUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditOrgUserMutation, EditOrgUserMutationVariables>(
    EditOrgUserDocument,
    options,
  );
}
export type EditOrgUserMutationHookResult = ReturnType<
  typeof useEditOrgUserMutation
>;
export type EditOrgUserMutationResult =
  Apollo.MutationResult<EditOrgUserMutation>;
export type EditOrgUserMutationOptions = Apollo.BaseMutationOptions<
  EditOrgUserMutation,
  EditOrgUserMutationVariables
>;
export const SetOrgDiscountDocument = gql`
  mutation SetOrgDiscount(
    $fleetId: Int!
    $organizationId: Int!
    $discount: Int!
  ) {
    setOrganizationCoupon(
      input: {
        fleetId: $fleetId
        organizationId: $organizationId
        discount: $discount
      }
    ) {
      id
      amountRemaining
      currency
      couponType {
        id
        unit
      }
    }
  }
`;
export type SetOrgDiscountMutationFn = Apollo.MutationFunction<
  SetOrgDiscountMutation,
  SetOrgDiscountMutationVariables
>;

/**
 * __useSetOrgDiscountMutation__
 *
 * To run a mutation, you first call `useSetOrgDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgDiscountMutation, { data, loading, error }] = useSetOrgDiscountMutation({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      organizationId: // value for 'organizationId'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useSetOrgDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOrgDiscountMutation,
    SetOrgDiscountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOrgDiscountMutation,
    SetOrgDiscountMutationVariables
  >(SetOrgDiscountDocument, options);
}
export type SetOrgDiscountMutationHookResult = ReturnType<
  typeof useSetOrgDiscountMutation
>;
export type SetOrgDiscountMutationResult =
  Apollo.MutationResult<SetOrgDiscountMutation>;
export type SetOrgDiscountMutationOptions = Apollo.BaseMutationOptions<
  SetOrgDiscountMutation,
  SetOrgDiscountMutationVariables
>;
export const OrganizationsDocument = gql`
  query Organizations(
    $fleetId: Int
    $sortColumn: String
    $sortOrder: SortOrder
    $cursor: String
    $limit: Int
    $search: String
  ) {
    organizations(
      fleetId: $fleetId
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      search: $search
      cursor: $cursor
      limit: $limit
    ) {
      organizations {
        id
        name
        isActive
        hasDebt
        monthlyPayments
        userCount
        createdAt
        taxNumber
        email
        coupons {
          id
          currency
          amountRemaining
          couponType {
            unit
          }
        }
      }
      pageInfo {
        remaining
        hasNextPage
        cursor
      }
    }
  }
`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      sortColumn: // value for 'sortColumn'
 *      sortOrder: // value for 'sortOrder'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options,
  );
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options,
  );
}
export type OrganizationsQueryHookResult = ReturnType<
  typeof useOrganizationsQuery
>;
export type OrganizationsLazyQueryHookResult = ReturnType<
  typeof useOrganizationsLazyQuery
>;
export type OrganizationsQueryResult = Apollo.QueryResult<
  OrganizationsQuery,
  OrganizationsQueryVariables
>;
export const RegionDocument = gql`
  query Region(
    $regionId: Int!
    $canQueryFleets: Boolean!
    $canQueryRegistrationCampaigns: Boolean!
  ) {
    region(regionId: $regionId) {
      id
      name
      latitude
      longitude
      timezone
      requiredLanguages
      countryCode
      slug
      status
      fleets @include(if: $canQueryFleets) {
        id
        name
        isPublic
      }
      registrationCampaigns @include(if: $canQueryRegistrationCampaigns) {
        id
        from
        to
        freeRides
        couponGroupId
      }
    }
  }
`;

/**
 * __useRegionQuery__
 *
 * To run a query within a React component, call `useRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      canQueryFleets: // value for 'canQueryFleets'
 *      canQueryRegistrationCampaigns: // value for 'canQueryRegistrationCampaigns'
 *   },
 * });
 */
export function useRegionQuery(
  baseOptions: Apollo.QueryHookOptions<RegionQuery, RegionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionQuery, RegionQueryVariables>(
    RegionDocument,
    options,
  );
}
export function useRegionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionQuery, RegionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionQuery, RegionQueryVariables>(
    RegionDocument,
    options,
  );
}
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export type RegionQueryResult = Apollo.QueryResult<
  RegionQuery,
  RegionQueryVariables
>;
export const CreateRegistrationCampaignDocument = gql`
  mutation createRegistrationCampaign(
    $regionId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $freeRides: Int
  ) {
    createRegistrationCampaign(
      regionId: $regionId
      startDate: $startDate
      endDate: $endDate
      freeRides: $freeRides
    ) {
      id
      from
      to
      freeRides
      couponGroupId
    }
  }
`;
export type CreateRegistrationCampaignMutationFn = Apollo.MutationFunction<
  CreateRegistrationCampaignMutation,
  CreateRegistrationCampaignMutationVariables
>;

/**
 * __useCreateRegistrationCampaignMutation__
 *
 * To run a mutation, you first call `useCreateRegistrationCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistrationCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistrationCampaignMutation, { data, loading, error }] = useCreateRegistrationCampaignMutation({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      freeRides: // value for 'freeRides'
 *   },
 * });
 */
export function useCreateRegistrationCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRegistrationCampaignMutation,
    CreateRegistrationCampaignMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRegistrationCampaignMutation,
    CreateRegistrationCampaignMutationVariables
  >(CreateRegistrationCampaignDocument, options);
}
export type CreateRegistrationCampaignMutationHookResult = ReturnType<
  typeof useCreateRegistrationCampaignMutation
>;
export type CreateRegistrationCampaignMutationResult =
  Apollo.MutationResult<CreateRegistrationCampaignMutation>;
export type CreateRegistrationCampaignMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRegistrationCampaignMutation,
    CreateRegistrationCampaignMutationVariables
  >;
export const RemoteSerialVehicleDocument = gql`
  query RemoteSerialVehicle($deviceId: String!) {
    vehicle(deviceId: $deviceId) {
      id
      code
      batteryLevel
      lastPosition
      longitude
      latitude
      vehicleType {
        vehicleName
      }
    }
  }
`;

/**
 * __useRemoteSerialVehicleQuery__
 *
 * To run a query within a React component, call `useRemoteSerialVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoteSerialVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoteSerialVehicleQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useRemoteSerialVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemoteSerialVehicleQuery,
    RemoteSerialVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RemoteSerialVehicleQuery,
    RemoteSerialVehicleQueryVariables
  >(RemoteSerialVehicleDocument, options);
}
export function useRemoteSerialVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemoteSerialVehicleQuery,
    RemoteSerialVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RemoteSerialVehicleQuery,
    RemoteSerialVehicleQueryVariables
  >(RemoteSerialVehicleDocument, options);
}
export type RemoteSerialVehicleQueryHookResult = ReturnType<
  typeof useRemoteSerialVehicleQuery
>;
export type RemoteSerialVehicleLazyQueryHookResult = ReturnType<
  typeof useRemoteSerialVehicleLazyQuery
>;
export type RemoteSerialVehicleQueryResult = Apollo.QueryResult<
  RemoteSerialVehicleQuery,
  RemoteSerialVehicleQueryVariables
>;
export const ToggleVehicleLockByDeviceIdDocument = gql`
  mutation ToggleVehicleLockByDeviceId($deviceId: String!, $lock: Boolean!) {
    toggleVehicleLockByDeviceId(deviceId: $deviceId, lock: $lock)
  }
`;
export type ToggleVehicleLockByDeviceIdMutationFn = Apollo.MutationFunction<
  ToggleVehicleLockByDeviceIdMutation,
  ToggleVehicleLockByDeviceIdMutationVariables
>;

/**
 * __useToggleVehicleLockByDeviceIdMutation__
 *
 * To run a mutation, you first call `useToggleVehicleLockByDeviceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleVehicleLockByDeviceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleVehicleLockByDeviceIdMutation, { data, loading, error }] = useToggleVehicleLockByDeviceIdMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      lock: // value for 'lock'
 *   },
 * });
 */
export function useToggleVehicleLockByDeviceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleVehicleLockByDeviceIdMutation,
    ToggleVehicleLockByDeviceIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleVehicleLockByDeviceIdMutation,
    ToggleVehicleLockByDeviceIdMutationVariables
  >(ToggleVehicleLockByDeviceIdDocument, options);
}
export type ToggleVehicleLockByDeviceIdMutationHookResult = ReturnType<
  typeof useToggleVehicleLockByDeviceIdMutation
>;
export type ToggleVehicleLockByDeviceIdMutationResult =
  Apollo.MutationResult<ToggleVehicleLockByDeviceIdMutation>;
export type ToggleVehicleLockByDeviceIdMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleVehicleLockByDeviceIdMutation,
    ToggleVehicleLockByDeviceIdMutationVariables
  >;
export const DemoModeCodeDocument = gql`
  query DemoModeCode($imei: String!) {
    demoModeCode(imei: $imei)
  }
`;

/**
 * __useDemoModeCodeQuery__
 *
 * To run a query within a React component, call `useDemoModeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoModeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoModeCodeQuery({
 *   variables: {
 *      imei: // value for 'imei'
 *   },
 * });
 */
export function useDemoModeCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    DemoModeCodeQuery,
    DemoModeCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemoModeCodeQuery, DemoModeCodeQueryVariables>(
    DemoModeCodeDocument,
    options,
  );
}
export function useDemoModeCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoModeCodeQuery,
    DemoModeCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DemoModeCodeQuery, DemoModeCodeQueryVariables>(
    DemoModeCodeDocument,
    options,
  );
}
export type DemoModeCodeQueryHookResult = ReturnType<
  typeof useDemoModeCodeQuery
>;
export type DemoModeCodeLazyQueryHookResult = ReturnType<
  typeof useDemoModeCodeLazyQuery
>;
export type DemoModeCodeQueryResult = Apollo.QueryResult<
  DemoModeCodeQuery,
  DemoModeCodeQueryVariables
>;
export const VehicleByImeiDocument = gql`
  query VehicleByIMEI($deviceId: String!) {
    vehicle(deviceId: $deviceId) {
      id
      code
      batteryLevel
    }
  }
`;

/**
 * __useVehicleByImeiQuery__
 *
 * To run a query within a React component, call `useVehicleByImeiQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleByImeiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleByImeiQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useVehicleByImeiQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleByImeiQuery,
    VehicleByImeiQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleByImeiQuery, VehicleByImeiQueryVariables>(
    VehicleByImeiDocument,
    options,
  );
}
export function useVehicleByImeiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleByImeiQuery,
    VehicleByImeiQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleByImeiQuery, VehicleByImeiQueryVariables>(
    VehicleByImeiDocument,
    options,
  );
}
export type VehicleByImeiQueryHookResult = ReturnType<
  typeof useVehicleByImeiQuery
>;
export type VehicleByImeiLazyQueryHookResult = ReturnType<
  typeof useVehicleByImeiLazyQuery
>;
export type VehicleByImeiQueryResult = Apollo.QueryResult<
  VehicleByImeiQuery,
  VehicleByImeiQueryVariables
>;
export const ToggleIotModeDocument = gql`
  mutation ToggleIotMode($vehicleId: Int!, $mode: VehicleIotMode!) {
    toggleIotMode(vehicleId: $vehicleId, mode: $mode)
  }
`;
export type ToggleIotModeMutationFn = Apollo.MutationFunction<
  ToggleIotModeMutation,
  ToggleIotModeMutationVariables
>;

/**
 * __useToggleIotModeMutation__
 *
 * To run a mutation, you first call `useToggleIotModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIotModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIotModeMutation, { data, loading, error }] = useToggleIotModeMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useToggleIotModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleIotModeMutation,
    ToggleIotModeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleIotModeMutation,
    ToggleIotModeMutationVariables
  >(ToggleIotModeDocument, options);
}
export type ToggleIotModeMutationHookResult = ReturnType<
  typeof useToggleIotModeMutation
>;
export type ToggleIotModeMutationResult =
  Apollo.MutationResult<ToggleIotModeMutation>;
export type ToggleIotModeMutationOptions = Apollo.BaseMutationOptions<
  ToggleIotModeMutation,
  ToggleIotModeMutationVariables
>;
export const LocateVehicleDocument = gql`
  mutation LocateVehicle($vehicleId: Int!) {
    locateVehicle(vehicleId: $vehicleId) {
      id
    }
  }
`;
export type LocateVehicleMutationFn = Apollo.MutationFunction<
  LocateVehicleMutation,
  LocateVehicleMutationVariables
>;

/**
 * __useLocateVehicleMutation__
 *
 * To run a mutation, you first call `useLocateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locateVehicleMutation, { data, loading, error }] = useLocateVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useLocateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LocateVehicleMutation,
    LocateVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LocateVehicleMutation,
    LocateVehicleMutationVariables
  >(LocateVehicleDocument, options);
}
export type LocateVehicleMutationHookResult = ReturnType<
  typeof useLocateVehicleMutation
>;
export type LocateVehicleMutationResult =
  Apollo.MutationResult<LocateVehicleMutation>;
export type LocateVehicleMutationOptions = Apollo.BaseMutationOptions<
  LocateVehicleMutation,
  LocateVehicleMutationVariables
>;
export const AllIssuesAndRepairsDocument = gql`
  query AllIssuesAndRepairs(
    $fleetId: Int!
    $vehicleId: Int
    $repairedOnly: Boolean
    $limit: Int
    $cursor: String
  ) {
    allIssues(
      fleetId: $fleetId
      vehicleId: $vehicleId
      repairedOnly: $repairedOnly
      limit: $limit
      cursor: $cursor
    ) {
      issues {
        id
        comment
        createdAt
        vehicleId
        vehicle {
          code
        }
        tags {
          name
        }
        repair {
          fixedBy {
            name
          }
          fixedAt
          realIssueType {
            key
          }
          comment
          parts {
            name
          }
        }
        issueType {
          id
          key
        }
      }
      pageInfo {
        cursor
        remaining
        hasNextPage
      }
    }
  }
`;

/**
 * __useAllIssuesAndRepairsQuery__
 *
 * To run a query within a React component, call `useAllIssuesAndRepairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIssuesAndRepairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIssuesAndRepairsQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      vehicleId: // value for 'vehicleId'
 *      repairedOnly: // value for 'repairedOnly'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAllIssuesAndRepairsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllIssuesAndRepairsQuery,
    AllIssuesAndRepairsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllIssuesAndRepairsQuery,
    AllIssuesAndRepairsQueryVariables
  >(AllIssuesAndRepairsDocument, options);
}
export function useAllIssuesAndRepairsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllIssuesAndRepairsQuery,
    AllIssuesAndRepairsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllIssuesAndRepairsQuery,
    AllIssuesAndRepairsQueryVariables
  >(AllIssuesAndRepairsDocument, options);
}
export type AllIssuesAndRepairsQueryHookResult = ReturnType<
  typeof useAllIssuesAndRepairsQuery
>;
export type AllIssuesAndRepairsLazyQueryHookResult = ReturnType<
  typeof useAllIssuesAndRepairsLazyQuery
>;
export type AllIssuesAndRepairsQueryResult = Apollo.QueryResult<
  AllIssuesAndRepairsQuery,
  AllIssuesAndRepairsQueryVariables
>;
export const StaffShiftsAggregateByUserDocument = gql`
  query StaffShiftsAggregateByUser(
    $fleetId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    staffShiftsAggregateByUser(
      fleetId: $fleetId
      startDate: $startDate
      endDate: $endDate
    ) {
      duration
      batteriesReplaced
      employee {
        name
        phone
      }
    }
  }
`;

/**
 * __useStaffShiftsAggregateByUserQuery__
 *
 * To run a query within a React component, call `useStaffShiftsAggregateByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffShiftsAggregateByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffShiftsAggregateByUserQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useStaffShiftsAggregateByUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    StaffShiftsAggregateByUserQuery,
    StaffShiftsAggregateByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StaffShiftsAggregateByUserQuery,
    StaffShiftsAggregateByUserQueryVariables
  >(StaffShiftsAggregateByUserDocument, options);
}
export function useStaffShiftsAggregateByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StaffShiftsAggregateByUserQuery,
    StaffShiftsAggregateByUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StaffShiftsAggregateByUserQuery,
    StaffShiftsAggregateByUserQueryVariables
  >(StaffShiftsAggregateByUserDocument, options);
}
export type StaffShiftsAggregateByUserQueryHookResult = ReturnType<
  typeof useStaffShiftsAggregateByUserQuery
>;
export type StaffShiftsAggregateByUserLazyQueryHookResult = ReturnType<
  typeof useStaffShiftsAggregateByUserLazyQuery
>;
export type StaffShiftsAggregateByUserQueryResult = Apollo.QueryResult<
  StaffShiftsAggregateByUserQuery,
  StaffShiftsAggregateByUserQueryVariables
>;
export const DriverAccountingPeriodsDocument = gql`
  query DriverAccountingPeriods(
    $periodStart: DateTime!
    $periodEnd: DateTime!
    $fleetId: Int!
  ) {
    driverAccountingPeriods(
      periodStart: $periodStart
      periodEnd: $periodEnd
      fleetId: $fleetId
    ) {
      id
      xeroInvoiceId
      startTime
      endTime
      amountDue
      adjustedAmountDue
      currency
      driver {
        id
        name
      }
      owner {
        id
        name
      }
      taxiVehicle {
        id
        licensePlate
      }
    }
  }
`;

/**
 * __useDriverAccountingPeriodsQuery__
 *
 * To run a query within a React component, call `useDriverAccountingPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverAccountingPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverAccountingPeriodsQuery({
 *   variables: {
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useDriverAccountingPeriodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DriverAccountingPeriodsQuery,
    DriverAccountingPeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DriverAccountingPeriodsQuery,
    DriverAccountingPeriodsQueryVariables
  >(DriverAccountingPeriodsDocument, options);
}
export function useDriverAccountingPeriodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriverAccountingPeriodsQuery,
    DriverAccountingPeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DriverAccountingPeriodsQuery,
    DriverAccountingPeriodsQueryVariables
  >(DriverAccountingPeriodsDocument, options);
}
export type DriverAccountingPeriodsQueryHookResult = ReturnType<
  typeof useDriverAccountingPeriodsQuery
>;
export type DriverAccountingPeriodsLazyQueryHookResult = ReturnType<
  typeof useDriverAccountingPeriodsLazyQuery
>;
export type DriverAccountingPeriodsQueryResult = Apollo.QueryResult<
  DriverAccountingPeriodsQuery,
  DriverAccountingPeriodsQueryVariables
>;
export const MapDocument = gql`
  query map($filter: TaxiVehicleFilterInput) {
    allTaxiVehicles(first: 100, filter: $filter) {
      edges {
        node {
          id
          online
          passengerCapacity
          position {
            id
            coordinates {
              latitude
              longitude
            }
            heading
            speed
          }
        }
      }
    }
    allTaxiTrips(first: 100) {
      edges {
        node {
          id
          pickupCoordinate {
            latitude
            longitude
          }
          dropoffCoordinate {
            latitude
            longitude
          }
          quote {
            id
          }
          passengerCount
          startedAt
          endedAt
          settledAt
          createdAt
          lastProcessedAt
          departure
          reason
          state
        }
      }
    }
  }
`;

/**
 * __useMapQuery__
 *
 * To run a query within a React component, call `useMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMapQuery(
  baseOptions?: Apollo.QueryHookOptions<MapQuery, MapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MapQuery, MapQueryVariables>(MapDocument, options);
}
export function useMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MapQuery, MapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MapQuery, MapQueryVariables>(MapDocument, options);
}
export type MapQueryHookResult = ReturnType<typeof useMapQuery>;
export type MapLazyQueryHookResult = ReturnType<typeof useMapLazyQuery>;
export type MapQueryResult = Apollo.QueryResult<MapQuery, MapQueryVariables>;
export const TaxiTripMarkerDocument = gql`
  query TaxiTripMarker($taxiTripId: ID!) {
    TaxiTrip(id: $taxiTripId) {
      id
      quote {
        id
        route
      }
      rider {
        name
        phone
      }
    }
  }
`;

/**
 * __useTaxiTripMarkerQuery__
 *
 * To run a query within a React component, call `useTaxiTripMarkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxiTripMarkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxiTripMarkerQuery({
 *   variables: {
 *      taxiTripId: // value for 'taxiTripId'
 *   },
 * });
 */
export function useTaxiTripMarkerQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaxiTripMarkerQuery,
    TaxiTripMarkerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxiTripMarkerQuery, TaxiTripMarkerQueryVariables>(
    TaxiTripMarkerDocument,
    options,
  );
}
export function useTaxiTripMarkerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxiTripMarkerQuery,
    TaxiTripMarkerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxiTripMarkerQuery, TaxiTripMarkerQueryVariables>(
    TaxiTripMarkerDocument,
    options,
  );
}
export type TaxiTripMarkerQueryHookResult = ReturnType<
  typeof useTaxiTripMarkerQuery
>;
export type TaxiTripMarkerLazyQueryHookResult = ReturnType<
  typeof useTaxiTripMarkerLazyQuery
>;
export type TaxiTripMarkerQueryResult = Apollo.QueryResult<
  TaxiTripMarkerQuery,
  TaxiTripMarkerQueryVariables
>;
export const TaxiTripPanelDocument = gql`
  query TaxiTripPanel($taxiTripId: ID!) {
    TaxiTrip(id: $taxiTripId) {
      id
      startedAt
      endedAt
      createdAt
      acceptedAt
      settledAt
      quote {
        id
        route
        distance
        duration
        options {
          fleet {
            id
          }
          total
          earning
          currency
          eta
        }
        createdAt
      }
      rider {
        name
        phone
      }
      fleet {
        id
        name
      }
      vehicle {
        id
      }
      pickupAddress {
        name
        streetName
        houseNumber
        city
        postalCode
      }
      dropoffAddress {
        name
        streetName
        houseNumber
        city
        postalCode
      }
    }
  }
`;

/**
 * __useTaxiTripPanelQuery__
 *
 * To run a query within a React component, call `useTaxiTripPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxiTripPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxiTripPanelQuery({
 *   variables: {
 *      taxiTripId: // value for 'taxiTripId'
 *   },
 * });
 */
export function useTaxiTripPanelQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaxiTripPanelQuery,
    TaxiTripPanelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxiTripPanelQuery, TaxiTripPanelQueryVariables>(
    TaxiTripPanelDocument,
    options,
  );
}
export function useTaxiTripPanelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxiTripPanelQuery,
    TaxiTripPanelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxiTripPanelQuery, TaxiTripPanelQueryVariables>(
    TaxiTripPanelDocument,
    options,
  );
}
export type TaxiTripPanelQueryHookResult = ReturnType<
  typeof useTaxiTripPanelQuery
>;
export type TaxiTripPanelLazyQueryHookResult = ReturnType<
  typeof useTaxiTripPanelLazyQuery
>;
export type TaxiTripPanelQueryResult = Apollo.QueryResult<
  TaxiTripPanelQuery,
  TaxiTripPanelQueryVariables
>;
export const SelectedTaxiVehicleDocument = gql`
  query SelectedTaxiVehicle($taxiVehicleId: ID!) {
    TaxiVehicle(id: $taxiVehicleId) {
      id
      online
      passengerCapacity
      licensePlate
      state
      driver {
        id
        name
        phoneNumber
      }
      fleets {
        fleet {
          id
          name
          description
          maximumPassengers
          pool
        }
      }
      info {
        id
        make
        model
        year
      }
      steps(rider: false) {
        id
        estimatedCompleteAt
        address {
          streetName
          houseNumber
        }
        route
        type
      }
    }
  }
`;

/**
 * __useSelectedTaxiVehicleQuery__
 *
 * To run a query within a React component, call `useSelectedTaxiVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedTaxiVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedTaxiVehicleQuery({
 *   variables: {
 *      taxiVehicleId: // value for 'taxiVehicleId'
 *   },
 * });
 */
export function useSelectedTaxiVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<
    SelectedTaxiVehicleQuery,
    SelectedTaxiVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SelectedTaxiVehicleQuery,
    SelectedTaxiVehicleQueryVariables
  >(SelectedTaxiVehicleDocument, options);
}
export function useSelectedTaxiVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SelectedTaxiVehicleQuery,
    SelectedTaxiVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SelectedTaxiVehicleQuery,
    SelectedTaxiVehicleQueryVariables
  >(SelectedTaxiVehicleDocument, options);
}
export type SelectedTaxiVehicleQueryHookResult = ReturnType<
  typeof useSelectedTaxiVehicleQuery
>;
export type SelectedTaxiVehicleLazyQueryHookResult = ReturnType<
  typeof useSelectedTaxiVehicleLazyQuery
>;
export type SelectedTaxiVehicleQueryResult = Apollo.QueryResult<
  SelectedTaxiVehicleQuery,
  SelectedTaxiVehicleQueryVariables
>;
export const DriverDocument = gql`
  query Driver($driverId: ID!) {
    Driver(id: $driverId) {
      id
      name
      phoneNumber
      profileImageUrl
      email
      nationalId
      bankAccount
      activeVehicleId
      permitType
      vehicles {
        id
        online
        passengerCapacity
        licensePlate
        approvedAt
        info {
          id
          make
          model
          color
          registeredPlate
          vin
          year
          weight
          status
          nextInspection
        }
        fleets {
          id
          active
          fleet {
            id
            name
            description
            pool
          }
        }
      }
      approvedAt
    }
  }
`;

/**
 * __useDriverQuery__
 *
 * To run a query within a React component, call `useDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useDriverQuery(
  baseOptions: Apollo.QueryHookOptions<DriverQuery, DriverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriverQuery, DriverQueryVariables>(
    DriverDocument,
    options,
  );
}
export function useDriverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DriverQuery, DriverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriverQuery, DriverQueryVariables>(
    DriverDocument,
    options,
  );
}
export type DriverQueryHookResult = ReturnType<typeof useDriverQuery>;
export type DriverLazyQueryHookResult = ReturnType<typeof useDriverLazyQuery>;
export type DriverQueryResult = Apollo.QueryResult<
  DriverQuery,
  DriverQueryVariables
>;
export const UpdateDriverDetailsDocument = gql`
  mutation UpdateDriverDetails($input: UpdateDriverInput!, $driverId: ID!) {
    updateDriverDetails(input: $input, driverId: $driverId) {
      id
      name
      phoneNumber
      email
      nationalId
      bankAccount
      permitType
    }
  }
`;
export type UpdateDriverDetailsMutationFn = Apollo.MutationFunction<
  UpdateDriverDetailsMutation,
  UpdateDriverDetailsMutationVariables
>;

/**
 * __useUpdateDriverDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateDriverDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverDetailsMutation, { data, loading, error }] = useUpdateDriverDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useUpdateDriverDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDriverDetailsMutation,
    UpdateDriverDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDriverDetailsMutation,
    UpdateDriverDetailsMutationVariables
  >(UpdateDriverDetailsDocument, options);
}
export type UpdateDriverDetailsMutationHookResult = ReturnType<
  typeof useUpdateDriverDetailsMutation
>;
export type UpdateDriverDetailsMutationResult =
  Apollo.MutationResult<UpdateDriverDetailsMutation>;
export type UpdateDriverDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverDetailsMutation,
  UpdateDriverDetailsMutationVariables
>;
export const UpdateDriverApprovalDocument = gql`
  mutation UpdateDriverApproval($approved: Boolean!, $driverId: ID!) {
    updateDriverApproval(approved: $approved, driverId: $driverId) {
      id
      approvedAt
    }
  }
`;
export type UpdateDriverApprovalMutationFn = Apollo.MutationFunction<
  UpdateDriverApprovalMutation,
  UpdateDriverApprovalMutationVariables
>;

/**
 * __useUpdateDriverApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateDriverApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverApprovalMutation, { data, loading, error }] = useUpdateDriverApprovalMutation({
 *   variables: {
 *      approved: // value for 'approved'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useUpdateDriverApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDriverApprovalMutation,
    UpdateDriverApprovalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDriverApprovalMutation,
    UpdateDriverApprovalMutationVariables
  >(UpdateDriverApprovalDocument, options);
}
export type UpdateDriverApprovalMutationHookResult = ReturnType<
  typeof useUpdateDriverApprovalMutation
>;
export type UpdateDriverApprovalMutationResult =
  Apollo.MutationResult<UpdateDriverApprovalMutation>;
export type UpdateDriverApprovalMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverApprovalMutation,
  UpdateDriverApprovalMutationVariables
>;
export const AllTaxiDrivesDocument = gql`
  query AllTaxiDrives(
    $first: Int
    $after: String
    $isApproved: Boolean
    $isPending: Boolean
  ) {
    allTaxiDrivers(
      first: $first
      after: $after
      isApproved: $isApproved
      isPending: $isPending
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        cursor
        node {
          id
          name
          phoneNumber
          email
          nationalId
          approvedAt
          permitType
          createdAt
        }
      }
    }
  }
`;

/**
 * __useAllTaxiDrivesQuery__
 *
 * To run a query within a React component, call `useAllTaxiDrivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaxiDrivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaxiDrivesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      isApproved: // value for 'isApproved'
 *      isPending: // value for 'isPending'
 *   },
 * });
 */
export function useAllTaxiDrivesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllTaxiDrivesQuery,
    AllTaxiDrivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTaxiDrivesQuery, AllTaxiDrivesQueryVariables>(
    AllTaxiDrivesDocument,
    options,
  );
}
export function useAllTaxiDrivesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTaxiDrivesQuery,
    AllTaxiDrivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTaxiDrivesQuery, AllTaxiDrivesQueryVariables>(
    AllTaxiDrivesDocument,
    options,
  );
}
export type AllTaxiDrivesQueryHookResult = ReturnType<
  typeof useAllTaxiDrivesQuery
>;
export type AllTaxiDrivesLazyQueryHookResult = ReturnType<
  typeof useAllTaxiDrivesLazyQuery
>;
export type AllTaxiDrivesQueryResult = Apollo.QueryResult<
  AllTaxiDrivesQuery,
  AllTaxiDrivesQueryVariables
>;
export const CreateVehicleDocument = gql`
  mutation CreateVehicle($driverId: ID, $licensePlate: String!) {
    createVehicle(driverId: $driverId, licensePlate: $licensePlate) {
      id
      info {
        make
        model
        id
        year
        nextInspection
      }
      licensePlate
    }
  }
`;
export type CreateVehicleMutationFn = Apollo.MutationFunction<
  CreateVehicleMutation,
  CreateVehicleMutationVariables
>;

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      licensePlate: // value for 'licensePlate'
 *   },
 * });
 */
export function useCreateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVehicleMutation,
    CreateVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVehicleMutation,
    CreateVehicleMutationVariables
  >(CreateVehicleDocument, options);
}
export type CreateVehicleMutationHookResult = ReturnType<
  typeof useCreateVehicleMutation
>;
export type CreateVehicleMutationResult =
  Apollo.MutationResult<CreateVehicleMutation>;
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<
  CreateVehicleMutation,
  CreateVehicleMutationVariables
>;
export const RemoveVehicleDocument = gql`
  mutation RemoveVehicle($removeVehicleId: ID!) {
    removeVehicle(id: $removeVehicleId)
  }
`;
export type RemoveVehicleMutationFn = Apollo.MutationFunction<
  RemoveVehicleMutation,
  RemoveVehicleMutationVariables
>;

/**
 * __useRemoveVehicleMutation__
 *
 * To run a mutation, you first call `useRemoveVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVehicleMutation, { data, loading, error }] = useRemoveVehicleMutation({
 *   variables: {
 *      removeVehicleId: // value for 'removeVehicleId'
 *   },
 * });
 */
export function useRemoveVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVehicleMutation,
    RemoveVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveVehicleMutation,
    RemoveVehicleMutationVariables
  >(RemoveVehicleDocument, options);
}
export type RemoveVehicleMutationHookResult = ReturnType<
  typeof useRemoveVehicleMutation
>;
export type RemoveVehicleMutationResult =
  Apollo.MutationResult<RemoveVehicleMutation>;
export type RemoveVehicleMutationOptions = Apollo.BaseMutationOptions<
  RemoveVehicleMutation,
  RemoveVehicleMutationVariables
>;
export const SetActiveVehicleDocument = gql`
  mutation SetActiveVehicle($driverId: ID, $vehicleId: ID!) {
    setActiveVehicle(driverId: $driverId, vehicleId: $vehicleId) {
      id
    }
  }
`;
export type SetActiveVehicleMutationFn = Apollo.MutationFunction<
  SetActiveVehicleMutation,
  SetActiveVehicleMutationVariables
>;

/**
 * __useSetActiveVehicleMutation__
 *
 * To run a mutation, you first call `useSetActiveVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveVehicleMutation, { data, loading, error }] = useSetActiveVehicleMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useSetActiveVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetActiveVehicleMutation,
    SetActiveVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetActiveVehicleMutation,
    SetActiveVehicleMutationVariables
  >(SetActiveVehicleDocument, options);
}
export type SetActiveVehicleMutationHookResult = ReturnType<
  typeof useSetActiveVehicleMutation
>;
export type SetActiveVehicleMutationResult =
  Apollo.MutationResult<SetActiveVehicleMutation>;
export type SetActiveVehicleMutationOptions = Apollo.BaseMutationOptions<
  SetActiveVehicleMutation,
  SetActiveVehicleMutationVariables
>;
export const TaxiTripDocument = gql`
  query TaxiTrip(
    $taxiTripId: ID!
    $includeInvoiceIssuedFines: Boolean = false
  ) {
    TaxiTrip(id: $taxiTripId) {
      id
      startedAt
      endedAt
      createdAt
      acceptedAt
      settledAt
      state
      quote {
        id
        route
        distance
        duration
        options {
          fleet {
            id
          }
          total
          earning
          currency
          eta
        }
        createdAt
      }
      rider {
        name
        phone
      }
      fleet {
        id
        name
      }
      vehicle {
        id
        licensePlate
        driver {
          id
          name
        }
      }
      pickupAddress {
        name
        streetName
        houseNumber
        city
        postalCode
      }
      dropoffAddress {
        name
        streetName
        houseNumber
        city
        postalCode
      }
      invoice {
        org {
          id
          name
        }
        payment {
          card {
            id
            lastDigits
            provider
          }
        }
        ...Invoice
      }
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useTaxiTripQuery__
 *
 * To run a query within a React component, call `useTaxiTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxiTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxiTripQuery({
 *   variables: {
 *      taxiTripId: // value for 'taxiTripId'
 *      includeInvoiceIssuedFines: // value for 'includeInvoiceIssuedFines'
 *   },
 * });
 */
export function useTaxiTripQuery(
  baseOptions: Apollo.QueryHookOptions<TaxiTripQuery, TaxiTripQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxiTripQuery, TaxiTripQueryVariables>(
    TaxiTripDocument,
    options,
  );
}
export function useTaxiTripLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxiTripQuery,
    TaxiTripQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxiTripQuery, TaxiTripQueryVariables>(
    TaxiTripDocument,
    options,
  );
}
export type TaxiTripQueryHookResult = ReturnType<typeof useTaxiTripQuery>;
export type TaxiTripLazyQueryHookResult = ReturnType<
  typeof useTaxiTripLazyQuery
>;
export type TaxiTripQueryResult = Apollo.QueryResult<
  TaxiTripQuery,
  TaxiTripQueryVariables
>;
export const AllTaxiFleetsDocument = gql`
  query allTaxiFleets {
    allTaxiFleets {
      edges {
        node {
          id
          name
          pool
        }
      }
    }
  }
`;

/**
 * __useAllTaxiFleetsQuery__
 *
 * To run a query within a React component, call `useAllTaxiFleetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaxiFleetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaxiFleetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTaxiFleetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllTaxiFleetsQuery,
    AllTaxiFleetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTaxiFleetsQuery, AllTaxiFleetsQueryVariables>(
    AllTaxiFleetsDocument,
    options,
  );
}
export function useAllTaxiFleetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTaxiFleetsQuery,
    AllTaxiFleetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTaxiFleetsQuery, AllTaxiFleetsQueryVariables>(
    AllTaxiFleetsDocument,
    options,
  );
}
export type AllTaxiFleetsQueryHookResult = ReturnType<
  typeof useAllTaxiFleetsQuery
>;
export type AllTaxiFleetsLazyQueryHookResult = ReturnType<
  typeof useAllTaxiFleetsLazyQuery
>;
export type AllTaxiFleetsQueryResult = Apollo.QueryResult<
  AllTaxiFleetsQuery,
  AllTaxiFleetsQueryVariables
>;
export const TaxiVehicleDocument = gql`
  query TaxiVehicle($taxiVehicleId: ID!) {
    TaxiVehicle(id: $taxiVehicleId) {
      id
      driver {
        approvedAt
        id
        name
        email
        phoneNumber
        nationalId
        profileImageUrl
        activeVehicleId
      }
      driverId
      licensePlate
      passengerCapacity
      online
      info {
        id
        make
        model
        color
        vin
        registeredPlate
        year
        weight
        status
        nextInspection
        owner {
          id
          name
        }
      }
      state
      approvedAt
      fleets {
        id
        fleet {
          id
          name
          description
          pool
          maximumPassengers
        }
        active
      }
    }
  }
`;

/**
 * __useTaxiVehicleQuery__
 *
 * To run a query within a React component, call `useTaxiVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxiVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxiVehicleQuery({
 *   variables: {
 *      taxiVehicleId: // value for 'taxiVehicleId'
 *   },
 * });
 */
export function useTaxiVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaxiVehicleQuery,
    TaxiVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxiVehicleQuery, TaxiVehicleQueryVariables>(
    TaxiVehicleDocument,
    options,
  );
}
export function useTaxiVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxiVehicleQuery,
    TaxiVehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxiVehicleQuery, TaxiVehicleQueryVariables>(
    TaxiVehicleDocument,
    options,
  );
}
export type TaxiVehicleQueryHookResult = ReturnType<typeof useTaxiVehicleQuery>;
export type TaxiVehicleLazyQueryHookResult = ReturnType<
  typeof useTaxiVehicleLazyQuery
>;
export type TaxiVehicleQueryResult = Apollo.QueryResult<
  TaxiVehicleQuery,
  TaxiVehicleQueryVariables
>;
export const UpdateVehicleApprovalDocument = gql`
  mutation UpdateVehicleApproval($approved: Boolean!, $vehicleId: ID!) {
    updateVehicleApproval(approved: $approved, vehicleId: $vehicleId) {
      id
      approvedAt
    }
  }
`;
export type UpdateVehicleApprovalMutationFn = Apollo.MutationFunction<
  UpdateVehicleApprovalMutation,
  UpdateVehicleApprovalMutationVariables
>;

/**
 * __useUpdateVehicleApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleApprovalMutation, { data, loading, error }] = useUpdateVehicleApprovalMutation({
 *   variables: {
 *      approved: // value for 'approved'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useUpdateVehicleApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleApprovalMutation,
    UpdateVehicleApprovalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVehicleApprovalMutation,
    UpdateVehicleApprovalMutationVariables
  >(UpdateVehicleApprovalDocument, options);
}
export type UpdateVehicleApprovalMutationHookResult = ReturnType<
  typeof useUpdateVehicleApprovalMutation
>;
export type UpdateVehicleApprovalMutationResult =
  Apollo.MutationResult<UpdateVehicleApprovalMutation>;
export type UpdateVehicleApprovalMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleApprovalMutation,
  UpdateVehicleApprovalMutationVariables
>;
export const UpdateTaxiVehicleFleetsDocument = gql`
  mutation UpdateTaxiVehicleFleets($fleetIds: [ID!]!, $vehicleId: ID!) {
    updateTaxiVehicleFleets(fleetIds: $fleetIds, vehicleId: $vehicleId) {
      id
      fleet {
        id
      }
      active
    }
  }
`;
export type UpdateTaxiVehicleFleetsMutationFn = Apollo.MutationFunction<
  UpdateTaxiVehicleFleetsMutation,
  UpdateTaxiVehicleFleetsMutationVariables
>;

/**
 * __useUpdateTaxiVehicleFleetsMutation__
 *
 * To run a mutation, you first call `useUpdateTaxiVehicleFleetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxiVehicleFleetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxiVehicleFleetsMutation, { data, loading, error }] = useUpdateTaxiVehicleFleetsMutation({
 *   variables: {
 *      fleetIds: // value for 'fleetIds'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useUpdateTaxiVehicleFleetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaxiVehicleFleetsMutation,
    UpdateTaxiVehicleFleetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaxiVehicleFleetsMutation,
    UpdateTaxiVehicleFleetsMutationVariables
  >(UpdateTaxiVehicleFleetsDocument, options);
}
export type UpdateTaxiVehicleFleetsMutationHookResult = ReturnType<
  typeof useUpdateTaxiVehicleFleetsMutation
>;
export type UpdateTaxiVehicleFleetsMutationResult =
  Apollo.MutationResult<UpdateTaxiVehicleFleetsMutation>;
export type UpdateTaxiVehicleFleetsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaxiVehicleFleetsMutation,
  UpdateTaxiVehicleFleetsMutationVariables
>;
export const SetVehicleInfoOwnerDriverDocument = gql`
  mutation SetVehicleInfoOwnerDriver($vehicleInfoId: ID!, $driverId: ID) {
    setVehicleInfoOwnerDriver(
      vehicleInfoId: $vehicleInfoId
      driverId: $driverId
    ) {
      id
      ownerDriverId
    }
  }
`;
export type SetVehicleInfoOwnerDriverMutationFn = Apollo.MutationFunction<
  SetVehicleInfoOwnerDriverMutation,
  SetVehicleInfoOwnerDriverMutationVariables
>;

/**
 * __useSetVehicleInfoOwnerDriverMutation__
 *
 * To run a mutation, you first call `useSetVehicleInfoOwnerDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVehicleInfoOwnerDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVehicleInfoOwnerDriverMutation, { data, loading, error }] = useSetVehicleInfoOwnerDriverMutation({
 *   variables: {
 *      vehicleInfoId: // value for 'vehicleInfoId'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useSetVehicleInfoOwnerDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVehicleInfoOwnerDriverMutation,
    SetVehicleInfoOwnerDriverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVehicleInfoOwnerDriverMutation,
    SetVehicleInfoOwnerDriverMutationVariables
  >(SetVehicleInfoOwnerDriverDocument, options);
}
export type SetVehicleInfoOwnerDriverMutationHookResult = ReturnType<
  typeof useSetVehicleInfoOwnerDriverMutation
>;
export type SetVehicleInfoOwnerDriverMutationResult =
  Apollo.MutationResult<SetVehicleInfoOwnerDriverMutation>;
export type SetVehicleInfoOwnerDriverMutationOptions =
  Apollo.BaseMutationOptions<
    SetVehicleInfoOwnerDriverMutation,
    SetVehicleInfoOwnerDriverMutationVariables
  >;
export const RefundTripInvoiceDocument = gql`
  mutation RefundTripInvoice(
    $tripId: Int!
    $items: [InvoiceItemInput!]
    $includeInvoiceIssuedFines: Boolean = false
  ) {
    refundTrip(tripId: $tripId, items: $items) {
      id
      invoice {
        ...Invoice
      }
    }
  }
  ${InvoiceFragmentDoc}
`;
export type RefundTripInvoiceMutationFn = Apollo.MutationFunction<
  RefundTripInvoiceMutation,
  RefundTripInvoiceMutationVariables
>;

/**
 * __useRefundTripInvoiceMutation__
 *
 * To run a mutation, you first call `useRefundTripInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundTripInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundTripInvoiceMutation, { data, loading, error }] = useRefundTripInvoiceMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      items: // value for 'items'
 *      includeInvoiceIssuedFines: // value for 'includeInvoiceIssuedFines'
 *   },
 * });
 */
export function useRefundTripInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefundTripInvoiceMutation,
    RefundTripInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefundTripInvoiceMutation,
    RefundTripInvoiceMutationVariables
  >(RefundTripInvoiceDocument, options);
}
export type RefundTripInvoiceMutationHookResult = ReturnType<
  typeof useRefundTripInvoiceMutation
>;
export type RefundTripInvoiceMutationResult =
  Apollo.MutationResult<RefundTripInvoiceMutation>;
export type RefundTripInvoiceMutationOptions = Apollo.BaseMutationOptions<
  RefundTripInvoiceMutation,
  RefundTripInvoiceMutationVariables
>;
export const TripDocument = gql`
  query Trip(
    $tripId: Int
    $tripOid: String
    $invoiceId: String
    $fleetId: Int
    $includeFleet: Boolean = false
    $includeInvoiceIssuedFines: Boolean = false
  ) {
    trip(
      tripId: $tripId
      tripOid: $tripOid
      invoiceId: $invoiceId
      fleetId: $fleetId
    ) {
      id
      oid
      ...TripDetails
      ...TripPayment
      invoice {
        ...Invoice
      }
      fleet @include(if: $includeFleet) {
        id
        name
      }
    }
  }
  ${TripDetailsFragmentDoc}
  ${TripPaymentFragmentDoc}
  ${InvoiceFragmentDoc}
`;

/**
 * __useTripQuery__
 *
 * To run a query within a React component, call `useTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      tripOid: // value for 'tripOid'
 *      invoiceId: // value for 'invoiceId'
 *      fleetId: // value for 'fleetId'
 *      includeFleet: // value for 'includeFleet'
 *      includeInvoiceIssuedFines: // value for 'includeInvoiceIssuedFines'
 *   },
 * });
 */
export function useTripQuery(
  baseOptions?: Apollo.QueryHookOptions<TripQuery, TripQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripQuery, TripQueryVariables>(TripDocument, options);
}
export function useTripLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TripQuery, TripQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripQuery, TripQueryVariables>(
    TripDocument,
    options,
  );
}
export type TripQueryHookResult = ReturnType<typeof useTripQuery>;
export type TripLazyQueryHookResult = ReturnType<typeof useTripLazyQuery>;
export type TripQueryResult = Apollo.QueryResult<TripQuery, TripQueryVariables>;
export const TripRouteDocument = gql`
  query TripRoute($tripOid: String!, $fleetId: Int) {
    trip(tripOid: $tripOid, fleetId: $fleetId) {
      path {
        type
        coordinates
      }
    }
  }
`;

/**
 * __useTripRouteQuery__
 *
 * To run a query within a React component, call `useTripRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripRouteQuery({
 *   variables: {
 *      tripOid: // value for 'tripOid'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useTripRouteQuery(
  baseOptions: Apollo.QueryHookOptions<TripRouteQuery, TripRouteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TripRouteQuery, TripRouteQueryVariables>(
    TripRouteDocument,
    options,
  );
}
export function useTripRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TripRouteQuery,
    TripRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TripRouteQuery, TripRouteQueryVariables>(
    TripRouteDocument,
    options,
  );
}
export type TripRouteQueryHookResult = ReturnType<typeof useTripRouteQuery>;
export type TripRouteLazyQueryHookResult = ReturnType<
  typeof useTripRouteLazyQuery
>;
export type TripRouteQueryResult = Apollo.QueryResult<
  TripRouteQuery,
  TripRouteQueryVariables
>;
export const UnmetDemandDocument = gql`
  query UnmetDemand(
    $nearestBike: Int!
    $startDate: String!
    $endDate: String!
  ) {
    unmetDemand(
      nearestBike: $nearestBike
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      latitude
      longitude
    }
  }
`;

/**
 * __useUnmetDemandQuery__
 *
 * To run a query within a React component, call `useUnmetDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnmetDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnmetDemandQuery({
 *   variables: {
 *      nearestBike: // value for 'nearestBike'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUnmetDemandQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnmetDemandQuery,
    UnmetDemandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnmetDemandQuery, UnmetDemandQueryVariables>(
    UnmetDemandDocument,
    options,
  );
}
export function useUnmetDemandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnmetDemandQuery,
    UnmetDemandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnmetDemandQuery, UnmetDemandQueryVariables>(
    UnmetDemandDocument,
    options,
  );
}
export type UnmetDemandQueryHookResult = ReturnType<typeof useUnmetDemandQuery>;
export type UnmetDemandLazyQueryHookResult = ReturnType<
  typeof useUnmetDemandLazyQuery
>;
export type UnmetDemandQueryResult = Apollo.QueryResult<
  UnmetDemandQuery,
  UnmetDemandQueryVariables
>;
export const UserDocument = gql`
  query User(
    $phone: String
    $email: String
    $oid: String
    $canQueryEmail: Boolean!
    $canQueryCards: Boolean!
    $canQueryCoupons: Boolean!
    $canQueryStripeId: Boolean!
    $canQueryLicenses: Boolean!
  ) {
    user(phone: $phone, email: $email, oid: $oid) {
      id
      oid
      stripeId @include(if: $canQueryStripeId)
      phone
      email @include(if: $canQueryEmail)
      createdAt
      name
      deletionRequestedAt
      signUpRegionV2 {
        id
        name
        country {
          name
        }
      }
      licenses @include(if: $canQueryLicenses) {
        id
        workflowId
        status
        createdAt
        documentNumber
        reviewedAutomatically
      }
      cards @include(if: $canQueryCards) {
        lastDigits
        id
        status
      }
      coupons @include(if: $canQueryCoupons) {
        id
        currency
        amountRemaining
        originalAmount
        redeemedAt
        validUntil
        couponType {
          id
          unit
          name
        }
        couponGroup {
          internalName
        }
      }
      organizations {
        id
        name
        isMissingRequiredCard
      }
      ...UserDetails
    }
  }
  ${UserDetailsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      oid: // value for 'oid'
 *      canQueryEmail: // value for 'canQueryEmail'
 *      canQueryCards: // value for 'canQueryCards'
 *      canQueryCoupons: // value for 'canQueryCoupons'
 *      canQueryStripeId: // value for 'canQueryStripeId'
 *      canQueryLicenses: // value for 'canQueryLicenses'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const RemoveCardDocument = gql`
  mutation RemoveCard($id: Int!) {
    removeCard(id: $id)
  }
`;
export type RemoveCardMutationFn = Apollo.MutationFunction<
  RemoveCardMutation,
  RemoveCardMutationVariables
>;

/**
 * __useRemoveCardMutation__
 *
 * To run a mutation, you first call `useRemoveCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCardMutation, { data, loading, error }] = useRemoveCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCardMutation,
    RemoveCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCardMutation, RemoveCardMutationVariables>(
    RemoveCardDocument,
    options,
  );
}
export type RemoveCardMutationHookResult = ReturnType<
  typeof useRemoveCardMutation
>;
export type RemoveCardMutationResult =
  Apollo.MutationResult<RemoveCardMutation>;
export type RemoveCardMutationOptions = Apollo.BaseMutationOptions<
  RemoveCardMutation,
  RemoveCardMutationVariables
>;
export const EndRentDocument = gql`
  mutation EndRent($tripId: Int!) {
    endRent(tripId: $tripId) {
      id
    }
  }
`;
export type EndRentMutationFn = Apollo.MutationFunction<
  EndRentMutation,
  EndRentMutationVariables
>;

/**
 * __useEndRentMutation__
 *
 * To run a mutation, you first call `useEndRentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndRentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endRentMutation, { data, loading, error }] = useEndRentMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useEndRentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndRentMutation,
    EndRentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndRentMutation, EndRentMutationVariables>(
    EndRentDocument,
    options,
  );
}
export type EndRentMutationHookResult = ReturnType<typeof useEndRentMutation>;
export type EndRentMutationResult = Apollo.MutationResult<EndRentMutation>;
export type EndRentMutationOptions = Apollo.BaseMutationOptions<
  EndRentMutation,
  EndRentMutationVariables
>;
export const GiveCouponDocument = gql`
  mutation GiveCoupon($input: GiveCouponInput!) {
    giveCoupon(input: $input) {
      user {
        id
        freeRides
      }
    }
  }
`;
export type GiveCouponMutationFn = Apollo.MutationFunction<
  GiveCouponMutation,
  GiveCouponMutationVariables
>;

/**
 * __useGiveCouponMutation__
 *
 * To run a mutation, you first call `useGiveCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveCouponMutation, { data, loading, error }] = useGiveCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiveCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GiveCouponMutation,
    GiveCouponMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GiveCouponMutation, GiveCouponMutationVariables>(
    GiveCouponDocument,
    options,
  );
}
export type GiveCouponMutationHookResult = ReturnType<
  typeof useGiveCouponMutation
>;
export type GiveCouponMutationResult =
  Apollo.MutationResult<GiveCouponMutation>;
export type GiveCouponMutationOptions = Apollo.BaseMutationOptions<
  GiveCouponMutation,
  GiveCouponMutationVariables
>;
export const VehicleDocument = gql`
  query Vehicle($code: String!, $includeTechDetails: Boolean = false) {
    vehicle(code: $code, searchInPublicFleets: false) {
      ...VehicleDetails
      id
      code
      status
      fleet {
        id
        currency
      }
    }
  }
  ${VehicleDetailsFragmentDoc}
`;

/**
 * __useVehicleQuery__
 *
 * To run a query within a React component, call `useVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleQuery({
 *   variables: {
 *      code: // value for 'code'
 *      includeTechDetails: // value for 'includeTechDetails'
 *   },
 * });
 */
export function useVehicleQuery(
  baseOptions: Apollo.QueryHookOptions<VehicleQuery, VehicleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleQuery, VehicleQueryVariables>(
    VehicleDocument,
    options,
  );
}
export function useVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleQuery,
    VehicleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleQuery, VehicleQueryVariables>(
    VehicleDocument,
    options,
  );
}
export type VehicleQueryHookResult = ReturnType<typeof useVehicleQuery>;
export type VehicleLazyQueryHookResult = ReturnType<typeof useVehicleLazyQuery>;
export type VehicleQueryResult = Apollo.QueryResult<
  VehicleQuery,
  VehicleQueryVariables
>;
export const UpdateVehicleStatusDocument = gql`
  mutation UpdateVehicleStatus(
    $vehicleId: Int!
    $event: VehicleEvent!
    $reason: VehicleEventReason
  ) {
    updateVehicleStatus(vehicleId: $vehicleId, event: $event, reason: $reason) {
      status
    }
  }
`;
export type UpdateVehicleStatusMutationFn = Apollo.MutationFunction<
  UpdateVehicleStatusMutation,
  UpdateVehicleStatusMutationVariables
>;

/**
 * __useUpdateVehicleStatusMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleStatusMutation, { data, loading, error }] = useUpdateVehicleStatusMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      event: // value for 'event'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useUpdateVehicleStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleStatusMutation,
    UpdateVehicleStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVehicleStatusMutation,
    UpdateVehicleStatusMutationVariables
  >(UpdateVehicleStatusDocument, options);
}
export type UpdateVehicleStatusMutationHookResult = ReturnType<
  typeof useUpdateVehicleStatusMutation
>;
export type UpdateVehicleStatusMutationResult =
  Apollo.MutationResult<UpdateVehicleStatusMutation>;
export type UpdateVehicleStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleStatusMutation,
  UpdateVehicleStatusMutationVariables
>;
export const VehicleAggregatesDocument = gql`
  query VehicleAggregates($vehicleId: Int!) {
    vehicleAggregates(vehicleId: $vehicleId) {
      length
      tripCount
      revenueInFleet
    }
  }
`;

/**
 * __useVehicleAggregatesQuery__
 *
 * To run a query within a React component, call `useVehicleAggregatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleAggregatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleAggregatesQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleAggregatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleAggregatesQuery,
    VehicleAggregatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VehicleAggregatesQuery,
    VehicleAggregatesQueryVariables
  >(VehicleAggregatesDocument, options);
}
export function useVehicleAggregatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleAggregatesQuery,
    VehicleAggregatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehicleAggregatesQuery,
    VehicleAggregatesQueryVariables
  >(VehicleAggregatesDocument, options);
}
export type VehicleAggregatesQueryHookResult = ReturnType<
  typeof useVehicleAggregatesQuery
>;
export type VehicleAggregatesLazyQueryHookResult = ReturnType<
  typeof useVehicleAggregatesLazyQuery
>;
export type VehicleAggregatesQueryResult = Apollo.QueryResult<
  VehicleAggregatesQuery,
  VehicleAggregatesQueryVariables
>;
export const VehicleConnectionStatusDocument = gql`
  query VehicleConnectionStatus($vehicleId: Int!) {
    vehicle(id: $vehicleId) {
      lastPosition
      lastPing
    }
  }
`;

/**
 * __useVehicleConnectionStatusQuery__
 *
 * To run a query within a React component, call `useVehicleConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleConnectionStatusQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleConnectionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleConnectionStatusQuery,
    VehicleConnectionStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VehicleConnectionStatusQuery,
    VehicleConnectionStatusQueryVariables
  >(VehicleConnectionStatusDocument, options);
}
export function useVehicleConnectionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleConnectionStatusQuery,
    VehicleConnectionStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehicleConnectionStatusQuery,
    VehicleConnectionStatusQueryVariables
  >(VehicleConnectionStatusDocument, options);
}
export type VehicleConnectionStatusQueryHookResult = ReturnType<
  typeof useVehicleConnectionStatusQuery
>;
export type VehicleConnectionStatusLazyQueryHookResult = ReturnType<
  typeof useVehicleConnectionStatusLazyQuery
>;
export type VehicleConnectionStatusQueryResult = Apollo.QueryResult<
  VehicleConnectionStatusQuery,
  VehicleConnectionStatusQueryVariables
>;
export const AllVehiclesDocument = gql`
  query AllVehicles($regionId: Int!, $filter: VehicleFilter) {
    allVehicles(regionId: $regionId, filter: $filter) {
      id
      code
      longitude
      latitude
      batteryLevel
      range
      fleet {
        id
        isPublic
        isStaffFleet
        currency
      }
      vehicleType {
        id
        type
      }
      status
      statusReason
      lockColor
      lastPosition
    }
  }
`;

/**
 * __useAllVehiclesQuery__
 *
 * To run a query within a React component, call `useAllVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVehiclesQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllVehiclesQuery,
    AllVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllVehiclesQuery, AllVehiclesQueryVariables>(
    AllVehiclesDocument,
    options,
  );
}
export function useAllVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllVehiclesQuery,
    AllVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllVehiclesQuery, AllVehiclesQueryVariables>(
    AllVehiclesDocument,
    options,
  );
}
export type AllVehiclesQueryHookResult = ReturnType<typeof useAllVehiclesQuery>;
export type AllVehiclesLazyQueryHookResult = ReturnType<
  typeof useAllVehiclesLazyQuery
>;
export type AllVehiclesQueryResult = Apollo.QueryResult<
  AllVehiclesQuery,
  AllVehiclesQueryVariables
>;
export const VehicleMapZonesDocument = gql`
  query VehicleMapZones($regionId: Int!) {
    changedZones(regionId: $regionId) {
      updated {
        id
        type
        geometry {
          type
          coordinates
        }
        vehicleTypeGroups
        icon {
          id
          darkUrl
        }
      }
    }
  }
`;

/**
 * __useVehicleMapZonesQuery__
 *
 * To run a query within a React component, call `useVehicleMapZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleMapZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleMapZonesQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useVehicleMapZonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleMapZonesQuery,
    VehicleMapZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleMapZonesQuery, VehicleMapZonesQueryVariables>(
    VehicleMapZonesDocument,
    options,
  );
}
export function useVehicleMapZonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleMapZonesQuery,
    VehicleMapZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VehicleMapZonesQuery,
    VehicleMapZonesQueryVariables
  >(VehicleMapZonesDocument, options);
}
export type VehicleMapZonesQueryHookResult = ReturnType<
  typeof useVehicleMapZonesQuery
>;
export type VehicleMapZonesLazyQueryHookResult = ReturnType<
  typeof useVehicleMapZonesLazyQuery
>;
export type VehicleMapZonesQueryResult = Apollo.QueryResult<
  VehicleMapZonesQuery,
  VehicleMapZonesQueryVariables
>;
export const VehiclePathDocument = gql`
  query VehiclePath(
    $vehicleCode: String!
    $dateFrom: DateTime!
    $dateTo: DateTime!
  ) {
    vehiclePath(
      vehicleCode: $vehicleCode
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      latitude
      longitude
      speed
      timestamp
    }
  }
`;

/**
 * __useVehiclePathQuery__
 *
 * To run a query within a React component, call `useVehiclePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclePathQuery({
 *   variables: {
 *      vehicleCode: // value for 'vehicleCode'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useVehiclePathQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehiclePathQuery,
    VehiclePathQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehiclePathQuery, VehiclePathQueryVariables>(
    VehiclePathDocument,
    options,
  );
}
export function useVehiclePathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclePathQuery,
    VehiclePathQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehiclePathQuery, VehiclePathQueryVariables>(
    VehiclePathDocument,
    options,
  );
}
export type VehiclePathQueryHookResult = ReturnType<typeof useVehiclePathQuery>;
export type VehiclePathLazyQueryHookResult = ReturnType<
  typeof useVehiclePathLazyQuery
>;
export type VehiclePathQueryResult = Apollo.QueryResult<
  VehiclePathQuery,
  VehiclePathQueryVariables
>;
export const VehicleSimDocument = gql`
  query VehicleSIM($deviceId: String!) {
    vehicle(deviceId: $deviceId) {
      id
      code
      batteryLevel
    }
  }
`;

/**
 * __useVehicleSimQuery__
 *
 * To run a query within a React component, call `useVehicleSimQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleSimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleSimQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useVehicleSimQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleSimQuery,
    VehicleSimQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehicleSimQuery, VehicleSimQueryVariables>(
    VehicleSimDocument,
    options,
  );
}
export function useVehicleSimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleSimQuery,
    VehicleSimQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehicleSimQuery, VehicleSimQueryVariables>(
    VehicleSimDocument,
    options,
  );
}
export type VehicleSimQueryHookResult = ReturnType<typeof useVehicleSimQuery>;
export type VehicleSimLazyQueryHookResult = ReturnType<
  typeof useVehicleSimLazyQuery
>;
export type VehicleSimQueryResult = Apollo.QueryResult<
  VehicleSimQuery,
  VehicleSimQueryVariables
>;
export const SetApnDocument = gql`
  mutation SetApn($vehicleId: Int!, $apn: String!) {
    changeApn(vehicleId: $vehicleId, apn: $apn) {
      id
    }
  }
`;
export type SetApnMutationFn = Apollo.MutationFunction<
  SetApnMutation,
  SetApnMutationVariables
>;

/**
 * __useSetApnMutation__
 *
 * To run a mutation, you first call `useSetApnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApnMutation, { data, loading, error }] = useSetApnMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      apn: // value for 'apn'
 *   },
 * });
 */
export function useSetApnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetApnMutation,
    SetApnMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetApnMutation, SetApnMutationVariables>(
    SetApnDocument,
    options,
  );
}
export type SetApnMutationHookResult = ReturnType<typeof useSetApnMutation>;
export type SetApnMutationResult = Apollo.MutationResult<SetApnMutation>;
export type SetApnMutationOptions = Apollo.BaseMutationOptions<
  SetApnMutation,
  SetApnMutationVariables
>;
export const AllVehiclesInFleetDocument = gql`
  query AllVehiclesInFleet($fleetId: Int) {
    allVehiclesInFleet(fleetId: $fleetId) {
      id
      code
      bodyCode
      imei
      batteryLevel
      status
      statusReason
      lastPosition
      lastPing
      vehicleType {
        vehicleName
      }
    }
  }
`;

/**
 * __useAllVehiclesInFleetQuery__
 *
 * To run a query within a React component, call `useAllVehiclesInFleetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVehiclesInFleetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVehiclesInFleetQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useAllVehiclesInFleetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllVehiclesInFleetQuery,
    AllVehiclesInFleetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllVehiclesInFleetQuery,
    AllVehiclesInFleetQueryVariables
  >(AllVehiclesInFleetDocument, options);
}
export function useAllVehiclesInFleetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllVehiclesInFleetQuery,
    AllVehiclesInFleetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllVehiclesInFleetQuery,
    AllVehiclesInFleetQueryVariables
  >(AllVehiclesInFleetDocument, options);
}
export type AllVehiclesInFleetQueryHookResult = ReturnType<
  typeof useAllVehiclesInFleetQuery
>;
export type AllVehiclesInFleetLazyQueryHookResult = ReturnType<
  typeof useAllVehiclesInFleetLazyQuery
>;
export type AllVehiclesInFleetQueryResult = Apollo.QueryResult<
  AllVehiclesInFleetQuery,
  AllVehiclesInFleetQueryVariables
>;
export const AllWatcherEventsDocument = gql`
  query AllWatcherEvents(
    $fleetId: Int!
    $vehicleId: Int
    $type: WatcherEventTypeEnum
    $limit: Int
    $cursor: String
  ) {
    allWatcherEvents(
      fleetId: $fleetId
      vehicleId: $vehicleId
      limit: $limit
      type: $type
      cursor: $cursor
    ) {
      watcherEvents {
        id
        metadata
        type
        createdAt
        notifications {
          message
          user {
            oid
            name
          }
        }
        vehicle {
          id
          code
        }
      }
      pageInfo {
        remaining
        hasNextPage
        cursor
      }
    }
  }
`;

/**
 * __useAllWatcherEventsQuery__
 *
 * To run a query within a React component, call `useAllWatcherEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWatcherEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWatcherEventsQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *      vehicleId: // value for 'vehicleId'
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAllWatcherEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllWatcherEventsQuery,
    AllWatcherEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllWatcherEventsQuery, AllWatcherEventsQueryVariables>(
    AllWatcherEventsDocument,
    options,
  );
}
export function useAllWatcherEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWatcherEventsQuery,
    AllWatcherEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllWatcherEventsQuery,
    AllWatcherEventsQueryVariables
  >(AllWatcherEventsDocument, options);
}
export type AllWatcherEventsQueryHookResult = ReturnType<
  typeof useAllWatcherEventsQuery
>;
export type AllWatcherEventsLazyQueryHookResult = ReturnType<
  typeof useAllWatcherEventsLazyQuery
>;
export type AllWatcherEventsQueryResult = Apollo.QueryResult<
  AllWatcherEventsQuery,
  AllWatcherEventsQueryVariables
>;
export const AllWatcherDocumentationSummaryDocument = gql`
  query AllWatcherDocumentationSummary {
    watcherDocumentation {
      type
      name
    }
  }
`;

/**
 * __useAllWatcherDocumentationSummaryQuery__
 *
 * To run a query within a React component, call `useAllWatcherDocumentationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWatcherDocumentationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWatcherDocumentationSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWatcherDocumentationSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllWatcherDocumentationSummaryQuery,
    AllWatcherDocumentationSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllWatcherDocumentationSummaryQuery,
    AllWatcherDocumentationSummaryQueryVariables
  >(AllWatcherDocumentationSummaryDocument, options);
}
export function useAllWatcherDocumentationSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWatcherDocumentationSummaryQuery,
    AllWatcherDocumentationSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllWatcherDocumentationSummaryQuery,
    AllWatcherDocumentationSummaryQueryVariables
  >(AllWatcherDocumentationSummaryDocument, options);
}
export type AllWatcherDocumentationSummaryQueryHookResult = ReturnType<
  typeof useAllWatcherDocumentationSummaryQuery
>;
export type AllWatcherDocumentationSummaryLazyQueryHookResult = ReturnType<
  typeof useAllWatcherDocumentationSummaryLazyQuery
>;
export type AllWatcherDocumentationSummaryQueryResult = Apollo.QueryResult<
  AllWatcherDocumentationSummaryQuery,
  AllWatcherDocumentationSummaryQueryVariables
>;
export const AllWatcherDocumentationDocument = gql`
  query AllWatcherDocumentation {
    watcherDocumentation {
      type
      name
      description
      appliesTo
      trigger
      whatHappens
    }
  }
`;

/**
 * __useAllWatcherDocumentationQuery__
 *
 * To run a query within a React component, call `useAllWatcherDocumentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWatcherDocumentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWatcherDocumentationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWatcherDocumentationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllWatcherDocumentationQuery,
    AllWatcherDocumentationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllWatcherDocumentationQuery,
    AllWatcherDocumentationQueryVariables
  >(AllWatcherDocumentationDocument, options);
}
export function useAllWatcherDocumentationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWatcherDocumentationQuery,
    AllWatcherDocumentationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllWatcherDocumentationQuery,
    AllWatcherDocumentationQueryVariables
  >(AllWatcherDocumentationDocument, options);
}
export type AllWatcherDocumentationQueryHookResult = ReturnType<
  typeof useAllWatcherDocumentationQuery
>;
export type AllWatcherDocumentationLazyQueryHookResult = ReturnType<
  typeof useAllWatcherDocumentationLazyQuery
>;
export type AllWatcherDocumentationQueryResult = Apollo.QueryResult<
  AllWatcherDocumentationQuery,
  AllWatcherDocumentationQueryVariables
>;
export const ZoneEditorZonesDocument = gql`
  query ZoneEditorZones($fleetId: Int!) {
    allZonesAdmin(fleetId: $fleetId) {
      ...ZoneEditorZone
    }
  }
  ${ZoneEditorZoneFragmentDoc}
`;

/**
 * __useZoneEditorZonesQuery__
 *
 * To run a query within a React component, call `useZoneEditorZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneEditorZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneEditorZonesQuery({
 *   variables: {
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useZoneEditorZonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ZoneEditorZonesQuery,
    ZoneEditorZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoneEditorZonesQuery, ZoneEditorZonesQueryVariables>(
    ZoneEditorZonesDocument,
    options,
  );
}
export function useZoneEditorZonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoneEditorZonesQuery,
    ZoneEditorZonesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ZoneEditorZonesQuery,
    ZoneEditorZonesQueryVariables
  >(ZoneEditorZonesDocument, options);
}
export type ZoneEditorZonesQueryHookResult = ReturnType<
  typeof useZoneEditorZonesQuery
>;
export type ZoneEditorZonesLazyQueryHookResult = ReturnType<
  typeof useZoneEditorZonesLazyQuery
>;
export type ZoneEditorZonesQueryResult = Apollo.QueryResult<
  ZoneEditorZonesQuery,
  ZoneEditorZonesQueryVariables
>;
export const UpdateZonesDocument = gql`
  mutation UpdateZones($input: UpdateZonesInput!) {
    updateZones(input: $input) {
      ...ZoneEditorZone
    }
  }
  ${ZoneEditorZoneFragmentDoc}
`;
export type UpdateZonesMutationFn = Apollo.MutationFunction<
  UpdateZonesMutation,
  UpdateZonesMutationVariables
>;

/**
 * __useUpdateZonesMutation__
 *
 * To run a mutation, you first call `useUpdateZonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZonesMutation, { data, loading, error }] = useUpdateZonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZonesMutation,
    UpdateZonesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZonesMutation, UpdateZonesMutationVariables>(
    UpdateZonesDocument,
    options,
  );
}
export type UpdateZonesMutationHookResult = ReturnType<
  typeof useUpdateZonesMutation
>;
export type UpdateZonesMutationResult =
  Apollo.MutationResult<UpdateZonesMutation>;
export type UpdateZonesMutationOptions = Apollo.BaseMutationOptions<
  UpdateZonesMutation,
  UpdateZonesMutationVariables
>;
